import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MediaSource from '../entity/MediaSource';
import Media from '../entity/Media';
import {NETBOX_STATUS_ACTIVE, NETBOX_STATUS_DISABLED, NETBOX_STATUS_IDLE} from '../../netbox/constants/statuses';
import {ALL_POSSIBLE_ORIGINAL_MEDIA_SIZES, MEDIA_SIZE_480} from '../constants/sizes';
import AsyncLoader from '../../common/services/AsyncLoader';
import PlayerAPI from '../services/player/player';
import {TYPE_JEWELRY, TYPE_LGD_ROUGH, TYPE_ROUGH} from '../../product/constants/productTypes';
import PlayerErrorBoundary from './PlayerErrorBoundary/PlayerErrorBoundary';
import {E2E_TESTS_ENABLED} from '@/test/constants/e2e';
import config from '../../../config';

export const ZOOM_TYPE_OVERLAP = 'overlap';
export const ZOOM_TYPE_SIDEVIEW = 'sideview';

export const ZOOM_MODE_FOLLOW_MOUSE = 'follow-mouse';

export const HIDE_ALL_CONTROLS = 1;
export const HIDE_FULLSCREEN_BUTTON_ONLY = 2;

export class Player extends Component {
  static propTypes = {
    media: PropTypes.instanceOf(Media),
    mediaSource: PropTypes.instanceOf(MediaSource),
    stereoSource: PropTypes.string,
    group: PropTypes.string,
    bgColor: PropTypes.string,
    mediaSize: PropTypes.number,
    maxScale: PropTypes.number,
    isSingleScale: PropTypes.bool,
    isGrayscale: PropTypes.bool,
    isSaturation: PropTypes.bool,
    onClick: PropTypes.func,
    fps: PropTypes.number,
    zoom: PropTypes.oneOf([ZOOM_TYPE_OVERLAP, ZOOM_TYPE_SIDEVIEW]),
    zoomMode: PropTypes.oneOf([ZOOM_MODE_FOLLOW_MOUSE]),
    hasLazyLoading: PropTypes.bool,
    autofocus: PropTypes.bool,
    hqMode: PropTypes.bool,
    fullscreenContainer: PropTypes.instanceOf(Element),
    fullscreenPadding: PropTypes.shape({
      right: PropTypes.number,
      left: PropTypes.number,
      top: PropTypes.number,
      bottom: PropTypes.number,
    }),
    fullscreenPlayerCount: PropTypes.number,
    maxSourceSize: PropTypes.number,
    netBoxStatus: PropTypes.oneOf([NETBOX_STATUS_ACTIVE, NETBOX_STATUS_DISABLED, NETBOX_STATUS_IDLE]),
    isRenderedInFaceToFaceMode: PropTypes.bool,
    autoPlay: PropTypes.bool,
    plotting: PropTypes.shape({
      element: PropTypes.instanceOf(Element).isRequired,
      view: PropTypes.shape({
        showInclusions: PropTypes.bool.isRequired,
        showMedia: PropTypes.bool.isRequired,
        inclusionTypes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        highlightInclusions: PropTypes.bool.isRequired,
      }),
      size: PropTypes.shape({ w: PropTypes.number.isRequired, h: PropTypes.number.isRequired }),
    }),
    hideControls: PropTypes.number,
    transformation: PropTypes.string,
  };

  static defaultProps = {
    media: null,
    mediaSource: null,
    stereoSource: null,
    group: '',
    bgColor: '',
    mediaSize: MEDIA_SIZE_480,
    maxScale: null,
    isSingleScale: false,
    isGrayscale: false,
    isSaturation: false,
    onClick: null,
    fps: null,
    zoom: null,
    zoomMode: null,
    hasLazyLoading: false,
    autofocus: false,
    hqMode: false,
    fullscreenContainer: null,
    fullscreenPadding: null,
    fullscreenPlayerCount: null,
    maxSourceSize: null,
    netBoxStatus: NETBOX_STATUS_DISABLED,
    isRenderedInFaceToFaceMode: false,
    autoPlay: false,
    plotting: null,
    hideControls: null,
    transformation: null,
  };

  static getPlayerJSUrl() {
    return config.PLAYER_JS_URL;
  }

  static getPlayerCSSUrl() {
    return config.PLAYER_CSS_URL;
  }

  state = {
    isCutwisePlayerLoaded: AsyncLoader.isLoaded(Player.getPlayerJSUrl()) && AsyncLoader.isLoaded(Player.getPlayerJSUrl()),
  };

  constructor(props) {
    super(props);

    // random element id
    this.id = Math.random()
      .toString(36)
      .substring(7); // todo
    this.isCreated = false;
  }

  componentDidMount() {
    if (this.state.isCutwisePlayerLoaded) {
      return;
    }

    Promise.all([
      AsyncLoader.load(Player.getPlayerJSUrl(), 'text/javascript', PlayerAPI.triggerLoad),
      AsyncLoader.load(Player.getPlayerCSSUrl(), 'text/css'),
    ])
      .then(() => {
        this.setState({ isCutwisePlayerLoaded: true });
      });
  }

  componentDidUpdate() {
    if (!window.CutwisePlayer) {
      return;
    }

    if (this.isCreated) {
      this.destroyPlayer();
    }

    this.createPlayer();
  }

  componentWillUnmount() {
    if (!window.CutwisePlayer) {
      return;
    }

    this.destroyPlayer();
  }

  getAllPlayerSources() {
    const sourceKey = this.props.mediaSource.media.isImage ? 'img' : 'fset';

    return this.props.mediaSource.mediaSubFiles.map(mediaSubFile => ({
      size: mediaSubFile.mediaSubFormat.r,
      res: {
        w: mediaSubFile.resolutionX,
        h: mediaSubFile.resolutionY,
      },
      [sourceKey]: {
        href: mediaSubFile.externalLink,
      },
    }));
  }

  getPlayerSourcesForPlottingOnly() {
    return ALL_POSSIBLE_ORIGINAL_MEDIA_SIZES.map((s) => {
      return {
        size: s,
        res: {
          w: s,
          h: s,
        },
        svg: this.props.plotting.element,
      };
    });
  }

  getFilteredByMaxSizePlayerSources(playerSources) {
    return playerSources.filter(ps => ps.size <= this.props.maxSourceSize);
  }

  getSources() {
    if (this.props.plotting && !this.props.mediaSource && !this.props.media) {
      return this.getPlayerSourcesForPlottingOnly();
    }

    if (!this.props.media || !this.props.mediaSource) {
      return null;
    }

    let playerSources = this.getAllPlayerSources();

    if (this.props.maxSourceSize) {
      playerSources = this.getFilteredByMaxSizePlayerSources(playerSources);
    }

    return playerSources;
  }

  createPlayer() {
    if (this.state.isCutwisePlayerLoaded === false) {
      return;
    }

    const { media, mediaSource } = this.props;

    let scale = null;
    if (mediaSource && mediaSource.scaleRatio && this.props.maxScale) {
      scale = mediaSource.getRelScale(mediaSource.getMaxSubFileByMediaSize(this.props.mediaSize)) / this.props.maxScale;

      if (scale > 0.975) {
        scale = null;
      }
    }

    const handlers = {};
    if (this.props.onClick) {
      handlers.click = this.props.onClick;
    }

    const defaultConfig = {
      type: !media || media.isImage ? 'image' : 'video',
      debug: false,
      group: this.props.group || `${media.setupPreset.id}`,
      sources: this.getSources(),
      stereoSource: this.props.stereoSource,
      bg: this.props.bgColor,
      view: {
        hideControls: this.props.hideControls === HIDE_ALL_CONTROLS,
        drag: media && media.setupPreset && !media.setupPreset.isGyro,
        holdToDrag: true,
        size: this.props.mediaSize,
        autofocus: this.props.autofocus,
      },
      plugins: {
        gyroscope: true,
        grayscale: {
          state: this.props.isGrayscale ? 'on' : 'off',
        },
        saturation: {
          state: this.props.isSaturation ? 'on' : 'off',
        },
      },
      handlers: handlers,
      fps: this.props.fps || null,
      hasLazyLoading: E2E_TESTS_ENABLED ? true : this.props.hasLazyLoading,
      autoPlay: E2E_TESTS_ENABLED ? false : this.props.autoPlay,
      transformation: this.props.transformation,
    };

    if (this.props.netBoxStatus) {
      defaultConfig.plugins.netBox = {
        isActive: this.props.netBoxStatus === NETBOX_STATUS_ACTIVE,
      };
    }

    if (this.props.zoom) {
      defaultConfig.plugins.zoom = {
        enabled: true,
        type: window.innerWidth > 768 ? this.props.zoom : ZOOM_TYPE_OVERLAP,
        mode: this.props.zoomMode,
      };
    }

    if (this.props.hqMode) {
      defaultConfig.plugins.hqmode = {
        enabled: true,
      };
    }

    if (media && (media.product.type === TYPE_ROUGH || media.product.type === TYPE_LGD_ROUGH || media.product.type === TYPE_JEWELRY)) {
      defaultConfig.view['4view'] = 'disabled';
    }

    const configuration = Object.assign(defaultConfig, {
      scale: scale,
      isSingleScale: media && media.setupPreset.isPossibleSingleScale && this.props.isSingleScale,
    });

    if (media && media.isImage && this.props.bgColor) {
      config.bg = this.props.bgColor;
    }

    configuration.plugins.fullscreen = {
      enabled: true,
      container: this.props.fullscreenContainer,
      hideControl: this.props.hideControls === HIDE_FULLSCREEN_BUTTON_ONLY,
      padding: this.props.fullscreenPadding,
      playerCount: this.props.fullscreenPlayerCount,
    };

    if (this.props.fullscreenContainer) {
      configuration.plugins = {
        ...configuration.plugins,
        zoom: {
          enabled: true,
          type: ZOOM_TYPE_OVERLAP,
          mode: this.props.zoomMode,
        },
      };
    }

    if (this.props.plotting) {
      configuration.plugins.plotting = {
        withoutMedia: media === null && mediaSource === null,
        element: this.props.plotting.element,
        view: this.props.plotting.view,
      };
    }

    if (this.props.isRenderedInFaceToFaceMode) {
      configuration.view.multi = {
        mode: 'face-to-face',
      };
    }

    if (configuration.isSingleScale === false) {
      configuration.scale = null;
    }

    window.CutwisePlayer.createPlayer(this.id, configuration);

    this.isCreated = true;
  }

  destroyPlayer() {
    window.CutwisePlayer.destroyPlayer(this.id);
    this.playerContainer.innerHTML = '';
  }

  render() {
    if (!this.props.media && !this.props.mediaSource && !this.props.plotting) {
      return null;
    }

    return (
      <div
        ref={(playerContainer) => {
          this.playerContainer = playerContainer;
        }}
        id={this.id}
        aria-label={this.props.media && this.props.media.setupPreset ? `${this.props.media.setupPreset.title} Media` : null}
      />
    );
  }
}

export default props => (
  <PlayerErrorBoundary>
    <Player {...props} />
  </PlayerErrorBoundary>
);
