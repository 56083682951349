import {observable} from 'mobx';
import {setupPresetRepository} from '../../media/repository/SetupPresetRepository';
import ViewTemplate from '../entity/ViewTemplate';
import {setupPresetGroupRepository} from '../../media/repository/SetupPresetGroupRepository';
import {CONTEXT_B2B, CONTEXT_LISTING} from '../../common/constants/context';
import {
  TYPE_DIAMOND_FANCY, TYPE_JEWELRY, TYPE_LGD_ROUGH, TYPE_ROUGH,
} from '../../product/constants/productTypes';
import {
  DEFAULT_VIEW_TEMPLATE_ID_COLORLESS,
  DEFAULT_VIEW_TEMPLATE_ID_COLORLESS_EDIT,
  DEFAULT_VIEW_TEMPLATE_ID_FANCY,
  DEFAULT_VIEW_TEMPLATE_ID_FANCY_EDIT,
  DEFAULT_VIEW_TEMPLATE_ID_JEWELRY,
  DEFAULT_VIEW_TEMPLATE_ID_JEWELRY_EDIT,
  DEFAULT_VIEW_TEMPLATE_ID_ROUGH,
  DEFAULT_VIEW_TEMPLATE_ID_ROUGH_EDIT,
} from '../constants/defaultViewTemplates';

export default class ViewTemplateRepository {
  /**
   * @type {ViewTemplate[]}
   */
  @observable
  viewTemplates;

  initViewTemplates = (viewTemplatesData) => {
    this.viewTemplates = viewTemplatesData.map(d => new ViewTemplate(
      d,
      setupPresetRepository.findSetupPresetById(d.setupPreset),
      setupPresetGroupRepository.findSetupPresetGroupById(d.setupPresetGroup),
    ));
  };

  /**
   * @param {number} id
   * @returns {?ViewTemplate}
   */
  findViewTemplateById(id) {
    if (!this.viewTemplates) {
      return null;
    }

    return this.viewTemplates.find(sp => sp.id === id);
  }

  /**
   * @param {string} productType
   * @param {?number} context
   * @return {ViewTemplate[]}
   */
  findViewTemplates(productType, context = null) {
    const spType = ViewTemplate.getTypeByProductType(productType);

    const viewTemplatesFilteredByProductType = this.viewTemplates.filter(vt => vt.type.includes(spType));

    if (!context) {
      return viewTemplatesFilteredByProductType;
    }

    return viewTemplatesFilteredByProductType.filter(vt => vt.context.includes(context));
  }

  getDefaultViewTemplateId(productType, context = CONTEXT_LISTING) {
    if (productType === TYPE_ROUGH || productType === TYPE_LGD_ROUGH) {
      if (context === CONTEXT_B2B) {
        return DEFAULT_VIEW_TEMPLATE_ID_ROUGH_EDIT;
      }

      return DEFAULT_VIEW_TEMPLATE_ID_ROUGH;
    }

    if (productType === TYPE_JEWELRY) {
      if (context === CONTEXT_B2B) {
        return DEFAULT_VIEW_TEMPLATE_ID_JEWELRY_EDIT;
      }

      return DEFAULT_VIEW_TEMPLATE_ID_JEWELRY;
    }

    if (productType === TYPE_DIAMOND_FANCY) {
      if (context === CONTEXT_B2B) {
        return DEFAULT_VIEW_TEMPLATE_ID_FANCY_EDIT;
      }

      return DEFAULT_VIEW_TEMPLATE_ID_FANCY;
    }

    if (context === CONTEXT_B2B) {
      return DEFAULT_VIEW_TEMPLATE_ID_COLORLESS_EDIT;
    }

    return DEFAULT_VIEW_TEMPLATE_ID_COLORLESS;
  }
}

export const viewTemplateRepository = new ViewTemplateRepository();
