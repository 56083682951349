var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, computed, observable } from 'mobx';
import Diamond from '../../product/entity/Diamond';
import ProductFactory from '../../product/entity/ProductFactory';
import Rough from '../../product/entity/Rough';
const sortByCarat = (p1, p2) => p2.carat - p1.carat;
const sortByPrice = (p1, p2) => p2.price - p1.price;
export default class Solution {
    id;
    hpoId;
    guid;
    products = [];
    title;
    isHpoAllocation;
    isScan;
    weight = null;
    price = null;
    constructor({ id, hpoId, title, products, isHpoAllocation, isScan, weight = null, price = null, guid, }) {
        this.id = id;
        this.hpoId = hpoId;
        this.title = title;
        this.products = products.map((data) => ProductFactory.make(data._entityType, data));
        this.isHpoAllocation = isHpoAllocation;
        this.isScan = isScan;
        this.weight = weight;
        this.price = price;
        this.guid = guid;
    }
    get sortedProducts() {
        return [...this.products].sort(sortByCarat).sort(sortByPrice);
    }
    get diamondIds() {
        return this.getProductIdsByType(Diamond);
    }
    get hasOneProduct() {
        return this.sortedProducts.length === 1;
    }
    get roughIds() {
        return this.getProductIdsByType(Rough);
    }
    get solutionId() {
        return this.guid ? this.guid : this.hpoId;
    }
    getProductIdsByType(type) {
        return this.products
            .map((product) => (product instanceof type ? product.id : null))
            .filter((product) => Boolean(product));
    }
    replaceProduct(product) {
        this.products = this.products.filter(({ id }) => id !== product.id);
        this.products.push(product);
    }
    hasProductId(productId) {
        return Boolean(this.products.find(({ id }) => id === productId));
    }
    /**
     * Solutions must support both types of id: "hpoId" and "guid"
     * this utility check incoming id to match one of Solutions ids
     */
    isMatchId(solutionId) {
        /*
         * Convert everything to string for proper matching
         *
         * For example, "solutionId" will come as number, but "this.guid" is string,
         * or, "solutionId" will come as string, but "this.hpoId" is number
         * */
        return [`${this.guid}`, `${this.id}`, `${this.hpoId}`].includes(`${solutionId}`);
    }
}
__decorate([
    observable
], Solution.prototype, "products", void 0);
__decorate([
    computed
], Solution.prototype, "sortedProducts", null);
__decorate([
    computed
], Solution.prototype, "diamondIds", null);
__decorate([
    computed
], Solution.prototype, "hasOneProduct", null);
__decorate([
    computed
], Solution.prototype, "roughIds", null);
__decorate([
    action
], Solution.prototype, "replaceProduct", null);
