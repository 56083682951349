import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import './Content.less';
import AppStore from '../../../stores/AppStore';

@inject('appStore')
@observer
export default class Content extends Component {
  static propTypes = {
    appStore: PropTypes.instanceOf(AppStore).isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    contrast: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    contrast: false,
  };

  constructor(props) {
    super(props);

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.handleWidthChanges();

    window.addEventListener('resize', this.handleWidthChanges);

    this.windowSizeReactionDisposer = reaction(
      () => this.props.appStore.isSidebarOpened,
      () => {
        setTimeout(this.handleWidthChanges);
      },
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWidthChanges);
    this.windowSizeReactionDisposer();
  }

  handleWidthChanges = debounce(() => {
    if (!this.containerRef.current) {
      return;
    }

    this.props.appStore.setContainerWidth(this.containerRef.current.offsetWidth);
  }, 100, { leading: true });

  render() {
    return (
      <div ref={this.containerRef} className={`content ${this.props.className} ${this.props.contrast ? 'content--contrast' : ''}`}>
        {this.props.children}
      </div>
    );
  }
}
