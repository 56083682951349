import {IMAGE, VIDEO_MONO, VIDEO_STEREO} from '../constants/mediaSourceTypes';

export const I_FACEUP_FLUOR = 31;
export const GYRO_DARKFIELD = 32;
export const GYRO_FIRE_2D = 33;
export const VIDEO_360_GIRDLE = 1;
export const VIDEO_360_PAVILION = 2;
export const DARKFIELD_CROWN = 23;
export const VIBOX_GYRO_FIRE = 28;
export const VIBOX_VERTICAL_SWINGING = 3;
export const VIBOX_SWINGING = 4;
export const I_OFFICE = 7;
export const I_ASET_BLACK = 10;
export const I_ASET_WHITE = 11;
export const I_ARROWS = 13;
export const I_HEARTS = 14;
export const I_MUNSELL_CLOSEUP = 16;
export const I_MUNSELL = 15;
export const I_DARKFIELD = 50;
export const DARKFIELD = 51;
export const I_SIDE_FLUOR = 55;
export const I_ASET_BLACK_TABLEDOWN = 58;
export const I_WHITEDOME = 61;
export const I_FACEUP_FLUOR_DARK = 62;
export const I_SIDE_FLUOR_DARK = 63;

export const GYRO_SETUP_PRESETS = [GYRO_DARKFIELD, GYRO_FIRE_2D];

export const LP_FACEUP_FLUOR = 38;
export const LP_TCOLOR_LAB = 64;
export const SCINTILLATION = 40;
export const LP_OFFICE = 41;
export const I_TCOLOR_OUTDOOR = 42;
export const FIRE = 43;
export const LP_FIRE = 44;
export const OFFICE = 45;
export const LP_ASET_BLACK = 47;
export const I_PAVCOLOR_LAB = 52;
export const LP_PAVCOLOR_LAB = 53;
export const I_FIRE = 54;
export const I_TCOLOR_LAB = 56;
export const I_TCOLOR_UVFREE = 57;
export const I_BRIGHTFIELD = 59;
export const BRIGHTFIELD = 60;
export const LP_SIDE_FLUOR = 68;
export const VISION_360_GIRDLE = 81;
export const VISION_360_PAVILION = 82;
export const ROUGH_360_GIRDLE = 88;
export const ROUGH_360_PAVILION = 89;

const EXCLUDED_SINGLE_SCALE_SETUP_PRESETS = [
  I_MUNSELL,
  I_MUNSELL_CLOSEUP,
  VISION_360_GIRDLE,
  VISION_360_PAVILION,
  ROUGH_360_GIRDLE,
  ROUGH_360_PAVILION,
];

/**
 * @typedef {Object} SetupPresetObject
 * @property {number} id
 * @property {number} direction
 * @property {string} oldTitle
 * @property {string} title
 * @property {Object} mediaSourceType
 * @property {boolean} isStandard
 */

export default class SetupPreset {
  /**
   * @type {number}
   */
  id;

  /**
   * @type {string}
   */
  title;

  /**
   * @type {number}
   */
  direction;

  /**
   * @type {number}
   */
  mediaSourceType;

  /**
   * @type {string}
   */
  bg;

  /**
   * @type {boolean}
   */
  isStandard;

  /**
   * @param {{id: number, direction: number, title: string, mediaSourceType: number, bg: string}} modelData
   * @param {boolean} isStandard
   * @constructor
   */
  constructor(modelData, isStandard) {
    if (!modelData) {
      return;
    }

    this.id = modelData.id;
    this.direction = modelData.direction;
    this.title = modelData.title;
    this.mediaSourceType = modelData.mediaSourceType;
    this.isStandard = isStandard;
    this.bg = modelData.bg;
  }

  /**
   * @return {boolean}
   */
  get isImage() {
    return this.mediaSourceType === IMAGE;
  }

  /**
   * @return {boolean}
   */
  get isVideo() {
    return this.mediaSourceType === VIDEO_MONO || this.mediaSourceType === VIDEO_STEREO;
  }

  /**
   * @return {boolean}
   */
  get isFourView() {
    return this.id === VIDEO_360_GIRDLE || this.id === VIDEO_360_PAVILION;
  }

  /**
   * @return {boolean}
   */
  get isGyro() {
    return GYRO_SETUP_PRESETS.includes(this.id);
  }

  /**
   * @return {boolean}
   */
  get isPossibleSingleScale() {
    return !EXCLUDED_SINGLE_SCALE_SETUP_PRESETS.includes(this.id);
  }
}
