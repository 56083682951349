import {
  MEDIA_SIZE_FULL,
  MEDIA_SIZE_LARGE,
  MEDIA_SIZE_MEDIUM,
  MEDIA_SIZE_SMALL,
  MEDIA_SIZE_XSMALL,
  MEDIA_SIZE_XXSMALL,
} from './sizeCodes';
import {
  MEDIA_SIZE_160, MEDIA_SIZE_320, MEDIA_SIZE_40, MEDIA_SIZE_480, MEDIA_SIZE_80,
} from './sizes';

export const MEDIA_SIZE_MAP = {
  [MEDIA_SIZE_XXSMALL]: MEDIA_SIZE_40,
  [MEDIA_SIZE_XSMALL]: MEDIA_SIZE_80,
  [MEDIA_SIZE_SMALL]: MEDIA_SIZE_160,
  [MEDIA_SIZE_MEDIUM]: MEDIA_SIZE_320,
  [MEDIA_SIZE_LARGE]: MEDIA_SIZE_480,
  [MEDIA_SIZE_FULL]: MEDIA_SIZE_FULL,
};
