import Storage from '../services/Storage';

export const RESET_CACHE_ON_APP_VERSION_UPDATE = 'on-app-version-update';

const CACHE_INDEX_KEY = 'network-cache-index';

export default class Cache {
  static getEntry(key) {
    const cacheEntry = Storage.getObject(key, Storage.STORAGE_LOCAL);

    return cacheEntry.content;
  }

  static hasEntry(key) {
    return Storage.hasObject(key, Storage.STORAGE_LOCAL) && Cache.indexHasEntry(key);
  }

  static saveEntry(key, content, config) {
    Storage.saveObject(key, { content, meta: {}}, Storage.STORAGE_LOCAL);

    if (config.resetStrategy === RESET_CACHE_ON_APP_VERSION_UPDATE) {
      Cache.addToIndex(key);
    }

    // @todo: implement different cache cleaning strategies ("on version update" by default)
  }

  static remove(key) {
    Storage.removeObject(key, Storage.STORAGE_LOCAL);
    Cache.removeFromIndex(key);
  }

  static clearIndex() {
    Storage.removeObject(CACHE_INDEX_KEY, Storage.STORAGE_LOCAL);
  }

  /**
   * @private
   * @param {string} key
   */
  static addToIndex(key) {
    let index = Storage.getObject(CACHE_INDEX_KEY, Storage.STORAGE_LOCAL);

    if (!index) {
      index = {entries: []};
    }

    if (!index.entries.includes(key)) {
      index.entries.push(key);
    }

    Storage.saveObject(CACHE_INDEX_KEY, index, Storage.STORAGE_LOCAL);
  }

  /**
   * @private
   * @param {string} key
   */
  static removeFromIndex(key) {
    const index = Storage.getObject(CACHE_INDEX_KEY, Storage.STORAGE_LOCAL);
    const entriesWithoutKey = index.filter(entry => entry !== key);

    Storage.saveObject(CACHE_INDEX_KEY, { entries: entriesWithoutKey }, Storage.STORAGE_LOCAL);
  }

  /**
   * @private
   * @param {string} key
   * @return {boolean}
   */
  static indexHasEntry(key) {
    const index = Storage.getObject(CACHE_INDEX_KEY, Storage.STORAGE_LOCAL);

    if (!index || !index.entries || index.entries.length === 0) {
      return false;
    }

    return index.entries.includes(key);
  }
}
