import {IS_SAFARI} from './mobileDetect';

function scrollPolyfill(scrollElement, top, left, duration = 200) {
  const startTop = scrollElement.scrollTop;
  const startLeft = scrollElement.scrollLeft;
  const startTime = performance.now();
  const destinationOffsetToScrollTop = Math.ceil(top);
  const destinationOffsetToScrollLeft = Math.ceil(left);

  function scroll() {
    const now = performance.now();
    const time = Math.min(1, ((now - startTime) / duration));
    scrollElement.scrollTop = Math.ceil((time * (destinationOffsetToScrollTop - startTop)) + startTop);
    scrollElement.scrollLeft = Math.ceil((time * (destinationOffsetToScrollLeft - startLeft)) + startLeft);

    const isComplete = scrollElement.scrollTop === destinationOffsetToScrollTop && scrollElement.scrollLeft === destinationOffsetToScrollLeft;

    if (isComplete || (now - startTime) > duration) {
      return;
    }

    requestAnimationFrame(scroll);
  }

  scroll();
}

export default function scrollTo(top = 0, left = 0, element = null) {
  const scrollElement = element || window;

  if (window.scrollTo) {
    try {
      if (IS_SAFARI && scrollElement !== window) {
        scrollPolyfill(scrollElement, top, left, 200);
      } else {
        scrollElement.scrollTo({
          top: top,
          left: left,
          behavior: 'smooth',
        });
      }
    } catch (e) {
      console.warn(e);
    }
  } else {
    scrollElement.scrollTop = top;
    scrollElement.scrollLeft = left;
  }
}
