import CutwiseAPIClient, { METHOD_GET, METHOD_POST } from '@/common/network/CutwiseAPIClient';
const PATH_BILLING_ACCOUNT = 'api/billing/account';
const PATH_BILLING_ACCOUNT_EXPENSES = 'api/billing/account/expenses';
const PATH_BILLING_ACCOUNT_DASHBOARD = 'api/billing/account/dashboard';
const PATH_BILLING_TRANSACTIONS = 'api/billing/transaction';
const PATH_EXPORT_BILLING_TRANSACTIONS = 'api/billing/transaction/export';
const PATH_BILLING_PRODUCT_TRANSACTIONS = 'api/billing/transaction/product';
const PATH_BILLING_PAYMENT = 'api/billing/payment/enroll';
export default class BillingAPI {
    getBillingAccount() {
        const qb = CutwiseAPIClient.createQueryBuilder().method(METHOD_GET).path(PATH_BILLING_ACCOUNT);
        const query = qb.getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
    getAccountExpensesById(filter) {
        const qb = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_GET)
            .path(PATH_BILLING_ACCOUNT_EXPENSES)
            .addQueryStringParam('id', filter.account)
            .addQueryStringParam('period', filter.period);
        const query = qb.getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
    getAccountDashboardById(id) {
        const qb = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_GET)
            .path(PATH_BILLING_ACCOUNT_DASHBOARD)
            .addQueryStringParam('id', id);
        const query = qb.getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
    getBillingTransactions(offset, limit, filter, order) {
        const qb = CutwiseAPIClient.createQueryBuilder().method(METHOD_GET).path(PATH_BILLING_TRANSACTIONS);
        qb.offset(offset);
        qb.limit(limit);
        qb.addQueryStringParam('filter', filter);
        qb.addQueryStringParam('order', order);
        const query = qb.getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
    exportBillingTransactionsToCSV(filter) {
        const qb = CutwiseAPIClient.createQueryBuilder().method(METHOD_GET).path(PATH_EXPORT_BILLING_TRANSACTIONS).asText();
        qb.addQueryStringParam('filter', filter);
        const query = qb.getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
    getProductTransactions(productId) {
        const qb = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_GET)
            .path(`${PATH_BILLING_PRODUCT_TRANSACTIONS}/${productId}`);
        const query = qb.getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
    getPaymentEnroll(values) {
        const qb = CutwiseAPIClient.createQueryBuilder().method(METHOD_POST).path(PATH_BILLING_PAYMENT).body(values);
        const query = qb.getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
}
