import { COMPARE_DIAMOND_GROUP, COMPARE_ROUGH_GROUP, TYPE_DIAMOND, TYPE_ROUGH, } from '@/product/constants/productTypes';
export function getCompareContextByType(type) {
    if (COMPARE_DIAMOND_GROUP.has(type)) {
        return TYPE_DIAMOND;
    }
    if (COMPARE_ROUGH_GROUP.has(type)) {
        return TYPE_ROUGH;
    }
    return type;
}
