import { observable, computed } from 'mobx';
import Product from './Product';
import {TYPE_LGD_ROUGH, TYPE_ROUGH} from '../constants/productTypes';

export default class Rough extends Product {
  /**
   * @type {number}
   */
  @observable width;

  /**
   * @type {number}
   */
  @observable height;

  /**
   * @type {number}
   */
  @observable length;

  entityType = TYPE_ROUGH;

  @computed
  get dimensions() {
    if (this.width && this.height && this.length) {
      return `${this.width}×${this.height}×${this.length}`;
    }

    return null;
  }

  /**
   * @override
   * @return {string}
   */
  @computed
  get type() {
    if (this.isLabGrown) {
      return TYPE_LGD_ROUGH;
    }

    return TYPE_ROUGH;
  }
}
