import CutwiseAPIClient, {METHOD_GET} from '../../common/network/CutwiseAPIClient';

export const PATH_FILTERS = 'api/v2/b2b/filters';

export default class B2BAPI {
  /**
   * @return {Promise<QueryResult>}
   */
  fetchB2BFilters() {
    const qb = CutwiseAPIClient.createQueryBuilder()
      .method(METHOD_GET)
      .path(PATH_FILTERS);

    const query = qb.getQuery();

    return CutwiseAPIClient.sendQuery(query);
  }
}
