export const SETUP_PRESET_GROUP_ASET = 1;
export const SETUP_PRESET_GROUP_MACBETH = 6;
export const SETUP_PRESET_GROUP_SWINGING = 14;
export const SETUP_PRESET_GROUP_FULL_ROTATION = 15;
export const SETUP_PRESET_GROUP_VIBOX = 16;
export const SETUP_PRESET_GROUP_CUSTOM_LIGHTINGS = 22;

export const SETUP_PRESET_GROUP_OFFICE = 11;
export const SETUP_PRESET_GROUP_FIRE_DIBOX = 17;

export const SETUP_PRESET_GROUP_DARKFIELD = 7;
export const SETUP_PRESET_GROUP_TABLE_COLOR_LAB = 4;
export const SETUP_PRESET_GROUP_PAV_COLOR_LAB = 5;
export const SETUP_PRESET_GROUP_TABLE_COLOR_UVFREE = 19;
export const SETUP_PRESET_GROUP_PAV_COLOR_UVFREE = 20;
export const SETUP_PRESET_GROUP_FLUOR_TABLE = 12;
export const SETUP_PRESET_GROUP_FLUOR_SIDE = 13;

export const SETUP_PRESET_GROUP_GRETAG = 18;

export const IGNORED_FOR_REFERENCE_COMPARISON = [
  SETUP_PRESET_GROUP_FULL_ROTATION,
  SETUP_PRESET_GROUP_VIBOX,
  SETUP_PRESET_GROUP_DARKFIELD,
  SETUP_PRESET_GROUP_TABLE_COLOR_LAB,
  SETUP_PRESET_GROUP_PAV_COLOR_LAB,
  SETUP_PRESET_GROUP_FLUOR_TABLE,
  SETUP_PRESET_GROUP_FLUOR_SIDE,
];

export const SPG_ORDER_SOLUTION_LIST_DZ = [
  SETUP_PRESET_GROUP_OFFICE,
  SETUP_PRESET_GROUP_TABLE_COLOR_UVFREE,
  SETUP_PRESET_GROUP_PAV_COLOR_UVFREE,
  SETUP_PRESET_GROUP_ASET,
];

export const SPG_ORDER_SOLUTION_LIST_FANCY_COLOR = [
  SETUP_PRESET_GROUP_OFFICE,
  SETUP_PRESET_GROUP_TABLE_COLOR_UVFREE,
  SETUP_PRESET_GROUP_PAV_COLOR_UVFREE,
  SETUP_PRESET_GROUP_ASET,
];

/**
 * Entity for composition of different setup presets. For example, we merge Fire and Fire Live Photo in one SetupPresetGroup. If product doesn't have Fire video then Fire Live Photo will be displayed
 */
export default class SetupPresetGroup {
  /**
   * @type {number} id
   */
  id;

  /**
   * Indicates whether the Power component is present.
   *
   * @type {number}
   */
  position;

  /**
   * Indicates whether the SetupPresetGroup should be displayed with exact context (for example, different combo tables could have different fields. So we have to hide certain SetupPresetGroups)
   *
   * @type {string[]} scope
   */
  scope;

  /**
   * {string}
   */
  title;

  /**
   * List of related SetupPresets
   *
   * {SetupPreset[]} sp
   */
  sp;

  constructor({
    id, position, scope, title, sp,
  }) {
    this.id = id;
    this.position = position;
    this.scope = scope;
    this.title = title;
    this.sp = sp;
  }
}
