import styles from 'styles/mixins/_variables.less';

export const SCREEN_XS = parseInt(styles.screenxs, 10);
export const SCREEN_SM = parseInt(styles.screensm, 10);
export const SCREEN_MD = parseInt(styles.screenmd, 10);
export const SCREEN_LG = parseInt(styles.screenlg, 10);
export const SCREEN_ML = parseInt(styles.screenml, 10);
export const SCREEN_XLG = parseInt(styles.screenxlg, 10);
export const SCREEN_XXLG = parseInt(styles.screenxxlg, 10);
export const SCREEN_XXXLG = parseInt(styles.screenxxxlg, 10);
