import {
  observable, action, autorun, computed,
} from 'mobx';
import {NETBOX_DOMAIN, NETBOX_STATUS_ACTIVE, NETBOX_STATUS_DISABLED} from '../constants/statuses';
import {STATUS_GET, STATUS_INIT, STATUS_SEND} from '../constants/events';
import Product from '../../product/entity/Product';
import ProxyCache from '../services/ProxyCache';
import Collection from '../../collection/entity/Collection';
import {
  BRIGHTFIELD,
  DARKFIELD, FIRE,
  I_ARROWS,
  I_ASET_BLACK,
  I_ASET_BLACK_TABLEDOWN,
  I_ASET_WHITE, I_BRIGHTFIELD, I_DARKFIELD, I_FACEUP_FLUOR, I_FIRE,
  I_HEARTS, I_OFFICE, I_PAVCOLOR_LAB, I_SIDE_FLUOR, I_SIDE_FLUOR_DARK, I_TCOLOR_LAB, I_TCOLOR_OUTDOOR, I_TCOLOR_UVFREE,
  I_WHITEDOME, LP_ASET_BLACK, LP_FACEUP_FLUOR, LP_FIRE, LP_OFFICE, LP_PAVCOLOR_LAB, LP_SIDE_FLUOR, LP_TCOLOR_LAB, OFFICE,
} from '../../media/entity/SetupPreset';
import Project from '../../hpo/entity/Project';
import {NETBOX, securityManager} from '@/common/services/SecurityManager';
import storage from '@/common/services/Storage';
import {NETBOX_KEY} from '@/common/network/CutwiseAPIClient';
import {configurationStore} from '@/common/stores/ConfigurationStore';

export default class NetBoxStore {
  @observable
  status = NETBOX_STATUS_DISABLED;

  @observable
  isServiceWorkerActivated = false;

  /**
   * @type {[LoadingTask]}
   */
  @observable
  tasks = [];

  constructor(configurationStore) {
    this.configurationStore = configurationStore;

    this.isServiceWorkerActivated = Boolean(navigator.serviceWorker && navigator.serviceWorker.controller);

    if (!navigator.serviceWorker) {
      return;
    }

    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data.code === STATUS_INIT) {
        this.isServiceWorkerActivated = true;
      }

      if (event.data.code === STATUS_GET) {
        this.sendNetBoxStatusToServiceWorker(this.status);
      }
    });

    autorun(this.sendNetBoxStatusToServiceWorker);
  }

  @computed
  get isAccessible() {
    return this.status !== NETBOX_STATUS_DISABLED;
  }

  @computed
  get isSaveToNetBoxAvailable() {
    return this.status !== NETBOX_STATUS_DISABLED || securityManager.isGranted(NETBOX) || !storage.getObject(NETBOX_KEY, storage.STORAGE_LOCAL)
  }

  sendNetBoxStatusToServiceWorker = () => {
    if (!this.isServiceWorkerActivated || !navigator.serviceWorker || !navigator.serviceWorker.controller) {
      return;
    }

    navigator.serviceWorker.controller.postMessage({ code: STATUS_SEND, status: this.status });
  };

  connectToNetBox = () => {
    if (this.status !== NETBOX_STATUS_DISABLED) {
      return;
    }

    this.status = this.configurationStore.isNetbox ? NETBOX_STATUS_ACTIVE : NETBOX_STATUS_DISABLED;
  };

  /**
   * @param {Product|Collection} entity
   * @return {?Promise<LoadingTask>}
   */
  save(entity) {
    let promise = null;

    if (entity instanceof Product) {
      promise = ProxyCache.saveProducts([entity], `${entity.b2bSid}, ${entity.shortView}`, [160, 320, 480, 640, 960, 1200, 1920]);
    }

    if (entity instanceof Collection || entity instanceof Project) {
      promise = ProxyCache.saveProducts(entity.products, entity.title, [160, 320, 480, 640, 960], [
        I_HEARTS,
        I_ARROWS,
        I_ASET_WHITE,
        I_ASET_BLACK,
        I_ASET_BLACK_TABLEDOWN,
        I_WHITEDOME,
        I_BRIGHTFIELD,
        I_TCOLOR_LAB,
        I_PAVCOLOR_LAB,
        I_TCOLOR_OUTDOOR,
        I_TCOLOR_UVFREE,
        I_SIDE_FLUOR,
        I_SIDE_FLUOR_DARK,
        I_FACEUP_FLUOR,
        I_DARKFIELD,
        I_OFFICE,
        I_FIRE,
        LP_ASET_BLACK,
        LP_SIDE_FLUOR,
        LP_FACEUP_FLUOR,
        LP_OFFICE,
        LP_FIRE,
        LP_TCOLOR_LAB,
        LP_PAVCOLOR_LAB,
        DARKFIELD,
        OFFICE,
        FIRE,
        BRIGHTFIELD,
      ]);
    }

    if (!promise) {
      return null;
    }

    return promise.then((loadingTask) => {
      if (loadingTask) {
        this.tasks.push(loadingTask);
      }

      return loadingTask;
    });
  }

  /**
   * @param {string} id
   * @return {Promise}
   */
  waitForLoadingComplete(id) {
    return new Promise((resolve) => {
      const intervalId = setInterval(() => {
        fetch(`${NETBOX_DOMAIN}/cache/task/${id}`)
          .then(response => response.json())
          .then(action((taskResponse) => {
            if (!taskResponse || taskResponse.done === taskResponse.total) { // task was removed from server and that mean that in was fully completed
              clearInterval(intervalId);
              resolve();
            }

            if (taskResponse) {
              const task = this.tasks.find(t => t.id === taskResponse.id);
              if (task) {
                task.done = taskResponse.done;
              }
            }
          }));
      }, 1000);
    });
  }
}

export const netBoxStore = new NetBoxStore(configurationStore)
