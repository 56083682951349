import {
  action, computed, observable,
} from 'mobx';
import B2BAPI from '../../b2b/api/B2BAPI';
import ConstantsAPI from '../api/ConstantsAPI';
import {dictionaryRepository} from '../repositories/DictionaryRepository';
import {setupPresetRepository} from '../../media/repository/SetupPresetRepository';
import {viewTemplateRepository} from '../../collection/repository/ViewTemplateRepository';
import {setupPresetGroupRepository} from '../../media/repository/SetupPresetGroupRepository';
import {sortRepository} from '../../catalog/repository/SortRepository';
import {scoringRepository} from '../../metric/repositories/ScoringRepository';
import {mediaSubFormatRepository} from '../../media/repository/MediaSubFormatRepository';
import config from '../../../config';
import { APP_VERSION_KEY, HEADER_X_NETBOX, HEADER_X_VERSION, NETBOX_KEY } from '../network/CutwiseAPIClient';
import settingsPreset from 'assets/data/settingsPreset.json';
import Storage from '../services/Storage';

/**
 * @typedef {Object} ScoringConfiguration
 * @property {{grades: [number], ver: string}} brightness
 * @property {{grades: [number], ver: string}} fire
 * @property {{ver: string}} fluorescence
 * @property {{grades: [number], ver: string}} integral
 * @property {{grades: [number], ver: string}} symmetry
 * @property {{grades: [number], ver: string}} spread
 */

export default class ConfigurationStore {
  @observable.ref
  b2bFilters;

  // `transactionTypeNames` means transactions with products,
  // Other known names: `service`, `type`
  @observable
  transactionTypeNames;

  // `productTypeNames` means type of product: "Colorless", "Fancy", "Rough", "Jewelry", etc
  @observable
  productTypeNames;

  @observable
  financeEmail;

  @observable
  wsServerURL;

  @observable
  isNetbox = false;

  constructor() {
    this.b2bAPI = new B2BAPI();
    this.constantsAPI = new ConstantsAPI();
  }

  @computed
  get isConfigurationLoaded() {
    return Boolean(dictionaryRepository.dictionaries) && Boolean(setupPresetRepository.setupPresets);
  }

  /**
   * @return {Promise}
   */
  @action
  fetchB2BFilters() {
    if (this.b2bFilters) {
      return Promise.resolve(this.b2bFilters);
    }

    return this.b2bAPI
      .fetchB2BFilters()
      .then(res => res.data)
      .then(this.processAndApplyB2BList);
  }

  /**
   * @return {Promise}
   */
  @action
  fetchServerConstants() {
    return this.constantsAPI
      .fetchAll()
      .then((res) => {
        const appVersion = res.networkResponse?.headers.get(HEADER_X_VERSION);
        const netBoxVersion = res.networkResponse?.headers.get(HEADER_X_NETBOX);

        if (netBoxVersion) {
          Storage.saveObject(NETBOX_KEY, netBoxVersion, Storage.STORAGE_LOCAL);
        }

        if (appVersion) {
          Storage.saveObject(APP_VERSION_KEY, appVersion, Storage.STORAGE_LOCAL);
        }

        this.isNetbox = !!Storage.getObject(NETBOX_KEY, Storage.STORAGE_LOCAL);

        return res.content;
      })
      .then(this.processAndApplyConstants);
  }

  /**
   * @param {Object} serverResponse
   */
  @action
  processAndApplyConstants = (serverResponse) => {
    config.PLAYER_JS_URL = serverResponse.web.player.js;
    config.PLAYER_CSS_URL = serverResponse.web.player.css;

    setupPresetGroupRepository.initSorts(serverResponse.productTypes);
    sortRepository.initSorts(serverResponse.dict.sorts, serverResponse.productTypes); // todo make serverResponse immutable
    dictionaryRepository.initDictionaries(serverResponse.dict);
    scoringRepository.initScoring(serverResponse.scoring);
    scoringRepository.initMetricDataDecorators(serverResponse.metricDataDecorators);
    scoringRepository.initMetricThresholds(serverResponse.metricThresholds);
    mediaSubFormatRepository.initMediaSubFormats(serverResponse.dict.subFormats);
    setupPresetRepository.initSetupPresets(serverResponse.dict.setupPresets);
    setupPresetRepository.initSetupPresetOrder(serverResponse.spOrder);
    setupPresetGroupRepository.initSetupPresetGroups(serverResponse.dict.setupPresetGroups);
    viewTemplateRepository.initViewTemplates(settingsPreset);

    this.transactionTypeNames = serverResponse.transactionTypeNames;
    this.productTypeNames = serverResponse.ptypes;
    this.financeEmail = serverResponse.web.financeEmail;
    this.scaleBaseUri = serverResponse.web.scaleBaseUri;
    this.wsServerURL = serverResponse.web.wsServerURL;
  };

  @action
  processAndApplyB2BList = (serverResponse) => {
    const b2bByProductTypes = serverResponse.productTypes;

    Object.keys(b2bByProductTypes).forEach((productType) => {
      b2bByProductTypes[productType] = b2bByProductTypes[productType].map(b2bId => serverResponse.b2b.find(b2b => b2b.id === b2bId));
    });

    this.b2bFilters = b2bByProductTypes;
  };
}

export const configurationStore = new ConfigurationStore();
