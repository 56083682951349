import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from 'react';
import Analytics from '../services/Analytics';
export default function withPageViewHOC(WrappedComponent, page = null) {
    return class extends Component {
        componentDidMount() {
            const params = {};
            if (page) {
                params.page = page;
            }
            Analytics.sendPageview(params);
        }
        render() {
            return _jsx(WrappedComponent, { ...this.props });
        }
    };
}
