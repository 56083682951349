import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './ProductInfo.less';
import Product from '../../../../../../product/entity/Product';
import SetupPreset from '../../../../../../media/entity/SetupPreset';
import ProductLink from '../../../../../../product/components/ProductLink/ProductLink';
import ProductPrice from '../../../../../../product/components/ProductPrice/ProductPrice';

export default class ProductInfo extends Component {
  static propTypes = {
    product: PropTypes.instanceOf(Product).isRequired,
    setupPreset: PropTypes.instanceOf(SetupPreset),
    showPrice: PropTypes.bool,
    showTitle: PropTypes.bool,
    showB2BSid: PropTypes.bool,
    isSmall: PropTypes.bool,
  };

  static defaultProps = {
    setupPreset: null,
    showPrice: true,
    showTitle: true,
    showB2BSid: true,
    isSmall: false,
  };

  render() {
    return (
      <div className={`${styles.productInfo} ${this.props.isSmall ? styles.productInfoSmall : null}`}>
        <div className={styles.productInfoDesc}>
          {this.props.showB2BSid && <ProductLink className={styles.productInfoShortView} product={this.props.product} setupPreset={this.props.setupPreset} text={this.props.product.b2bSid} />}
          {this.props.showTitle && <ProductLink className={styles.productInfoShortView} product={this.props.product} setupPreset={this.props.setupPreset} />}
        </div>
        {this.props.showPrice && (
          <div className={styles.productInfoDescRight}>
            {this.props.showPrice && <ProductPrice product={this.props.product} />}
          </div>
        )}
      </div>
    );
  }
}
