import React, {Component} from 'react';
import {observer} from 'mobx-react';
import styles from './HostedStockTable.module.css';
import Icon from '../../../common/components/Icon/Icon';
import Link from '../../../common/components/Link/Link';

@observer
export default class HostedStockTable extends Component {
  render() {
    return (
      <div className={styles.hostedStockTableWrap}>
        <table className={styles.hostedStockTable}>
          <thead>
          <tr>
            <th title="Publication Age">Pub.Age</th>
            <th title="Hosted Products Count">Products</th>
            <th></th>
            <th title="Hosting Expenses in Previous Periods">Spent to Host</th>
            <th title="Hosting Payments in the Next Month">Next Payment</th>
            <th>Manage</th>
          </tr>
          </thead>
          <tbody className={styles.blockOpened}>
          <tr>
            <td className={`${styles.text} ${styles.strong}`}>All</td>
            <td className={styles.text}>All</td>
            <td>5 011</td>
            <td>3 495.00 $</td>
            <td className={styles.strong}>3 495.00 $</td>
            <td><Icon name="arrow-lt-down"/></td>
          </tr>
          <tr className={styles.detailLine}>
            <td></td>
            <td className={styles.text}>Colorless Diams</td>
            <td>400</td>
            <td>480.00 $</td>
            <td className={styles.strong}>80.00 $</td>
            <td><Link href="#">Manage</Link></td>
          </tr>
          <tr className={styles.detailLine}>
            <td></td>
            <td className={styles.text}>Fancy Color Diams</td>
            <td>400</td>
            <td>480.00 $</td>
            <td className={styles.strong}>80.00 $</td>
            <td><Link href="#">Manage</Link></td>
          </tr>
          <tr className={styles.detailLine}>
            <td></td>
            <td className={styles.text}>Rough</td>
            <td>400</td>
            <td>480.00 $</td>
            <td className={styles.strong}>80.00 $</td>
            <td><Link href="#">Manage</Link></td>
          </tr>
          <tr className={styles.detailLine}>
            <td></td>
            <td className={styles.text}>Jewelry</td>
            <td>400</td>
            <td>480.00 $</td>
            <td className={styles.strong}>80.00 $</td>
            <td><Link href="#">Manage</Link></td>
          </tr>
          </tbody>
          <tbody>
          <tr>
            <td className={`${styles.text} ${styles.strong}`}>New</td>
            <td className={styles.text}>{this.renderHostringProportionBar(35, 100)}</td>
            <td>504</td>
            <td>—</td>
            <td className={styles.strong}>3 495.00 $</td>
            <td><Icon name="arrow-lt-down"/></td>
          </tr>
          </tbody>
          <tbody>
          <tr>
            <td className={`${styles.text} ${styles.strong}`}>1 month</td>
            <td className={styles.text}>{this.renderHostringProportionBar(64, 100)}</td>
            <td>695</td>
            <td>3 495.00 $</td>
            <td className={styles.strong}>3 495.00 $</td>
            <td><Icon name="arrow-lt-down"/></td>
          </tr>
          </tbody>
          <tbody>
          <tr>
            <td className={`${styles.text} ${styles.strong}`}>2 month</td>
            <td className={styles.text}>{this.renderHostringProportionBar(45, 100)}</td>
            <td>5 011</td>
            <td>3 495.00 $</td>
            <td className={styles.strong}>3 495.00 $</td>
            <td><Icon name="arrow-lt-down"/></td>
          </tr>
          </tbody>
        </table>
      </div>
    );
  };

  renderHostringProportionBar(value, total) {
    const width = `${Math.round(value / total * 100)}%`;
    return (
      <div className={styles.hostingProportionBar} style={{width: width}}/>
    );
  };
}
