import { format, formatDuration, intervalToDuration, isValid } from 'date-fns';
import { BASIC_DATE, FULL_HUMAN_DATE_TIME, HUMAN_DATE, SERVER_DATE, SERVER_DATE_TIME } from './dateFormat.constants';
export default {
    format(date, pattern) {
        const dateValue = new Date(date);
        return dateValue && isValid(dateValue) ? format(dateValue, pattern) : '';
    },
    toServer(date) {
        return this.format(date, SERVER_DATE_TIME);
    },
    toServerDate(date) {
        return this.format(date, SERVER_DATE);
    },
    toBaseDate(date) {
        return this.format(date, BASIC_DATE);
    },
    toHumanDate(date) {
        return this.format(date, HUMAN_DATE);
    },
    toFullHumanDateTime(date) {
        return this.format(date, FULL_HUMAN_DATE_TIME);
    },
    toDistance(start, end, options = {}) {
        const startDate = new Date(start);
        const endDate = new Date(end);
        if (isValid(startDate) && isValid(endDate)) {
            const { limit, useShortLabels } = options;
            const duration = intervalToDuration({
                start: new Date(startDate),
                end: new Date(endDate),
            });
            // these keys `date-fns` are accepted in `formatDuration` function
            // link: https://date-fns.org/v2.29.3/docs/formatDuration
            let formatKeys = ['years', 'months', 'days', 'hours', 'minutes', 'seconds'];
            const limitKeyIndex = formatKeys.findIndex((key) => key === limit);
            if (limitKeyIndex) {
                formatKeys = formatKeys.slice(0, limitKeyIndex + 1);
            }
            let format = [];
            // run through array and create pairs of keys, if value in `duration` greater than 0
            // pairs are:
            // ['minutes', 'seconds']
            // ['hours', 'minutes']
            // ['days', 'hours']
            // ['months', 'days']
            // ['years', 'months']
            //
            // example:
            // `duration.year > 0` -> ['years', 'months']
            // `duration.months > 0 && duration.year === 0` -> ['months', 'days']
            formatKeys.reverse().forEach((key, index, keys) => {
                if (!duration[key]) {
                    return;
                }
                format = [key];
                if (keys[index - 1]) {
                    format.push(keys[index - 1]);
                }
            });
            let distance = '';
            // `format` might be empty array when duration is less than limit,
            // e.g.: if `limit === 'months'` and duration is `10 days` - in this case we must return `0 m`
            if (format.length > 0) {
                distance = formatDuration(duration, {
                    delimiter: ' ',
                    format,
                });
            }
            else {
                distance = `0 ${limit}`;
            }
            if (useShortLabels) {
                // by default `date-fns` returns string with values: `years`, `year`, `months`, etc
                // we don't have ability to change it,
                // so below we replace long labels with short ones
                return distance
                    .replaceAll(/year[+s]?(?!\w)/g, 'y')
                    .replaceAll(/month[+s]?(?!\w)/g, 'm')
                    .replaceAll(/week[+s]?(?!\w)/g, 'w')
                    .replaceAll(/day[+s]?(?!\w)/g, 'd')
                    .replaceAll(/hour[+s]?(?!\w)/g, 'h')
                    .replaceAll(/minute[+s]?(?!\w)/g, 'm')
                    .replaceAll(/second[+s]?(?!\w)/g, 's');
            }
            return distance;
        }
        return '';
    },
    toDistanceFromNow(date, options = {}) {
        return this.toDistance(date, new Date(), options);
    },
};
