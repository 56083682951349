import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Layout.less';
import AppStore from '../../stores/AppStore';

@inject('appStore')
@observer
export default class Layout extends Component {
  static propTypes = {
    appStore: PropTypes.instanceOf(AppStore).isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    twoColumn: PropTypes.bool,
    strictHeight: PropTypes.bool,
    sidebarWidth: PropTypes.number,
  };

  static defaultProps = {
    className: '',
    twoColumn: false,
    strictHeight: false,
    sidebarWidth: null,
  };

  componentDidMount() {
    if (this.props.strictHeight) {
      document.body.classList.add(styles.layoutStrictHeight);
    }
  }

  componentDidUpdate() {
    if (this.props.strictHeight) {
      document.body.classList.add(styles.layoutStrictHeight);
    } else {
      document.body.classList.remove(styles.layoutStrictHeight);
    }
  }

  componentWillUnmount() {
    document.body.classList.remove(styles.layoutStrictHeight);
  }

  render() {
    const { isSidebarOpened } = this.props.appStore;

    const showSidebar = isSidebarOpened && this.props.twoColumn && !this.props.appStore.isPhone;
    let sidebarExistenceClass = '';

    if (this.props.twoColumn) {
      sidebarExistenceClass = showSidebar ? styles.layoutWithSidebar : styles.layoutWithoutSidebar;
    }

    return (
      <div
        style={{'--sidebar-width': this.props.sidebarWidth ? `${this.props.sidebarWidth}rem` : null}}
        className={`
          ${styles.layout}
          ${this.props.twoColumn ? styles.layoutTwoColumn : styles.layoutOneColumn}
          ${this.props.strictHeight ? styles.layoutStrictHeight : ''}
          ${sidebarExistenceClass}
          ${this.props.className}
        `}
      >
        {this.props.children}
      </div>
    );
  }
}
