import {computed, observable} from 'mobx';

export default class LoadingTask {
  @observable
  id;

  @observable
  title;

  @observable
  done;

  @observable
  total;

  constructor(modelData) {
    this.populate(modelData);
  }

  @computed
  get percentageOfCompletion() {
    return (this.done / this.total) * 100;
  }

  @computed
  get isCompleted() {
    return this.percentageOfCompletion === 100;
  }

  populate(modelData) {
    Object.assign(this, modelData);
  }
}
