import Sort from '../../common/state/Sort';
import CutwiseAPIClient, {METHOD_DELETE, METHOD_GET, METHOD_PATCH} from '../../common/network/CutwiseAPIClient';
import ConsoleLog from '../../common/network/middleware/ConsoleLog';
import TransformOriginalCaratToCarat from './middleware/TransformOriginalCaratToCarat';
import token from '@/user/services/token';
import FiltersJSONSerializer from '../../common/serializers/filters/FiltersJSONSerializer';
import TransformDatesMiddleware from './middleware/TransformDatesMiddleware';
import TransformB2bSidMiddleware from './middleware/TransformB2bSidMiddleware';
import TransformCaratWeightMiddleware from './middleware/TransformCaratWeightMiddleware';
import TransformPhotorealProductsInclusion from './middleware/TransformPhotorealProductsInclusion';
import TransformB2bMiddleware from './middleware/TransformB2bMiddleware';
import {SECURITY_CHECK_ACL} from '../../security/constants/securityCheck';
import {TYPE_JEWELRY, TYPE_LGD_ROUGH, TYPE_ROUGH} from '../constants/productTypes';
import Diamond from '../entity/Diamond';
import Rough from '../entity/Rough';
import Jewelry from '../entity/Jewelry';
import TransformCutShapeList from './middleware/TransformCutShapeList';
import URLBuilder from "../../common/network/URLBuilder";

export const PATH_DIAMOND = 'api/v2/diamonds';
export const PATH_ROUGH = 'api/v2/roughs';
export const PATH_JEWELRY = 'api/v2/jewelries';
export const PATH_PDF_REPORT = 'pdf/render/pdf';

const entityConstructors = {
  [PATH_DIAMOND]: Diamond,
  [PATH_ROUGH]: Rough,
  [PATH_JEWELRY]: Jewelry,
};

export default class ProductAPI {
  /**
   * @param {string} path
   * @param {number} b2bContext
   */
  constructor(path = PATH_DIAMOND, b2bContext) {
    this.path = path;
    this.b2bContext = b2bContext;
  }

  /**
   * @return {Product}
   */
  get entityConstructor() {
    return entityConstructors[this.path];
  }

  /**
   * @param {string|number} id
   * @param {boolean} shouldDumpResponse
   * @return {Promise<QueryResult>}
   */
  fetchItem(id, shouldDumpResponse) {
    const EntityConstructor = this.entityConstructor;

    const qb = CutwiseAPIClient.createQueryBuilder()
      .method(METHOD_GET)
      .path(`${this.path}/${id}`)
      .hydration((data) => {
        const product = new EntityConstructor(data);
        product.needFullDetails = false;

        return product;
      })
      .suppressErrorNotifications();

    if (shouldDumpResponse) {
      qb.useMiddlewareOnResponse(ConsoleLog);
    }

    const query = qb.getQuery();

    return CutwiseAPIClient.sendQuery(query);
  }

  /**
   * @param {number} id
   * @param {Object} data
   * @return {Promise<QueryResult>}
   */
  patch(id, data) {
    const qb = CutwiseAPIClient.createQueryBuilder()
      .method(METHOD_PATCH)
      .path(`${this.path}/${id}`)
      .body(data)
      .useMiddlewareOnRequest(TransformOriginalCaratToCarat);

    const query = qb.getQuery();

    return CutwiseAPIClient.sendQuery(query);
  }

  /**
   * @param {number} id
   * @return {Promise<QueryResult>}
   */
  delete(id) {
    const qb = CutwiseAPIClient.createQueryBuilder()
      .method(METHOD_DELETE)
      .path(`${this.path}/${id}`);

    const query = qb.getQuery();

    return CutwiseAPIClient.sendQuery(query);
  }

  /**
   * @returns {Promise<QueryResult>}
   */
  fetchOwned(isLabGrown = false) {
    const qb = CutwiseAPIClient.createQueryBuilder()
      .method(METHOD_GET)
      .path(this.path)
      .addQueryStringParam('filters', { b2b: token.b2bId, isLabGrown: isLabGrown })
      .shortResponseData()
      .limit(0);

    const query = qb.getQuery();

    return CutwiseAPIClient.sendQuery(query);
  }

  /**
   * @param {?Filters} filters
   * @param {?Sort} sort
   * @param {?number} offset
   * @param {?number} limit
   * @param {?boolean} indexOnly
   * @param {?string} securityCheck
   * @return {Promise<QueryResult>}
   */
  fetchProductsByFilters(filters = null, sort = null, offset = null, limit = null, indexOnly = false, securityCheck = SECURITY_CHECK_ACL) {
    let filtersJSON = null;
    if (filters) {
      filtersJSON = FiltersJSONSerializer.serialize(filters);
    }

    const qb = CutwiseAPIClient.createQueryBuilder()
      .method(METHOD_GET)
      .path(this.path)
      .useMiddlewareOnRequest(TransformDatesMiddleware)
      .useMiddlewareOnRequest(TransformB2bSidMiddleware)
      .useMiddlewareOnRequest(TransformCaratWeightMiddleware)
      .useMiddlewareOnRequest(TransformPhotorealProductsInclusion)
      .useMiddlewareOnRequest(TransformCutShapeList)
      .addQueryStringParam('filters', filtersJSON)
      .addQueryStringParam('order_by', sort ? sort.transform() : null)
      .offset(offset)
      .limit(limit)
      .securityCheck(securityCheck);

    if (this.b2bContext) {
      qb.useMiddlewareOnRequest(TransformB2bMiddleware(this.b2bContext));
    }

    if (indexOnly === true) {
      qb.indexResponseData();
    }

    const query = qb.getQuery();

    // todo hydration

    return CutwiseAPIClient.sendQuery(query);
  }

  /**
   * @param {Filters} filters
   * @param {?Sort} sort
   * @param {?number} offset
   * @return {Promise<QueryResult>}
   */
  fetchProductsCountByFilters(filters, sort = null, offset = null) {
    let filtersJSON = null;
    if (filters) {
      filtersJSON = FiltersJSONSerializer.serialize(filters);
    }

    const qb = CutwiseAPIClient.createQueryBuilder()
      .method(METHOD_GET)
      .path(this.path)
      .useMiddlewareOnRequest(TransformDatesMiddleware)
      .useMiddlewareOnRequest(TransformB2bSidMiddleware)
      .useMiddlewareOnRequest(TransformCaratWeightMiddleware)
      .useMiddlewareOnRequest(TransformPhotorealProductsInclusion)
      .addQueryStringParam('filters', filtersJSON)
      .addQueryStringParam('order_by', sort ? sort.transform() : null)
      .offset(offset)
      .indexResponseData()
      .limit(1);

    if (this.b2bContext) {
      qb.useMiddlewareOnRequest(TransformB2bMiddleware(this.b2bContext));
    }

    const query = qb.getQuery();

    // todo hydration

    return CutwiseAPIClient.sendQuery(query);
  }

  /**
   * @param {Array} idList
   * @param {boolean} isLabGrown
   * @param {?Sort} sort
   * @param {?boolean} indexOnly
   * @param {?string} scope
   * @return {Promise<QueryResult>}
   */
  fetchProductsById(idList, isLabGrown, sort = null, indexOnly = false, scope = null) {
    const correctedSort = sort && sort.getFirstField() === Sort.SORT_MANUAL ? null : sort;

    const qb = CutwiseAPIClient.createQueryBuilder()
      .method(METHOD_GET)
      .path(this.path)
      .addQueryStringParam('filters', {id: idList, isLabGrown: isLabGrown})
      .addQueryStringParam('order_by', correctedSort ? correctedSort.transform() : null)
      .addQueryStringParam('scope', scope)
      .limit(1000);

    if (indexOnly === true) {
      qb.indexResponseData();
    }

    const query = qb.getQuery();

    const EntityConstructor = this.entityConstructor;

    return CutwiseAPIClient.sendQuery(query).then((res) => {
      if (indexOnly === true) {
        return res;
      }

      return res.content
        ? res.content.map((d) => {
          // eslint-disable-next-line new-cap
          const product = new EntityConstructor(d);
          product.needFullDetails = false;

          return product;
        })
        : null;
    });
  }

  /**
   * @param {string} productType
   */
  updatePath(productType) {
    if (productType === TYPE_ROUGH || productType === TYPE_LGD_ROUGH) {
      this.path = PATH_ROUGH;
    } else if (productType === TYPE_JEWELRY) {
      this.path = PATH_JEWELRY;
    } else {
      this.path = PATH_DIAMOND;
    }
  }

  /**
   * @param {number} productId
   */
  fetchPDFReport(productId) {
    const qb = CutwiseAPIClient.createQueryBuilder().method(METHOD_GET).path(PATH_PDF_REPORT).asBlob();

    qb.addQueryStringParam('body', encodeURI(JSON.stringify({
      url: URLBuilder.buildRelativeUrl(`/print/diamond/${productId}?access_token=${token.accessToken}`),
      options: {
        format: 'A4',
        landscape: true,
      },
      waitFor: 2000,
    })));

    const query = qb.getQuery();

    return CutwiseAPIClient.sendQuery(query);
  }

  /**
   * Static Factory
   *
   * @param {string} productType
   * @param {?number} b2bContext
   * @return {ProductAPI}
   */
  static make(productType, b2bContext) {
    if (productType === TYPE_ROUGH || productType === TYPE_LGD_ROUGH) {
      return new ProductAPI(PATH_ROUGH, b2bContext);
    }

    if (productType === TYPE_JEWELRY) {
      return new ProductAPI(PATH_JEWELRY, b2bContext);
    }

    return new ProductAPI(PATH_DIAMOND, b2bContext);
  }
}
