import CutwiseAPIClient, {METHOD_GET} from '../network/CutwiseAPIClient';
import SaveToCache from '../network/middleware/SaveToCache';

export const PATH_CONSTANTS = 'api/v2/constants/web';

export default class ConstantsAPI {
  /**
   * @return {Promise<QueryResult>}
   */
  fetchAll() {
    const qb = CutwiseAPIClient.createQueryBuilder()
      .method(METHOD_GET)
      .path(PATH_CONSTANTS)
      .useMiddlewareOnResponse(SaveToCache());

    const query = qb.getQuery();

    return CutwiseAPIClient.sendQuery(query);
  }
}
