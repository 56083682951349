import Cache, { RESET_CACHE_ON_APP_VERSION_UPDATE } from '../Cache';
import config from '../../../../config';
import { HEADER_X_VERSION } from '../CutwiseAPIClient';

const cacheDefaultConfig = {
  resetStrategy: RESET_CACHE_ON_APP_VERSION_UPDATE,
};

/**
 * @param {Object} cacheConfig
 * @return {function}
 */
export default (cacheConfig = cacheDefaultConfig) => (query) => {
  const appVersion = query.result.networkResponse.headers.get(HEADER_X_VERSION);

  if (config.IS_DEV || appVersion === 'develop' || !query.result.ok || query.result.status !== 200) {
    return query;
  }

  Cache.saveEntry(query.getCacheId(), query.result.content, cacheConfig);

  return query;
};
