import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, memo, useEffect, useRef } from 'react';
import cls from 'classnames';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import ModalPortal from '../ModalPortal/ModalPortal';
import styles from './Modal.less';
export const CENTER = 'center';
export const TOP = 'top';
export const RIGHT = 'right';
export const BOTTOM = 'bottom';
export const LEFT = 'left';
const CLASS_POSITION_MAP = {
    [CENTER]: styles.modalCentered,
    [TOP]: styles.modalTopped,
    [RIGHT]: styles.modalRighted,
    [BOTTOM]: styles.modalBottomed,
    [LEFT]: styles.modalLefted,
};
const Modal = forwardRef((props, ref) => {
    const { onClose, isShowed = false, usePortal = true, title, style, position = TOP, renderModalFooter, stretch = false, wide = false, disableClose = false, children, contentClassName, } = props;
    const backdropElement = useRef(null);
    function onBackdropClick(e) {
        if (e.target !== backdropElement.current) {
            return;
        }
        if (disableClose) {
            return;
        }
        onClose?.(e);
    }
    function onCloseHandler(e) {
        if (disableClose) {
            return;
        }
        onClose?.(e);
    }
    function onPressEscKey(e) {
        if (e.code !== 'Escape') {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        if (disableClose) {
            return;
        }
        onClose?.(e);
    }
    function addListeners() {
        document.body.style.position = 'fixed';
        document.addEventListener('keydown', onPressEscKey, false);
    }
    function removeListeners() {
        document.body.style.position = 'initial';
        document.removeEventListener('keydown', onPressEscKey, false);
    }
    useEffect(() => {
        if (isShowed) {
            addListeners();
        }
        else {
            removeListeners();
        }
        return () => {
            removeListeners();
        };
    }, [isShowed, disableClose]);
    const modal = isShowed ? (_jsxs("div", { ref: ref, "aria-modal": "true", className: cls(styles.modal, CLASS_POSITION_MAP[position]), role: "dialog", style: style, children: [_jsx("div", { ref: backdropElement, className: styles.modalBackdrop, onClick: onBackdropClick }), _jsxs("div", { className: cls(styles.modalInner, { [styles.modalInnerStretched]: stretch, [styles.modalInnerWide]: wide }, contentClassName), children: [_jsx("div", { className: styles.modalHeader, children: title && _jsx("h2", { className: styles.modalTitle, children: title }) }), _jsx("div", { className: styles.modalControls, children: onClose && (_jsx(Button, { "aria-label": "Close Modal", color: "transparent", onClick: onCloseHandler, withIcon: true, children: _jsx(Icon, { name: "close" }) })) }), _jsx("div", { className: styles.modalContent, children: _jsx("div", { className: styles.modalContentWrapper, children: children }) }), renderModalFooter ? _jsx("div", { className: styles.modalFooter, children: renderModalFooter() }) : null] })] })) : null;
    return usePortal ? _jsx(ModalPortal, { children: modal }) : modal;
});
export default memo(Modal);
