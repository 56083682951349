/* eslint-disable */
/*
 * Analytic script included here: "src/index.html"
 * */
import { serializeDimensions } from '../helpers/utils';
window.ga =
    window.ga ||
        function () {
            (window.dataLayer = window.dataLayer || []).push(arguments);
        };
// todo move it out
const GA_TRACKING_ID = 'G-06MTQFQ27S';
export const CATALOG_EVENT_CATEGORY = 'Catalog';
export const COLLECTION_CATEGORY = 'Collection';
export const COMPARISON_EVENT_CATEGORY = 'Comparison';
export const DETAILS_EVENT_CATEGORY = 'Product Page';
export const DEFAULT_CATEGORY = '';
export const ADD_TO_COMPARE = 'add-to-compare';
export const ADD_TO_WISH = 'add-to-wish';
export const CONTACT_SEND_FORM_ACTION = 'send-seller-form';
export const CHANGE_VIEW_TYPE_COMBO = 'change-view-type-combo';
export const CHANGE_VIEW_TYPE_GRID = 'change-view-type-grid';
export const CHANGE_VIEW_TYPE_GRID_SMALL = 'change-view-type-small';
export const CHANGE_VIEW_TYPE_GRID_MEDIUM = 'change-view-type-medium';
export const CHANGE_VIEW_TYPE_GRID_LARGE = 'change-view-type-large';
export const CHANGE_VIEW_TYPE_TABLE = 'change-view-type-table';
export const CHANGE_VIEW_TYPE_PLOT = 'change-view-type-plot';
export const CHANGE_VIEW_TEMPLATE = 'change-view-template';
export const CHANGE_VIEW_SIZE_SMALL = 'change-view-size-small';
export const CHANGE_VIEW_SIZE_MEDIUM = 'change-view-size-medium';
export const CHANGE_VIEW_SIZE_LARGE = 'change-view-size-large';
export const CHANGE_SINGLE_SCALE = 'change-single-scale';
export const CHANGE_DESCRIPTION_VISIBILITY = 'change-description-visibility';
export const CHANGE_GRAYSCALE = 'change-grayscale';
export const CHANGE_SATURATION = 'change-saturation';
export const CHANGE_SORT = 'change-sort';
export const TOGGLE_FILTERS = 'toggle-filters';
export const CLICK_SHARE = 'click-share';
export const CLICK_COMPARE_WITH_REFERENCE = 'click-compare-with-reference';
export const OPEN_CERTIFICATE = 'open-certificate';
export const GO_TO_NEXT_PRODUCT = 'go-to-next-pro duct';
export const GO_TO_PREV_PRODUCT = 'go-to-prev-product';
export const OPEN_FULLSCREEN = 'open-fullscreen';
export const OPEN_STEREO = 'open-stereo';
export const CLICK_PLAY = 'click-play';
export const GO_TO_COMPARE = 'go-to-compare';
export const CONTACT_US = 'contact-us';
export const GO_TO_CATALOG_FROM_LANDING = 'go-to-catalog-from-landing';
export const GO_TO_PRESENTATION_FROM_LANDING = 'go-to-presentation-from-landing';
export const GO_TO_COLLECTIONS_FROM_FOOTER = 'go-to-collections-from-landing';
export const GO_TO_PRESENTATION_FROM_FOOTER = 'go-to-presentation-from-footer';
export const GO_TO_ABOUT_FROM_FOOTER = 'go-to-about-from-landing';
export const GO_TO_WORKSHOP = 'go-to-workshop';
export const GO_TO_PRICING_FROM_FOOTER = 'go-to-pricing-from-landing';
export const GO_TO_PRIVACY_FROM_FOOTER = 'go-to-privacy-from-landing';
export const PAGINATION_CLICK = 'pagination-click';
export const DETAIL_REPORT_ACTION = 'open-detail';
export const I3D_REPORT_ACTION = 'open-i3d';
export const I3D_COMPARATIVE_REPORT_ACTION = 'open-i3d-comparative';
export const DMC_REPORT_ACTION = 'open-dmc';
export const OPEN_QR_CODE = 'open-qr-code';
export const DOWNLOAD_HPC_FILE = 'download-hpc-file';
export const OPEN_PREV_SOLUTION = 'open-prev-solution';
export const OPEN_NEXT_SOLUTION = 'open-next-solution';
export const KEYBOARD_OPEN_PREV_SOLUTION = 'keyboard-open-prev-solution';
export const KEYBOARD_OPEN_NEXT_SOLUTION = 'keyboard-open-next-solution';
export const OPEN_SOLUTIONS_POPUP = 'open-solutions-popup';
export const CLOSE_SOLUTIONS_POPUP = 'close-solutions-popup';
export const SELECT_SOLUTION_IN_POPUP = 'select-solution-in-popup';
export const OPEN_PREV_DIAMOND = 'open-prev-diamond';
export const OPEN_NEXT_DIAMOND = 'open-next-diamond';
export const KEYBOARD_OPEN_PREV_DIAMOND = 'keyboard-open-prev-diamond';
export const KEYBOARD_OPEN_NEXT_DIAMOND = 'keyboard-open-next-diamond';
export const OPEN_DIAMONDS_POPUP = 'open-diamonds-popup';
export const CLOSE_DIAMONDS_POPUP = 'close-diamonds-popup';
export const SELECT_DIAMOND_IN_POPUP = 'select-diamond-in-popup';
export const CLOSE_PRESENTATION_WINDOW = 'close-presentation -window';
export const KEYBOARD_CLOSE_PRESENTATION_WINDOW = 'keyboard-close-presentation -window';
export const START_STEREO_PRESENTATION = 'start-stereo-presentation';
export const STOP_STEREO_PRESENTATION = 'stop-stereo-presentation';
export const SELECT_VIEW_MODE = 'select-view-mode';
export const ZOOM_TO_FIT = 'zoom-to-fit';
export const RESET_CAMERA = 'reset-camera';
export const SET_CAMERA_VIEW = 'set-camera-view';
export const TOGGLE_CARBON_VIEWER = 'toggle-carbon-viewer';
export const JOIN_CONFERENCE = 'join-conference';
export const LEAVE_CONFERENCE = 'leave-conference';
export const EXPLORE_YOUR_OWN_CONFERENCE = 'explore-your-own-conference';
export const FOLLOW_MASTER_CONFERENCE = 'follow-master-conference';
export const ENTER_FULLSCREEN = 'enter-fullscreen';
export const LEAVE_FULLSCREEN = 'leave-fullscreen';
const GA_CUTWISE_CLIENT = 'Cutwise Frontend';
class Analytics {
    context = DEFAULT_CATEGORY;
    constructor() {
        window.ga('js', new Date());
        window.ga('config', GA_TRACKING_ID);
    }
    getProductPageName(pathname) {
        return pathname.replace(/\/\d+/g, '');
    }
    sendEvent(category, action, dimensions = {}) {
        const params = {
            hitType: 'event',
            eventCategory: category,
        };
        this.send(action, params, dimensions);
    }
    sendPageview(params = {}, dimensions = {}) {
        if (!params.page) {
            params.page = window.location.pathname;
        }
        params.hitType = 'pageview';
        const { page, ...rest } = params;
        this.send(page, rest, dimensions);
    }
    send(event, params, dimensions = {}) {
        Object.assign(params, serializeDimensions(dimensions), { dimension3: GA_CUTWISE_CLIENT, send_to: GA_TRACKING_ID });
        window.ga('event', event, params);
    }
}
export default new Analytics();
