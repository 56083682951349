export class BillingTransaction {
    id;
    sku;
    product;
    project;
    createdAt;
    type;
    amount;
    description;
    user;
    _links;
    link;
    linkTitle;
    constructor(model) {
        const { id, sku, product, project, createdAt, type, amount, description, user, _links } = model;
        this.id = id;
        this.sku = sku;
        this.product = product;
        this.project = project;
        this.createdAt = createdAt;
        this.type = type;
        this.amount = amount;
        this.description = description;
        this.user = user;
        this._links = _links;
        /*
         * Generally we will have transactions for Products, but also we can have transactions for Projects,
         * in this case we need to determine what link to use
         *
         * Also the might be situation when both types of transactions exist,
         * in this case product link has priority
         * */
        if (Array.isArray(_links)) {
            let productLink;
            let projectLink;
            _links.forEach((link) => {
                if (link.rel === 'product.view') {
                    productLink = link;
                }
                if (link.rel === 'project.view') {
                    projectLink = link;
                }
            });
            if (productLink && product) {
                this.link = productLink.href;
                this.linkTitle = product;
            }
            else if (projectLink && project) {
                this.link = projectLink.href;
                this.linkTitle = project;
            }
        }
    }
}
