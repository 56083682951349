function download(blob, filename) {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
}
export async function downloadFromUrl(url, filename) {
    const response = await fetch(url);
    const blob = await response.blob();
    download(blob, filename ?? url.substring(url.lastIndexOf('/') + 1));
}
export function downloadTextContentAsFile(text, contentType, filename) {
    const blob = new Blob([text], { type: contentType });
    download(blob, filename);
}
