import React, {Component, lazy, Suspense} from 'react';
import PropTypes from 'prop-types';
import {observable, action} from 'mobx';
import {inject, observer} from 'mobx-react';
import {withRouter} from 'react-router';
import ComparisonStore from '@/comparison/stores/ComparisonStore';
import AnalyticsStore from '@/analytics/store/AnalyticsStore';
import {
  GO_TO_ABOUT_FROM_FOOTER,
  GO_TO_COLLECTIONS_FROM_FOOTER,
  GO_TO_COMPARE,
  GO_TO_PRESENTATION_FROM_FOOTER, GO_TO_PRICING_FROM_FOOTER, GO_TO_PRIVACY_FROM_FOOTER, GO_TO_WORKSHOP,
} from '@/analytics/services/Analytics';
import {HOME_PAGE} from '@/common/constants/pages';
import styles from './Header.less';
import AppStore from '../../stores/AppStore';
import CollectionsStore from '../../../collection/stores/CollectionsStore';
import NetBoxStore from '../../../netbox/stores/NetBoxStore';
import UserStore from '../../../user/stores/UserStore';
import Auth from '@/user/services/auth/Auth';
import URLProvider from '../../services/URLProvider';
import Link, {NAV, NONE} from '../Link/Link';
import Icon from '../Icon/Icon';
import Label, {ACCENT, SIZE_MEDIUM, TERTIARY} from '../Label/Label';
import CollectionPopover from '../../../collection/components/CollectionPopover/CollectionPopover';
import Button from '../Button/Button';
import Popover from '../Popover/Popover';
import AnimatedDropdown from '../AnimatedDropdown/AnimatedDropdown';
import MenuList from '../MenuList/MenuList.tsx';
import MenuItem from '../MenuList/MenuItem/MenuItem.tsx';
import Avatar from '../Avatar/Avatar';
import MenuItemSeparator from '../MenuList/MenuItemSeparator/MenuItemSeparator.tsx';
import {
  IMPORT_PRODUCTS,
  LGD, MANAGER, RENDER_MANAGER, securityManager,
} from '../../services/SecurityManager';
import {TYPE_JEWELRY} from '../../../product/constants/productTypes';
import ProductPlaceholder, {
  COLOR_OUTLINE, COLOR_OUTLINE_GREEN,
} from '../../../media/components/ProductPlaceholder/ProductPlaceholder';
import {SHAPE_PEAR, SHAPE_ROUND} from '../../../product/entity/Cutshape';
import Token from '@/user/services/token';
import Logo from '../Logo/Logo.tsx';
import NetBoxIndicator from '../../../netbox/components/NetBoxIndicator/NetBoxIndicator';
import {SCREEN_MD} from '../../constants/breakpoints';
import Separator from '../Separator/Separator';
import { BILLING_DASHBOARD_PAGE } from '../../../billing/constants/pages.ts';

const ProductFormImport = lazy(() => import('../../../product/components/ProductForm/ProductFormImport/ProductFormImport'));
const Drawer = lazy(() => import('../Drawer/Drawer'));

@inject('appStore', 'collectionsStore', 'comparisonStore', 'analyticsStore', 'netBoxStore', 'userStore')
@withRouter
@observer
export default class Header extends Component {
  static propTypes = {
    appStore: PropTypes.instanceOf(AppStore).isRequired,
    collectionsStore: PropTypes.instanceOf(CollectionsStore).isRequired,
    comparisonStore: PropTypes.instanceOf(ComparisonStore).isRequired,
    analyticsStore: PropTypes.instanceOf(AnalyticsStore).isRequired,
    netBoxStore: PropTypes.instanceOf(NetBoxStore).isRequired,
    userStore: PropTypes.instanceOf(UserStore).isRequired,
    fixed: PropTypes.bool,
  };

  static defaultProps = {
    fixed: true,
  };

  state = {
    hidden: false,
    isPopoverOpened: false,
    isImportModalOpened: false,
    isAboutPopoverOpened: false,
    isNaturalDiamondPopoverOpened: false,
    isLGDPopoverOpened: false,
  };

  @observable
  isMobileMenuOpened = false;

  @action
  openMobileMenu = (e) => {
    e.preventDefault();
    e.target.closest('button')
      .blur();

    if (this.isMobileMenuOpened === true) {
      return;
    }

    this.isMobileMenuOpened = true;
  };

  @action
  closeMobileMenu = () => {
    this.isMobileMenuOpened = false;
  };

  handleCollectionLinkClick = (e) => {
    e.stopPropagation();
  };

  openPopover = () => {
    this.setState({
      isPopoverOpened: true,
    });
  };

  closePopover = () => {
    this.setState({
      isPopoverOpened: false,
    });
  };

  closeNaturalDiamondPopover = () => {
    this.setState({
      isNaturalDiamondPopoverOpened: false,
    });
  };

  closeLGDPopover = () => {
    this.setState({
      isLGDPopoverOpened: false,
    });
  };

  closeAboutPopover = () => {
    this.setState({
      isAboutPopoverOpened: false,
    });
  };

  toggleAboutPopover = () => {
    this.setState(
      prevState => ({isAboutPopoverOpened: !prevState.isAboutPopoverOpened}),
    );
  };

  toggleNaturalDiamondPopover = () => {
    this.setState(
      prevState => ({isNaturalDiamondPopoverOpened: !prevState.isNaturalDiamondPopoverOpened}),
    );
  };

  toggleLGDPopover = () => {
    this.setState(
      prevState => ({isLGDPopoverOpened: !prevState.isLGDPopoverOpened}),
    );
  };

  closeImportModal = () => {
    this.setState({
      isImportModalOpened: false,
    });
  };

  toggleImportModal = () => {
    this.setState(
      prevState => ({isImportModalOpened: !prevState.isImportModalOpened}),
    );
  };

  handleCompareClick = () => {
    this.props.analyticsStore.sendEventWithContext(GO_TO_COMPARE);
  };

  handleCollectionsClick = () => {
    this.props.analyticsStore.sendEventWithContext(GO_TO_COLLECTIONS_FROM_FOOTER);
    this.closeMobileMenu();
    this.closeAboutPopover();
  };

  handlePresentationClick = () => {
    this.props.analyticsStore.sendEventWithContext(GO_TO_PRESENTATION_FROM_FOOTER);
    this.closeMobileMenu();
  };

  handleAboutClick = () => {
    this.props.analyticsStore.sendEventWithContext(GO_TO_ABOUT_FROM_FOOTER);
    this.closeMobileMenu();
  };

  handleWorkshopClick = () => {
    this.props.analyticsStore.sendEventWithContext(GO_TO_WORKSHOP);
    this.closeMobileMenu();
  };

  handlePricingClick = () => {
    this.props.analyticsStore.sendEventWithContext(GO_TO_PRICING_FROM_FOOTER);
    this.closeMobileMenu();
  };

  handlePrivacyPolicyClick = () => {
    this.props.analyticsStore.sendEventWithContext(GO_TO_PRIVACY_FROM_FOOTER);
    this.closeMobileMenu();
  };

  handlePinClick(e, c) {
    e.preventDefault();
    e.stopPropagation();

    this.props.collectionsStore.toggleActive(c);
  }

  handleLogout() {
    try {
      navigator.sendBeacon('/logout');
    } catch (e) {
      // do nothing
    }

    Auth.logout();
  }

  redirectToLoginPage(e) {
    e.preventDefault();
    Auth.redirectToLoginPage();
  }

  renderComparisonPageLink() {
    if (this.props.location.pathname === HOME_PAGE) {
      return null;
    }

    const comparisonPageURL = URLProvider.comparisonPage(this.props.comparisonStore.productIds, this.props.appStore.productTypeContext);
    const isComparisonPageAccessible = this.props.appStore.productTypeContext && comparisonPageURL;

    return (
      <Link
        className="header__link header__link--icon"
        key={1}
        to={isComparisonPageAccessible ? comparisonPageURL : ''}
        onClick={this.handleCompareClick}
        aria-disabled={isComparisonPageAccessible ? 'false' : 'true'}
        aria-label="Link to comparison page"
        appearance={NONE}
      >
        <Icon name="compare"/>
        {this.props.comparisonStore.productsCount > 0 && (
          <Label appearance={ACCENT} rounded size={SIZE_MEDIUM} aria-live="assertive" aria-label="Number of products in comparison">
            {this.props.comparisonStore.productsCount}
          </Label>
        )}
      </Link>
    );
  }

  renderWishLink() {
    const popoverProps = {
      isOpen: this.state.isPopoverOpened,
      preferPlace: 'below',
      onOuterAction: this.closePopover,
      body: <CollectionPopover withLinks/>,
      tipSize: 0,
      className: 'Popover--transparent',
    };

    const btn = (
      <span className="header__link header__link--icon header__link--collections" onClick={this.openPopover} aria-label="Collection modal toggler">
        <Icon name="like"/>
        {this.props.collectionsStore.activeCollection && this.props.collectionsStore.activeCollection.products && this.props.collectionsStore.activeCollection.products.length > 0 && (
          <Label appearance={TERTIARY} rounded size={SIZE_MEDIUM} aria-live="assertive" aria-label="Number of products in pinned collection">
            {this.props.collectionsStore.activeCollection.products.length}
          </Label>
        )}
      </span>
    );

    return (
      <>
        {this.props.collectionsStore.activeCollection && (
          <div className="pinned-collection" aria-live="assertive">
            <span className="pinned-collection__label">Pinned Collection:</span>
            <Link
              className="pinned-collection__link"
              onClick={this.handleCollectionLinkClick}
              to={URLProvider.collectionDetailPage(this.props.collectionsStore.activeCollection)}
              appearance={NONE}
            >
              {this.props.collectionsStore.activeCollection.title}
            </Link>
            <Button size="xs" active onClick={e => this.handlePinClick(e, this.props.collectionsStore.activeCollection)} withIcon>
              <Icon name="pin-on"/>
            </Button>
          </div>
        )}
        <Popover {...popoverProps}>{btn}</Popover>
      </>
    );
  }

  renderUserLinks() {
    if (this.props.userStore.isAuthorized) {
      const user = this.props.userStore.user;

      return (
        <>
          {this.renderWishLink()}
          {this.renderComparisonPageLink()}

          <AnimatedDropdown className="header__link header__link--desktop" label={<Avatar user={this.props.userStore.user} withUsername/>}>
            <MenuList>
              <MenuItem>
                <Link exact activeClassName="active" to={URLProvider.profile(this.props.userStore.user)} appearance={NONE}>
                  <Icon name="user-profile"/> My Profile
                </Link>
              </MenuItem>

              {!this.props.userStore.user.flags.disableBilling && securityManager.isGranted(MANAGER) && (
                <MenuItem>
                  <Link exact activeClassName="active" to={BILLING_DASHBOARD_PAGE} appearance={NONE}>
                    {user && user.b2b && user.b2b.currency === 'USD' && <Icon name="user-billing-usd"/>}
                    {user && user.b2b && user.b2b.currency === 'EUR' && <Icon name="user-billing-eur"/>}

                    <span> Billing Dashboard</span>
                  </Link>
                </MenuItem>
              )}
              <MenuItemSeparator>Projects:</MenuItemSeparator>
              {securityManager.isGranted(RENDER_MANAGER) && (
                <MenuItem>
                  <Link exact activeClassName="active" to="/projects" appearance={NONE}>
                    <Icon name="project"/> Owned by Me
                  </Link>
                </MenuItem>
              )}
              <MenuItem>
                <Link exact activeClassName="active" to="/shared-with-me-projects" appearance={NONE}>
                  <Icon name="project-shared"/> Shared with Me
                </Link>
              </MenuItem>
              {securityManager.isGranted(MANAGER) && (
                <>
                  {!securityManager.isGranted(LGD) && (
                    <>
                      <MenuItemSeparator>My Natural Diamonds:</MenuItemSeparator>
                      <MenuItem>
                        <Link exact activeClassName="active" to="/catalog/diamond-colorless/edit" appearance={NONE}>
                          <Icon name="diamond"/>
                          Colorless
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link exact activeClassName="active" to="/catalog/diamond-fancy/edit" appearance={NONE}>
                          <Icon name="diamond" className={styles.iconFancy}/>
                          Fancy Color
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link exact activeClassName="active" to="/catalog/rough/edit" appearance={NONE}>
                          <Icon name="rough"/>
                          Rough
                        </Link>
                      </MenuItem>
                      <MenuItemSeparator>Other:</MenuItemSeparator>
                      <MenuItem>
                        <Link exact activeClassName="active" to="/catalog/jewelry/edit" appearance={NONE}>
                          <Icon name="jewelry"/>
                          Jewelry
                        </Link>
                      </MenuItem>
                      <MenuItemSeparator/>
                    </>
                  )}
                  {securityManager.isGranted(LGD) && (
                    <>
                      <MenuItemSeparator>Lab-grown diamonds:</MenuItemSeparator>
                      <MenuItem>
                        <Link exact activeClassName="active" to="/catalog/lgd-colorless/edit" appearance={NONE}>
                          <Icon name="diamond"/>
                          Colorless
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link exact activeClassName="active" to="/catalog/lgd-fancy/edit" appearance={NONE}>
                          <Icon name="diamond" className={styles.iconFancy}/>
                          Fancy Color
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link exact activeClassName="active" to="/catalog/lgd-rough/edit" appearance={NONE}>
                          <Icon name="rough"/>
                          Rough
                        </Link>
                      </MenuItem>
                      <MenuItemSeparator/>
                    </>
                  )}

                  {securityManager.isGranted(IMPORT_PRODUCTS) && (
                    <>
                      <MenuItem disabled={!securityManager.isGranted(IMPORT_PRODUCTS)} onClick={this.toggleImportModal}>
                        <Icon name="user-import"/> Import Products
                      </MenuItem>

                      <MenuItemSeparator/>
                    </>
                  )}
                </>
              )}

              <MenuItem onClick={this.handleLogout}>
                <Icon name="user-logout"/>
                Log out
              </MenuItem>
            </MenuList>
          </AnimatedDropdown>
        </>
      );
    }

    return (
      <>
        {this.renderComparisonPageLink()}
        <Link className="header__link header__link--desktop" href="#" onClick={this.redirectToLoginPage} appearance={NAV}>
          Log in
        </Link>
      </>
    );
  }

  renderMobileMenu() {
    return (
      <Drawer isOpened={this.isMobileMenuOpened} onClose={this.closeMobileMenu}>
        <div className="header__mobile-item-list">
          <div className="header__mobile-item">
            <Link exact onClick={this.closeMobileMenu} activeClassName="active" to="/catalog/diamond-colorless" className="header-link" appearance={NONE}>
              Natural Colorless Diamonds
            </Link>
          </div>
          <div className="header__mobile-item">
            <Link exact onClick={this.closeMobileMenu} activeClassName="active" to="/catalog/diamond-fancy" className="header-link" appearance={NONE}>
              Natural Fancy Color Diamonds
            </Link>
          </div>
          <div className="header__mobile-item">
            <Link exact onClick={this.closeMobileMenu} activeClassName="active" to="/catalog/rough" className="header-link" appearance={NONE}>
              Natural Rough
            </Link>
          </div>
          <div className="header__mobile-item">
            <Link exact onClick={this.closeMobileMenu} activeClassName="active" to="/catalog/lgd-colorless" className="header-link" appearance={NONE}>
              Lab-Grown Colorless Diamonds
            </Link>
          </div>
          <div className="header__mobile-item">
            <Link exact onClick={this.closeMobileMenu} activeClassName="active" to="/catalog/lgd-fancy" className="header-link" appearance={NONE}>
              Lab-Grown Fancy Color Diamonds
            </Link>
          </div>
          <div className="header__mobile-item">
            <Link exact onClick={this.closeMobileMenu} activeClassName="active" to="/catalog/lgd-rough" className="header-link" appearance={NONE}>
              Lab-Grown Rough
            </Link>
          </div>
          <div className="header__mobile-item">
            <Link exact onClick={this.closeMobileMenu} activeClassName="active" to="/catalog/jewelry" className="header-link" appearance={NONE}>
              Jewelry
            </Link>
          </div>
        </div>
        <Separator/>
        <div className="header__mobile-item-list">
          <div className="header__mobile-item">
            <Link onClick={this.handleCollectionsClick} activeClassName="active" className="header-link" to="/collections" appearance={NONE}>
              Demo Collections
            </Link>
          </div>
          <div className="header__mobile-item">
            <Link className="header-link" onClick={this.handlePresentationClick} href="/pr/#/" appearance={NONE} external>
              Technology Overview
            </Link>
          </div>
          <div className="header__mobile-item">
            <Link className="header-link" href="https://octonus-teams.com/wiki/display/CUDO/Release+Notes" appearance={NONE} external>
              What&apos;s new
            </Link>
          </div>
          <div className="header__mobile-item">
            <Link className="header-link" href="https://octonus-teams.com/wiki/display/CUDO/Cutwise+Widget" appearance={NONE} external>
              Embed Cutwise Widget
            </Link>
          </div>
          <div className="header__mobile-item">
            <Link className="header-link" href="https://api-doc.cutwise.com/" appearance={NONE} external>
              API Documentation
            </Link>
          </div>
          <div className="header__mobile-item">
            <Link className="header-link" href="https://www.youtube.com/channel/UCgIrQJ25wIuLfCPbFcZ4S6Q" appearance={NONE} external>
              YouTube Channel
            </Link>
          </div>
          <div className="header__mobile-item">
            <Link className="header-link" onClick={this.handleWorkshopClick} href="https://docs.cutwise.com/" appearance={NONE} external>
              Performance Workshop
            </Link>
          </div>
          <div className="header__mobile-item">
            <Link className="header-link" onClick={this.handlePricingClick} href="https://pricing.cutwise.com/" appearance={NONE} external>
              Pricing
            </Link>
          </div>
          <div className="header__mobile-item">
            <Link className="header-link" onClick={this.handlePrivacyPolicyClick} href="/privacy" appearance={NONE} external>
              Privacy Policy
            </Link>
          </div>
          <div className="header__mobile-item">
            <Link className="header-link" href="/about" appearance={NONE} external>
              Contacts
            </Link>
          </div>
        </div>
        <div className="header__mobile-item-separator"/>
        <div className="header__mobile-item-list">
          {this.props.userStore.isAuthorized ? (
            <>
              <div className="header__mobile-item">
                <Link className="header-link" to={URLProvider.profile(this.props.userStore.user)} appearance={NONE}>
                  My Profile
                </Link>
              </div>
              {!this.props.userStore.user.flags.disableBilling && securityManager.isGranted(MANAGER) && (
                <div className="header__mobile-item">
                  <Link className="header-link" to={BILLING_DASHBOARD_PAGE} appearance={NONE}>
                    Billing Dashboard
                  </Link>
                </div>
              )}
              <div className="header__mobile-item">
                <Link className="header-link" href="#" onClick={this.handleLogout} appearance={NONE}>
                  Log out
                </Link>
              </div>
            </>
          ) : (
            <div className="header__mobile-item">
              <Link className="header-link" href="#" onClick={this.redirectToLoginPage} appearance={NONE}>
                Log in
              </Link>
            </div>
          )}
        </div>
      </Drawer>
    );
  }

  render() {
    const isDemoCollectionsPageActive = this.props.location.pathname === '/collections';
    const isLGDContext = this.props.appStore.isLGDContext && !isDemoCollectionsPageActive;
    const isNaturalContext = this.props.appStore.isNaturalContext && !isDemoCollectionsPageActive;
    const isJewelryContext = this.props.appStore.productTypeContext === TYPE_JEWELRY && !isDemoCollectionsPageActive;

    const popoverAboutProps = {
      isOpen: this.state.isAboutPopoverOpened,
      preferPlace: 'below',
      onOuterAction: this.closeAboutPopover,
      body: (
        <MenuList withRoundedCorners>
          <MenuItem className="show-on-small-screens">
            <Link exact activeClassName="active" onClick={this.handleCollectionsClick} to="/collections" appearance={NONE}>
              Demo Collections
            </Link>
          </MenuItem>
          <MenuItem>
            <Link onClick={this.handlePresentationClick} href="/pr/#/" appearance={NONE} external>
              Technology Overview
            </Link>
          </MenuItem>
          <MenuItem>
            <Link href="https://octonus-teams.com/wiki/display/CUDO/Release+Notes" appearance={NONE} external>
              What&apos;s new
            </Link>
          </MenuItem>
          <MenuItem>
            <Link href="https://octonus-teams.com/wiki/display/CUDO/Cutwise+Widget" appearance={NONE} external>
              Embed Cutwise Widget
            </Link>
          </MenuItem>
          <MenuItem>
            <Link href="https://api-doc.cutwise.com/" appearance={NONE} external>
              API Documentation
            </Link>
          </MenuItem>
          <MenuItem>
            <Link href="https://www.youtube.com/channel/UCgIrQJ25wIuLfCPbFcZ4S6Q" appearance={NONE} external>
              YouTube Channel
            </Link>
          </MenuItem>
          <MenuItem>
            <Link onClick={this.handleWorkshopClick} href="https://docs.cutwise.com/" appearance={NONE} external>
              Performance Workshop
            </Link>
          </MenuItem>
          <MenuItem>
            <Link onClick={this.handlePricingClick} href="https://pricing.cutwise.com/" appearance={NONE} external>
              Pricing
            </Link>
          </MenuItem>
          <MenuItem>
            <Link onClick={this.handlePrivacyPolicyClick} href="/privacy" appearance={NONE} external>
              Privacy Policy
            </Link>
          </MenuItem>
          <MenuItem>
            <Link href="/about" appearance={NONE} external>
              Contacts
            </Link>
          </MenuItem>
        </MenuList>
      ),
      className: 'Popover--transparent',
    };

    const popoverNaturalDiamondProps = {
      isOpen: this.state.isNaturalDiamondPopoverOpened,
      preferPlace: 'below',
      onOuterAction: this.closeNaturalDiamondPopover,
      body: (
        <MenuList withRoundedCorners>
          <MenuItem className={styles.headerProductTypeMenuWrapper}>
            <Link
              exact
              className={styles.headerProductTypeMenuItem}
              activeClassName="active"
              to="/catalog/diamond-colorless"
              role="menuitem"
              onClick={this.closeNaturalDiamondPopover}
              appearance={NONE}
            >
              <div className={styles.headerProductTypeIcon}>
                <ProductPlaceholder bold color={COLOR_OUTLINE} shape={SHAPE_ROUND}/>
              </div>
              Polished Colorless
            </Link>
          </MenuItem>
          <MenuItem className={styles.headerProductTypeMenuWrapper}>
            <Link exact className={styles.headerProductTypeMenuItem} activeClassName="active" to="/catalog/diamond-fancy" role="menuitem" onClick={this.closeNaturalDiamondPopover} appearance={NONE}>
              <div className={styles.headerProductTypeIcon}>
                <ProductPlaceholder bold color={COLOR_OUTLINE_GREEN} shape={SHAPE_PEAR}/>
              </div>
              Polished Fancy Color
            </Link>
          </MenuItem>
          <MenuItem className={styles.headerProductTypeMenuWrapper}>
            <Link exact className={styles.headerProductTypeMenuItem} activeClassName="active" to="/catalog/rough" role="menuitem" onClick={this.closeNaturalDiamondPopover} appearance={NONE}>
              <div className={styles.headerProductTypeIcon}>
                <ProductPlaceholder bold color={COLOR_OUTLINE} shape="rough"/>
              </div>
              Rough
            </Link>
          </MenuItem>
        </MenuList>
      ),
      className: 'Popover--transparent',
    };

    const popoverLGDProps = {
      isOpen: this.state.isLGDPopoverOpened,
      preferPlace: 'below',
      onOuterAction: this.closeLGDPopover,
      body: (
        <MenuList withRoundedCorners>
          <MenuItem className={styles.headerProductTypeMenuWrapper}>
            <Link exact className={styles.headerProductTypeMenuItem} activeClassName="active" to="/catalog/lgd-colorless" role="menuitem" onClick={this.closeLGDPopover} appearance={NONE}>
              <div className={styles.headerProductTypeIcon}>
                <ProductPlaceholder bold color={COLOR_OUTLINE} shape={SHAPE_ROUND}/>
              </div>
              Polished Colorless
            </Link>
          </MenuItem>
          <MenuItem className={styles.headerProductTypeMenuWrapper}>
            <Link exact className={styles.headerProductTypeMenuItem} activeClassName="active" to="/catalog/lgd-fancy" role="menuitem" onClick={this.closeLGDPopover} appearance={NONE}>
              <div className={styles.headerProductTypeIcon}>
                <ProductPlaceholder bold color={COLOR_OUTLINE_GREEN} shape={SHAPE_PEAR}/>
              </div>
              Polished Fancy Color
            </Link>
          </MenuItem>
          <MenuItem className={styles.headerProductTypeMenuWrapper}>
            <Link exact className={styles.headerProductTypeMenuItem} activeClassName="active" to="/catalog/lgd-rough" role="menuitem" onClick={this.closeLGDPopover} appearance={NONE}>
              <div className={styles.headerProductTypeIcon}>
                <ProductPlaceholder bold color={COLOR_OUTLINE} shape="rough"/>
              </div>
              Rough
            </Link>
          </MenuItem>
        </MenuList>
      ),
      className: 'Popover--transparent',
    };

    return (
      <header className={`${styles.header} ${this.props.fixed ? styles.headerFixed : ''} ${this.state.hidden ? 'header--hidden' : 'header--displayed'}`}>
        <div className="header__hm">
          <Button color="transparent" onClick={this.openMobileMenu} withIcon aria-label="Header Menu Sections Control for Mobile Devices">
            <Icon name="hamburger"/>
          </Button>
        </div>

        <div className="header__left">
          <div className="header__logo">
            <Link to={Token.isAuthorized ? '/catalog/diamond-colorless' : '/'} appearance={NONE}>
              <Logo/>
            </Link>
          </div>

          <div className="header__cache-proxy">
            <NetBoxIndicator/>
          </div>
        </div>

        {this.props.appStore.screenWidth < SCREEN_MD && (
          this.renderMobileMenu()
        )}

        <div className="header__chapters" role="menu" aria-label="Header Menu Sections">
          <Popover {...popoverNaturalDiamondProps}>
            <div className={`header-link header-link--desktop header-link--dropdown ${isNaturalContext ? styles.headerLinkActive : ''}`} onClick={this.toggleNaturalDiamondPopover}>
              Natural Diamonds <Icon name="arrow-lt-down" className={`header-link__triangle ${this.state.isNaturalDiamondPopoverOpened ? 'header-link__triangle--opened' : ''}`}/>
            </div>
          </Popover>
          <Popover {...popoverLGDProps}>
            <div className={`header-link header-link--desktop header-link--dropdown ${styles.headerLinkLGD} ${isLGDContext ? styles.headerLinkActive : ''}`} onClick={this.toggleLGDPopover}>
              Lab-Grown Diamonds <Icon name="arrow-lt-down" className={`header-link__triangle ${this.state.isLGDPopoverOpened ? 'header-link__triangle--opened' : ''}`}/>
            </div>
          </Popover>
          <Link to="/catalog/jewelry" className={`header-link header-link--desktop ${isJewelryContext ? styles.headerLinkActive : ''}`} role="menuitem" appearance={NONE}>
            Jewelry
          </Link>
          <Link
            className={`header-link header-link--desktop ${isDemoCollectionsPageActive ? styles.headerLinkActive : ''} hide-on-small-screens`}
            onClick={this.handleCollectionsClick}
            to="/collections"
            role="menuitem"
            appearance={NONE}
          >
            Demo Collections
          </Link>
          <Popover {...popoverAboutProps}>
            <div className="header-link header-link--desktop header-link--dropdown" onClick={this.toggleAboutPopover}>
              About <Icon name="arrow-lt-down" className={`header-link__triangle ${this.state.isAboutPopoverOpened ? 'header-link__triangle--opened' : ''}`}/>
            </div>
          </Popover>
        </div>

        <div className="header__links">{this.renderUserLinks()}</div>

        <Suspense fallback={null}>
          <ProductFormImport
            onSubmit={() => {
            }}
            onClose={this.closeImportModal}
            isOpened={this.state.isImportModalOpened}
          />
        </Suspense>
      </header>
    );
  }
}
