import {observable, computed, action} from 'mobx';
import isEqual from 'lodash/isEqual';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import {MEDIA_SIZE_SMALL, MEDIA_SIZE_XSMALL} from '../../media/constants/sizeCodes';
import {FIELD_FILTER_AT_LEAST_ONE} from '../../catalog/constants/fieldFilters';
import {VIEW_LIST, VIEW_VISUAL} from '../../comparison/constants/views';
import {METRIC_DATA_DECORATOR_ROUND} from '../../metric/constants/dataDecorators';
import ViewTemplate from '../../collection/entity/ViewTemplate';
import {DEFAULT_VIEW_TEMPLATE_ID_COLORLESS} from '../../collection/constants/defaultViewTemplates';
import {viewTemplateRepository} from '../../collection/repository/ViewTemplateRepository';
import Sort from '../../common/state/Sort';
import {setupPresetRepository} from '../../media/repository/SetupPresetRepository';
import {INTERNAL, securityManager} from '../../common/services/SecurityManager';
import {CARD_FIELDS_SORT, ONLY_INTERNAL_USER_FIELDS} from '../../catalog/constants/fieldGroups';
import {FIELD_PRICE, FIELD_SHORT_VIEW, FIELD_SKU} from '../../catalog/constants/fieldsMap';
import {MEDIA_SIZE_MAP} from '../../media/constants/sizeCodeToPx';
import {
  FIELD_PRESET_TYPE_FULL,
  FIELD_PRESET_TYPE_MEDIA_ONLY,
  FIELD_PRESET_TYPE_MOBILE_COMPARISON,
  FIELD_PRESET_TYPE_MOBILE_REFERENCE_COMPARISON,
  FIELD_PRESET_TYPE_REFERENCE_COMPARISON,
  FIELD_PRESET_TYPE_SOLUTIONS,
  FIELD_PRESET_TYPE_SOLUTIONS_MEDIA_ONLY,
} from '../../catalog/constants/fieldPresetTypes';
import diff from '../../common/helpers/diff';

export const VIEW_TYPE_GRID = 'grid';
export const VIEW_TYPE_TABLE = 'table';
export const VIEW_TYPE_COMBO = 'combo';
export const VIEW_TYPE_PLOT = 'plot';

/**
 * @typedef {Object} ViewSettingsPlainObject
 * @property {=string} mediaSize
 * @property {=boolean} isHiddenDesc
 * @property {=boolean} isSingleScale
 * @property {=boolean} isGrayscale
 * @property {=boolean} isSaturation
 * @property {=Sort} sort
 * @property {=SetupPreset|number} setupPreset
 * @property {=[string]} cardFields
 * @property {=string} type
 * @property {=number} fps
 * @property {=ViewTemplate|number} ViewSettingsPlainObject.viewTemplate
 * @property {=boolean} isAutoplayEnabled
 * @property {=string} metricDataDecorator
 */

export default class ProductViewSettings {
  /**
   * @type {?ViewTemplate}
   */
  @observable
  viewTemplate = null;

  /**
   * @type {?boolean}
   */
  @observable
  isHiddenDesc = null;

  /**
   * @type {?boolean}
   */
  @observable
  isSingleScale = null;

  /**
   * @type {?boolean}
   */
  @observable
  isGrayscale = null;

  /**
   * @type {?boolean}
   */
  @observable
  isSaturation = null;

  /**
   * @type {[string]}
   */
  @observable
  cardFields = null;

  /**
   * @type {?SetupPreset}
   */
  @observable
  setupPreset = null;

  /**
   * @type {string}
   */
  @observable
  type = VIEW_TYPE_GRID;

  /**
   * @type {?string}
   */
  @observable
  mediaSize = MEDIA_SIZE_SMALL;

  /**
   * @type {?number}
   */
  @observable
  fps = null;

  @observable
  forcedSettings;

  /**
   * @type {Sort}
   */
  @observable
  sort = null;

  @observable
  combo = {
    selectedView: VIEW_LIST,
    isMediaModeEnabled: false,
    isExtendedFieldSet: false,
    isReferenceComparison: false,
    fieldFilter: FIELD_FILTER_AT_LEAST_ONE,
    isMobileMode: false,
    fullscreenSetupPresetGroup: null,
    isSolutionsListMode: false,
  };

  @observable
  isAutoplayEnabled = false;

  @observable
  metricDataDecorator = METRIC_DATA_DECORATOR_ROUND;

  /**
   * @param {?ViewSettingsPlainObject} modelData
   * @param {?ViewSettingsPlainObject} forcedSettings
   * @constructor
   */
  constructor(modelData = {}, forcedSettings = {}) {
    this.forcedSettings = forcedSettings;

    if (modelData.viewTemplate instanceof ViewTemplate) {
      this.viewTemplate = modelData.viewTemplate;
    } else {
      const viewTemplateId = modelData.viewTemplate ? Number(modelData.viewTemplate) : DEFAULT_VIEW_TEMPLATE_ID_COLORLESS; // todo pass default setting preset
      this.viewTemplate = viewTemplateRepository.findViewTemplateById(viewTemplateId);
    }

    this.fps = modelData.fps;

    if (!isNil(modelData.isHiddenDesc)) {
      this.isHiddenDesc = modelData.isHiddenDesc;
    }
    if (!isNil(modelData.isSingleScale)) {
      this.isSingleScale = modelData.isSingleScale;
    }
    if (!isNil(modelData.isGrayscale)) {
      this.isGrayscale = modelData.isGrayscale;
    }
    if (!isNil(modelData.isSaturation)) {
      this.isSaturation = modelData.isSaturation;
    }
    if (!isNil(modelData.sort)) {
      if (modelData.sort instanceof Sort) {
        this.sort = modelData.sort;
      } else {
        this.sort = Sort.fromJSON(modelData.sort);
      }
    }
    if (!isNil(modelData.cardFields)) {
      this.cardFields = modelData.cardFields;
    }
    if (!isNil(modelData.setupPreset)) {
      this.setupPreset = modelData.setupPreset;
    }
    if (isString(this.setupPreset) || isNumber(this.setupPreset)) {
      this.setupPreset = setupPresetRepository.findSetupPresetById(Number(this.setupPreset));
    }

    if (!isNil(modelData.mediaSize)) {
      this.mediaSize = modelData.mediaSize;
    }

    if (!isNil(modelData.type)) {
      this.type = modelData.type;
    }

    if (!isNil(modelData.isAutoplayEnabled)) {
      this.isAutoplayEnabled = modelData.isAutoplayEnabled;
    }

    if (this.type === VIEW_TYPE_TABLE) {
      this.mediaSize = null;
    }
  }

  /**
   * @return {[SetupPreset]}
   */
  @computed
  get resultedSetupPresets() {
    if (!isNil(this.forcedSettings.setupPreset)) {
      return [this.forcedSettings.setupPreset];
    }

    if (this.setupPreset) {
      return [this.setupPreset];
    }

    if (this.viewTemplate) {
      if (this.viewTemplate.setupPresetGroup) {
        return this.viewTemplate.setupPresetGroup.sp;
      }
      if (this.viewTemplate.setupPreset) {
        return [this.viewTemplate.setupPreset];
      }
    }

    return null;
  }

  /**
   * @return {?Sort}
   */
  @computed
  get resultedSort() {
    if (!isNil(this.forcedSettings.sort)) {
      return this.forcedSettings.sort;
    }

    if (this.sort) {
      return this.sort;
    }

    if (this.viewTemplate && this.viewTemplate.sort) {
      return this.viewTemplate.sort;
    }

    return null;
  }

  /**
   * @return {?[string]}
   */
  @computed
  get resultedCardFields() {
    if (!isNil(this.forcedSettings.cardFields)) {
      return this.forcedSettings.cardFields;
    }

    if (this.cardFields) {
      return this.cardFields;
    }

    if (this.viewTemplate && this.viewTemplate.cardFields) {
      return this.viewTemplate.cardFields;
    }

    return null;
  }

  @computed
  get displayedFieldObjects() {
    if (!this.resultedCardFields || this.resultedCardFields.length === 0) {
      return null;
    }

    return CARD_FIELDS_SORT
      .filter(f => securityManager.isGranted(INTERNAL) || !ONLY_INTERNAL_USER_FIELDS.includes(f))
      .map(f => (this.resultedCardFields.includes(f.key) ? f : null)).filter(f => f !== null);
  }

  @computed
  get resultedIsSingleScale() {
    if (!isNil(this.forcedSettings.isSingleScale)) {
      return this.forcedSettings.isSingleScale;
    }

    if (!isNil(this.isSingleScale)) {
      return this.isSingleScale;
    }

    if (this.viewTemplate) {
      return this.viewTemplate.isSingleScale;
    }

    return false;
  }

  @computed
  get resultedIsGrayscale() {
    if (!isNil(this.forcedSettings.isGrayscale)) {
      return this.forcedSettings.isGrayscale;
    }

    if (!isNil(this.isGrayscale)) {
      return this.isGrayscale;
    }

    if (this.viewTemplate) {
      return this.viewTemplate.isGrayscale;
    }

    return false;
  }

  @computed
  get resultedIsSaturation() {
    if (!isNil(this.forcedSettings.isSaturation)) {
      return this.forcedSettings.isSaturation;
    }

    if (!isNil(this.isSaturation)) {
      return this.isSaturation;
    }

    if (this.viewTemplate) {
      return this.viewTemplate.isSaturation;
    }

    return false;
  }

  @computed
  get resultedViewTemplate() {
    if (!this.viewTemplate) {
      return null;
    }

    if (this.setupPreset && this.setupPreset !== this.viewTemplate.setupPreset && isNil(this.forcedSettings.setupPreset)) {
      return null;
    }

    if ((!this.resultedCardFields || !isEqual(this.resultedCardFields.slice().sort(), this.viewTemplate.cardFields.slice().sort())) && isNil(this.forcedSettings.cardFields)) {
      return null;
    }

    if (this.resultedIsGrayscale !== this.viewTemplate.isGrayscale && isNil(this.forcedSettings.isGrayscale)) {
      return null;
    }

    if (this.resultedIsSingleScale !== this.viewTemplate.isSingleScale && isNil(this.forcedSettings.isSingleScale)) {
      return null;
    }

    // todo remove sort from view template
    if (!this.resultedSort || (!this.viewTemplate.sort.isEqual(this.resultedSort) && isNil(this.forcedSettings.sort))) {
      return null;
    }

    return this.viewTemplate;
  }

  @computed
  get hasSKUField() {
    if (!this.resultedCardFields || this.resultedCardFields.length === 0) {
      return false;
    }

    return this.resultedCardFields.includes(FIELD_SKU.key);
  }

  @computed
  get hasPriceField() {
    if (!this.resultedCardFields || this.resultedCardFields.length === 0) {
      return false;
    }

    return this.resultedCardFields.includes(FIELD_PRICE.key);
  }

  @computed
  get hasShortViewField() {
    if (!this.resultedCardFields || this.resultedCardFields.length === 0) {
      return false;
    }

    return this.resultedCardFields.includes(FIELD_SHORT_VIEW.key);
  }

  /**
   * @return {number}
   */
  @computed
  get mediaSizeInPX() {
    return MEDIA_SIZE_MAP[this.mediaSize];
  }

  /**
   * @return {string}
   */
  @computed
  get comboFieldPreset() {
    if (this.combo.isSolutionsListMode && this.combo.isMediaModeEnabled) {
      return FIELD_PRESET_TYPE_SOLUTIONS_MEDIA_ONLY;
    }

    if (this.combo.isSolutionsListMode) {
      return FIELD_PRESET_TYPE_SOLUTIONS;
    }

    if (this.combo.isMediaModeEnabled || this.combo.selectedView === VIEW_VISUAL) {
      return FIELD_PRESET_TYPE_MEDIA_ONLY;
    }

    if (this.combo.isReferenceComparison === false && this.combo.isMobileMode) {
      return FIELD_PRESET_TYPE_MOBILE_COMPARISON;
    }

    if (this.combo.isReferenceComparison && this.combo.isMobileMode) {
      return FIELD_PRESET_TYPE_MOBILE_REFERENCE_COMPARISON;
    }

    if (this.combo.isReferenceComparison) {
      return FIELD_PRESET_TYPE_REFERENCE_COMPARISON;
    }

    return FIELD_PRESET_TYPE_FULL;
  }

  /**
   * @param {string} metricDataDecorator
   */
  @action
  setMetricDataDecorator(metricDataDecorator) {
    this.metricDataDecorator = metricDataDecorator;
  }

  /**
   * @param {ViewTemplate} viewTemplate
   */
  applyViewTemplate(viewTemplate) {
    this.viewTemplate = viewTemplate;
    this.setupPreset = null;
    this.isGrayscale = null;
    this.isSaturation = null;
    this.isSingleScale = null;
    this.isSingleScale = null;
    this.cardFields = null;
    this.sort = null;
  }

  /**
   * @param {Sort} sort
   */
  applySort(sort) {
    this.sort = sort;
  }

  /**
   * @param {Product} product
   */
  getSetupPresetForProduct(product) {
    if (product.mediaCollection) {
      if (this.resultedSetupPresets) {
        return product.mediaCollection.getSuitableSPFromSPList(this.resultedSetupPresets);
      }

      return product.defaultMedia ? product.defaultMedia.setupPreset : null;
    }

    return null;
  }

  /**
   * @return {ViewSettingsPlainObject}
   */
  serialize() {
    const json = this.sanitizeValues();
    if (json.sort) {
      json.sort = json.sort.transform();
    }

    return json;
  }

  /**
   * @return {Object}
   */
  sanitizeValues() {
    const customSP = this.setupPreset ? this.setupPreset.id : null;
    const customViewTemplate = this.viewTemplate ? this.viewTemplate.id : null;

    const sanitizedStruct = {
      setupPreset: customSP,
      cardFields: this.resultedCardFields ? [...this.resultedCardFields].sort() : null,
      isSingleScale: this.resultedIsSingleScale,
      isGrayscale: this.resultedIsGrayscale,
      isSaturation: this.resultedIsSaturation,
      isHiddenDesc: this.isHiddenDesc,
      sort: this.resultedSort,
      mediaSize: this.mediaSize,
      type: this.type,
      viewTemplate: customViewTemplate,
      isAutoplayEnabled: this.isAutoplayEnabled,
    };

    if (this.viewTemplate) {
      if (this.viewTemplate.sort.isEqual(this.resultedSort)) {
        sanitizedStruct.sort = null;
      }
      if (this.resultedIsSingleScale === this.viewTemplate.isSingleScale) {
        sanitizedStruct.isSingleScale = null;
      }
      if (this.resultedIsGrayscale === this.viewTemplate.isGrayscale) {
        sanitizedStruct.isGrayscale = null;
      }
      if (this.resultedIsSaturation === this.viewTemplate.isSaturation) {
        sanitizedStruct.isSaturation = null;
      }
      if (this.resultedCardFields === this.viewTemplate.cardFields) {
        sanitizedStruct.cardFields = null;
      }
      if (this.isHiddenDesc === this.viewTemplate.isHiddenDesc) {
        sanitizedStruct.isHiddenDesc = null;
      }
    }

    return omitBy(sanitizedStruct, v => isNil(v));
  }

  /**
   * @param {ProductViewSettings} object
   * @return {boolean}
   */
  isEqual(object) {
    return isEqual(this.sanitizeValues(), object.sanitizeValues());
  }

  /**
   * @param {ProductViewSettings} otherObject
   * @return {Object}
   */
  diff(otherObject) {
    return omitBy(diff(otherObject.sanitizeValues(), this.sanitizeValues()), v => isNil(v));
  }
}

export const SHORT_LIST_VIEW = new ProductViewSettings({
  viewTemplate: null,
  mediaSize: MEDIA_SIZE_SMALL,
  cardFields: [FIELD_SHORT_VIEW.key],
  isSingleScale: true,
});

export const TABLE_ROW_VIEW = new ProductViewSettings({
  viewTemplate: null,
  mediaSize: MEDIA_SIZE_XSMALL,
  isHiddenDesc: true,
});
