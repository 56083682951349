import {
  FIELD_AVG_BRIGHTNESS,
  FIELD_BRILLIANCE,
  FIELD_CARAT_WEIGHT,
  FIELD_CERTIFICATE,
  FIELD_CLARITY,
  FIELD_COLOR_GRADE,
  FIELD_CREATED_AT,
  FIELD_CROWN_ANGLE,
  FIELD_CROWN_COMPOSED,
  FIELD_CROWN_MM,
  FIELD_CROWN_PC,
  FIELD_CULET,
  FIELD_CULET_COMPOSED,
  FIELD_CULET_MM,
  FIELD_CULET_PC,
  FIELD_CUSTOM_COLOR,
  FIELD_CUSTOM_GIRDLE_BEAUTY,
  FIELD_CUSTOM_BRILLIANCE,
  FIELD_CUSTOM_CUT_PERFORMANCE,
  FIELD_CUT,
  FIELD_CUT_PERFORMANCE,
  FIELD_DEPTH_COMPOSED,
  FIELD_DEPTH_MM,
  FIELD_DEPTH_PC,
  FIELD_DIAMETER_RATIO,
  FIELD_DIMENSIONS,
  FIELD_FACE_BRIGHTNESS,
  FIELD_FIRE,
  FIELD_SCINTILLATION,
  FIELD_FLUOR,
  FIELD_FLUOR_STRENGTH,
  FIELD_GIRDLE,
  FIELD_GIRDLE_COMPOSED,
  FIELD_GIRDLE_MM,
  FIELD_GIRDLE_PC,
  FIELD_LABELS, FIELD_LENGTH,
  FIELD_LW,
  FIELD_OPTICAL_SYMMETRY,
  FIELD_PAVILION_FIRST_ANGLE,
  FIELD_PAVILION_ANGLE,
  FIELD_PAVILION_COMPOSED,
  FIELD_PAVILION_MM,
  FIELD_PAVILION_PC,
  FIELD_POLISH,
  FIELD_PRICE,
  FIELD_SELLER,
  FIELD_SHAPE,
  FIELD_SHORT_VIEW,
  FIELD_SKU,
  FIELD_SPREAD,
  FIELD_SPREAD_CT,
  FIELD_SPREAD_PC,
  FIELD_SYMMETRY,
  FIELD_TABLE_COMPOSED,
  FIELD_TABLE_MM,
  FIELD_TABLE_PC,
  FIELD_WIDTH,
  FIELD_PRICE_CT,
  FIELD_FIRE_MAP,
  FIELD_SCINTILLATION_MAP,
  FIELD_PAVILION_COLOR,
  FIELD_TABLE_COLOR,
  FIELD_HISTOGRAM,
  FIELD_CHROMA,
  FIELD_NEXT_PAYMENT,
  FIELD_EXPENSES,
  FIELD_SHAPE_NORMALIZED_PERFORMANCE,
  FIELD_GLOBAL_ACCESS,
  FIELD_PRIVATE_ACCESS, FIELD_LABELS_EXTENDED,
} from './fieldsMap';

export const ONLY_INTERNAL_USER_FIELDS = [
  FIELD_FIRE_MAP,
  FIELD_SCINTILLATION,
  FIELD_SCINTILLATION_MAP,
];

export const DIAMOND_BASE_COMBO_FIELDS = [
  FIELD_PRICE,
  FIELD_PRICE_CT,
  FIELD_CARAT_WEIGHT,
  FIELD_CERTIFICATE,
  FIELD_SHAPE_NORMALIZED_PERFORMANCE,
  FIELD_CUT_PERFORMANCE,
  FIELD_FIRE,
  FIELD_BRILLIANCE,
  FIELD_SCINTILLATION,
  FIELD_OPTICAL_SYMMETRY,
  FIELD_SPREAD,
  FIELD_FLUOR,
  FIELD_FACE_BRIGHTNESS,
  FIELD_AVG_BRIGHTNESS,
  FIELD_CUSTOM_GIRDLE_BEAUTY,
  FIELD_CUSTOM_CUT_PERFORMANCE,
  FIELD_CUSTOM_BRILLIANCE,
  FIELD_COLOR_GRADE,
  FIELD_CUSTOM_COLOR,
  FIELD_PAVILION_COLOR,
  FIELD_TABLE_COLOR,
  FIELD_HISTOGRAM,
  FIELD_CHROMA,
  FIELD_DIAMETER_RATIO,
  FIELD_CLARITY,
  FIELD_CUT,
  FIELD_SYMMETRY,
  FIELD_POLISH,
  FIELD_FLUOR_STRENGTH,
  FIELD_LW,
  FIELD_DEPTH_COMPOSED,
  FIELD_TABLE_COMPOSED,
  FIELD_CROWN_ANGLE,
  FIELD_CROWN_COMPOSED,
  FIELD_PAVILION_FIRST_ANGLE,
  FIELD_PAVILION_ANGLE,
  FIELD_PAVILION_COMPOSED,
  FIELD_GIRDLE_COMPOSED,
  FIELD_CULET_COMPOSED,
  FIELD_GIRDLE,
  FIELD_CULET,
  FIELD_SELLER,
  FIELD_CREATED_AT,
  FIELD_SHAPE,
];

export const DIAMOND_COMBO_ALL_FIELDS_MAP = [
  FIELD_LABELS_EXTENDED,
  FIELD_SKU,
  ...DIAMOND_BASE_COMBO_FIELDS,
];

export const DIAMOND_COMBO_DZ_FIELDS_MAP = [
  ...new Set([
    FIELD_SKU,
    FIELD_PAVILION_COLOR,
    FIELD_TABLE_COLOR,
    ...DIAMOND_BASE_COMBO_FIELDS,
  ]),
];

export const DIAMOND_COMBO_SOLUTION_FIELDS_MAP_ONLY_MEDIA = [
  FIELD_SKU,
];

export const DIAMOND_COMBO_FANCY_COLOR_FIELDS_MAP = [
  ...new Set([
    FIELD_SKU,
    FIELD_CHROMA,
    FIELD_HISTOGRAM,
    FIELD_COLOR_GRADE,
    ...DIAMOND_BASE_COMBO_FIELDS,
  ]),
];

export const DIAMOND_COMBO_COMPARISON = [
  FIELD_LABELS_EXTENDED,
  ...DIAMOND_BASE_COMBO_FIELDS,
];

export const DIAMOND_COMBO_MOBILE_REFERENCE_COMPARISON = [
  FIELD_CARAT_WEIGHT,
  FIELD_CERTIFICATE,
  FIELD_SHAPE_NORMALIZED_PERFORMANCE,
  FIELD_CUT_PERFORMANCE,
  FIELD_FIRE,
  FIELD_BRILLIANCE,
  FIELD_SCINTILLATION,
  FIELD_OPTICAL_SYMMETRY,
  FIELD_SPREAD,
  FIELD_FACE_BRIGHTNESS,
  FIELD_AVG_BRIGHTNESS,
  FIELD_DIAMETER_RATIO,
  FIELD_CUT,
  FIELD_SYMMETRY,
  FIELD_POLISH,
  FIELD_LW,
  FIELD_DEPTH_COMPOSED,
  FIELD_TABLE_COMPOSED,
  FIELD_CROWN_ANGLE,
  FIELD_CROWN_COMPOSED,
  FIELD_PAVILION_FIRST_ANGLE,
  FIELD_PAVILION_ANGLE,
  FIELD_PAVILION_COMPOSED,
  FIELD_GIRDLE_COMPOSED,
  FIELD_CULET_COMPOSED,
  FIELD_GIRDLE,
  FIELD_CULET,
  FIELD_SHAPE,
];

export const DIAMOND_COMBO_REFERENCE_COMPARISON = [
  FIELD_SKU,
  ...DIAMOND_COMBO_MOBILE_REFERENCE_COMPARISON,
];

export const DIAMOND_BASE_CARD_FIELDS_MAP = [
  FIELD_SHAPE_NORMALIZED_PERFORMANCE,
  FIELD_CUT_PERFORMANCE,
  FIELD_FIRE,
  FIELD_BRILLIANCE,
  FIELD_SCINTILLATION,
  FIELD_OPTICAL_SYMMETRY,
  FIELD_SPREAD,
  FIELD_FLUOR,
  FIELD_FACE_BRIGHTNESS,
  FIELD_AVG_BRIGHTNESS,
  FIELD_CARAT_WEIGHT,
  FIELD_COLOR_GRADE,
  FIELD_PAVILION_COLOR,
  FIELD_TABLE_COLOR,
  FIELD_CUSTOM_COLOR,
  FIELD_CUSTOM_GIRDLE_BEAUTY,
  FIELD_CUSTOM_CUT_PERFORMANCE,
  FIELD_CUSTOM_BRILLIANCE,
  FIELD_DIAMETER_RATIO,
  FIELD_CLARITY,
  FIELD_CUT,
  FIELD_POLISH,
  FIELD_SYMMETRY,
  FIELD_FLUOR_STRENGTH,
  FIELD_SPREAD_PC,
  FIELD_SPREAD_CT,
  FIELD_PRICE,
  FIELD_LENGTH,
  FIELD_WIDTH,
  FIELD_DEPTH_PC,
  FIELD_DEPTH_MM,
  FIELD_TABLE_PC,
  FIELD_TABLE_MM,
  FIELD_CROWN_ANGLE,
  FIELD_CROWN_PC,
  FIELD_CROWN_MM,
  FIELD_PAVILION_FIRST_ANGLE,
  FIELD_PAVILION_ANGLE,
  FIELD_PAVILION_PC,
  FIELD_PAVILION_MM,
  FIELD_GIRDLE_PC,
  FIELD_GIRDLE_MM,
  FIELD_CULET_PC,
  FIELD_CULET_MM,
  FIELD_GIRDLE,
  FIELD_CULET,
];

export const DIAMOND_CARD_FIELDS_MAP = [
  FIELD_SKU,
  FIELD_SHORT_VIEW,
  ...DIAMOND_BASE_CARD_FIELDS_MAP,
];

export const DIAMOND_COLORLESS_TABLE_FIELDS_MAP = [
  FIELD_LABELS,
  FIELD_SKU,
  FIELD_CARAT_WEIGHT,
  FIELD_COLOR_GRADE,
  FIELD_CLARITY,
  FIELD_SHAPE,
  FIELD_CREATED_AT,
  FIELD_EXPENSES,
  FIELD_NEXT_PAYMENT,
  FIELD_CERTIFICATE,
  FIELD_SHAPE_NORMALIZED_PERFORMANCE,
  FIELD_CUT_PERFORMANCE,
  FIELD_FIRE,
  FIELD_BRILLIANCE,
  FIELD_SCINTILLATION,
  FIELD_OPTICAL_SYMMETRY,
  FIELD_SPREAD_PC,
  FIELD_GLOBAL_ACCESS,
  FIELD_PRIVATE_ACCESS,
];

export const DIAMOND_FANCY_TABLE_FIELDS_MAP = [
  FIELD_LABELS,
  FIELD_SKU,
  FIELD_CARAT_WEIGHT,
  FIELD_COLOR_GRADE,
  FIELD_CREATED_AT,
  FIELD_EXPENSES,
  FIELD_NEXT_PAYMENT,
  FIELD_CLARITY,
  FIELD_SHAPE,
  FIELD_CERTIFICATE,
  FIELD_SPREAD_PC,
  FIELD_GLOBAL_ACCESS,
  FIELD_PRIVATE_ACCESS,
];

export const DIAMOND_COMBO_FIELDS_MAP_ONLY_MEDIA = [
  FIELD_LABELS_EXTENDED,
  FIELD_SKU,
  FIELD_PRICE,
];

export const CARD_FIELDS_SORT = [
  ...DIAMOND_BASE_CARD_FIELDS_MAP,
  FIELD_LABELS,
  FIELD_SHAPE,
  FIELD_CREATED_AT,
];

export const LGD_BASE_COMBO_FIELDS = [
  FIELD_LABELS_EXTENDED,
  ...DIAMOND_BASE_COMBO_FIELDS,
];

export const LGD_COMBO_ALL_FIELDS_MAP = [
  FIELD_LABELS_EXTENDED,
  FIELD_SKU,
  ...DIAMOND_BASE_COMBO_FIELDS,
];

export const LGD_COMBO_COMPARISON = LGD_BASE_COMBO_FIELDS;

export const LGD_CARD_FIELDS_MAP = [
  FIELD_LABELS,
  ...DIAMOND_BASE_CARD_FIELDS_MAP,
];

export const LGD_COLORLESS_TABLE_FIELDS_MAP = [
  FIELD_LABELS,
  FIELD_SKU,
  FIELD_CARAT_WEIGHT,
  FIELD_COLOR_GRADE,
  FIELD_CLARITY,
  FIELD_SHAPE,
  FIELD_CREATED_AT,
  FIELD_EXPENSES,
  FIELD_NEXT_PAYMENT,
  FIELD_CERTIFICATE,
  FIELD_SHAPE_NORMALIZED_PERFORMANCE,
  FIELD_CUT_PERFORMANCE,
  FIELD_FIRE,
  FIELD_BRILLIANCE,
  FIELD_SCINTILLATION,
  FIELD_OPTICAL_SYMMETRY,
  FIELD_SPREAD_PC,
  FIELD_GLOBAL_ACCESS,
  FIELD_PRIVATE_ACCESS,
];

export const LGD_FANCY_TABLE_FIELDS_MAP = [
  FIELD_LABELS,
  FIELD_SKU,
  FIELD_CARAT_WEIGHT,
  FIELD_COLOR_GRADE,
  FIELD_CREATED_AT,
  FIELD_EXPENSES,
  FIELD_NEXT_PAYMENT,
  FIELD_CLARITY,
  FIELD_SHAPE,
  FIELD_CERTIFICATE,
  FIELD_SPREAD_PC,
  FIELD_GLOBAL_ACCESS,
  FIELD_PRIVATE_ACCESS,
];

export const LGD_COMBO_FIELDS_MAP_ONLY_MEDIA = [
  FIELD_SKU,
  FIELD_PRICE,
];

export const ROUGH_BASE_COMBO_FIELDS = [
  FIELD_SELLER,
  FIELD_PRICE,
  FIELD_CREATED_AT,
  FIELD_EXPENSES,
  FIELD_NEXT_PAYMENT,
  FIELD_CARAT_WEIGHT,
  FIELD_DIMENSIONS,
];

export const ROUGH_TABLE_FIELDS_MAP = [
  FIELD_LABELS,
  FIELD_SKU,
  ...ROUGH_BASE_COMBO_FIELDS,
  FIELD_GLOBAL_ACCESS,
  FIELD_PRIVATE_ACCESS,
];

export const JEWELRY_TABLE_FIELDS_MAP = [
  FIELD_LABELS,
  FIELD_SKU,
  FIELD_SELLER,
  FIELD_PRICE,
  FIELD_CREATED_AT,
  FIELD_EXPENSES,
  FIELD_NEXT_PAYMENT,
  FIELD_CARAT_WEIGHT,
  FIELD_GLOBAL_ACCESS,
  FIELD_PRIVATE_ACCESS,
];

export const CUSTOM_GRADE_FIELDS = [FIELD_CUSTOM_COLOR, FIELD_CUSTOM_GIRDLE_BEAUTY, FIELD_CUSTOM_CUT_PERFORMANCE, FIELD_CUSTOM_BRILLIANCE];

export const ROUGH_COMPARISON = ROUGH_BASE_COMBO_FIELDS;

export const LGD_ROUGH_COMPARISON = [
  FIELD_LABELS,
  ...ROUGH_COMPARISON,
];
