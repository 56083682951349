import isEqual from 'lodash/isEqual';

export default class Sort {
  sortFields;

  constructor() {
    this.sortFields = [];
  }

  static get DIRECTION_ASC() {
    return 'asc';
  }

  static get DIRECTION_DESC() {
    return 'desc';
  }

  static get SORT_MEDIA_RELEVANCE() {
    return 'mediaRelevance';
  }

  static get SORT_MANUAL() {
    return 'manual';
  }

  static get SORT_CREATED_AT() {
    return 'createdAt';
  }

  static get DEFAULT_SORT() {
    return Sort.SORT_MEDIA_RELEVANCE;
  }

  /**
   * todo: remove, transform duplicate
   * @return {?Object}
   */
  static decode(transformedSort) {
    if (!transformedSort) {
      return null;
    }

    const decodedField = Object.keys(transformedSort)[0];
    const decodedDirection = transformedSort[decodedField];

    return { field: decodedField, direction: decodedDirection };
  }

  /**
   * @param {Object} orderByFromServer
   * @return {Sort}
   */
  static fromJSON(orderByFromServer) {
    const sort = new Sort();
    Object.keys(orderByFromServer).forEach(sortField => sort.addMultiSort(sortField, orderByFromServer[sortField]));

    return sort;
  }

  /**
   * @param {string} field
   * @param {string} direction
   * @return {Sort}
   */
  setSingleSort(field, direction) {
    if (field && direction) {
      this.sortFields = [{ field: field, direction: direction }];
    }

    return this;
  }

  /**
   * @param {string} fieldName
   * @return {string}
   */
  getSortDirection(fieldName) {
    const sortField = this.getFieldByName(fieldName);
    return sortField ? sortField.direction : Sort.DIRECTION_DESC;
  }

  /**
   * @param {string} fieldName
   * @return {Object}
   */
  getFieldByName(fieldName) {
    return this.sortFields.find(sortItem => sortItem.field === fieldName);
  }

  /**
   * @return {?string}
   */
  getFirstField() {
    return this.sortFields && this.sortFields.length > 0 ? this.sortFields[0].field : null;
  }

  /**
   * @param {string} fieldName
   * @return {boolean}
   */
  hasField(fieldName) {
    return !!this.getFieldByName(fieldName);
  }

  /**
   * @param {string} field
   * @param {string} direction
   * @return {Sort}
   */
  addMultiSort(field, direction) {
    this.sortFields.push({ field: field, direction: direction });

    return this;
  }

  /**
   * @return {Object}
   */
  transform() {
    const transformedSort = {};
    this.sortFields.forEach((sort) => {
      transformedSort[sort.field] = sort.direction;
    });

    return transformedSort;
  }

  isEqual(object) {
    if (!object) {
      return false;
    }

    const transformedThis = this.transform();
    const transformedObject = object.transform();

    return isEqual(transformedThis, transformedObject);
  }

  static makeManual() {
    return new Sort().setSingleSort(Sort.SORT_MANUAL, Sort.DIRECTION_DESC);
  }
}
