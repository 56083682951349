import CutwiseAPIClient, {METHOD_GET} from '../../common/network/CutwiseAPIClient';

export const PATH_BILLING_STATE = 'api/v2/billing/state';

export default class BillingAPI {
  /**
   * @return {Promise<QueryResult>}
   */
  fetchState() {
    const qb = CutwiseAPIClient.createQueryBuilder()
      .method(METHOD_GET)
      .path(PATH_BILLING_STATE);

    const query = qb.getQuery();

    return CutwiseAPIClient.sendQuery(query);
  }
}
