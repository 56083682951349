import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { computed, observable, action } from 'mobx';
import './CollectionPopover.less';
import CollectionsStore from '../../stores/CollectionsStore';
import Product from '../../../product/entity/Product';
import closePopover from '../../../common/helpers/closePopover';
import Icon from '../../../common/components/Icon/Icon';
import TextField from '../../../common/components/inputs/TextField/TextField';
import CollectionsList from '../CollectionsList/CollectionsList';
import Button from '../../../common/components/Button/Button';

@inject('collectionsStore')
@observer
export default class CollectionPopover extends Component {
  static propTypes = {
    collectionsStore: PropTypes.instanceOf(CollectionsStore).isRequired,
    product: PropTypes.instanceOf(Product),
    className: PropTypes.string,
    onItemSelection: PropTypes.func,
    withLinks: PropTypes.bool,
  };

  static defaultProps = {
    product: null,
    onItemSelection: null,
    withLinks: false,
    className: '',
  };

  @observable
  collectionTitle = '';

  @observable
  collectionSearchString = '';

  @computed
  get filteredCollections() {
    if (!this.collectionSearchString) {
      return this.props.collectionsStore.collections;
    }

    if (this.props.collectionsStore.collections) {
      return this.props.collectionsStore.collections.filter(c => c.title && c.title.toLowerCase().indexOf(this.collectionSearchString.toLowerCase()) !== -1);
    }

    return [];
  }

  handleCollectionCreation = () => {
    this.props.collectionsStore.createNewCollection(this.collectionTitle).then(() => {
      this.collectionTitle = '';

      if (this.props.onItemSelection) {
        this.props.onItemSelection(this.props.collectionsStore.activeCollection);
      }
    });

    if (this.props.product !== null) {
      closePopover();
    }
  };

  @action
  handleNewCollectionTitleChanging = (e) => {
    this.collectionTitle = e.target.value;
  };

  @action
  handleSearchStringChanging = (e) => {
    this.collectionSearchString = e.target.value;
  };

  render() {
    const { className, withLinks, onItemSelection } = this.props;

    if (!this.filteredCollections) {
      return null;
    }

    return (
      <div className={`collections-popover collections-popover--${this.props.product ? 'small' : 'full'} ${className}`}>
        <div className="collections-popover__search">
          <Icon name="search" className="collections-popover__search-icon" />
          <TextField
            placeholder="Search"
            className="collections-popover__search-input"
            name="collection-search"
            modifier="squared"
            value={this.collectionSearchString}
            onChange={this.handleSearchStringChanging}
          />
        </div>
        {this.filteredCollections.length > 0 ? (
          <>
            <div className="collections-popover__collections">
              <CollectionsList withLinks={withLinks} onItemClick={onItemSelection} collections={this.filteredCollections} productContext={this.props.product} />
            </div>
          </>
        ) : (
          <div className="collections-popover__empty">No Collections found, please create one</div>
        )}
        <TextField
          autoComplete="off"
          placeholder="Type here collection title…"
          name="title"
          modifier="white"
          value={this.collectionTitle}
          onChange={this.handleNewCollectionTitleChanging}
        />
        <Button block color="primary" disabled={this.collectionTitle === ''} onClick={this.handleCollectionCreation}>
          Create new Collection
        </Button>
      </div>
    );
  }
}
