export class BillingAccountModel {
    id;
    balance;
    title;
    type;
    currency;
}
export class BillingAccount extends BillingAccountModel {
    constructor(model) {
        super();
        const { id, balance, title, type, currency } = model;
        this.id = id;
        this.balance = balance;
        this.title = title;
        this.type = type;
        this.currency = currency;
    }
    isDiBox() {
        return this.type === 'dibox';
    }
    isHPC() {
        return this.type === 'hpc';
    }
}
