import {
  observable, action, computed,
} from 'mobx';
import isNil from 'lodash/isNil';
import UserSessionDataManager from '../services/session/UserSessionDataManager';
import LocalStorageDriver from '../services/session/LocalStorageDriver';
import Storage, {KEY_IS_SIDEBAR_OPENED} from '../services/Storage';
import {IS_PORTABLE} from '../helpers/mobileDetect';
import ProductRepository from '../../product/repository/ProductRepository';
import ProductAPI from '../../product/api/ProductsAPI';
import IdentityMap from '../storage/IdentityMap';
import asyncProductLoader from '../../catalog/service/AsyncProductLoader';
import Sort from '../state/Sort';
import {sortRepository} from '../../catalog/repository/SortRepository';
import {
  TYPE_DIAMOND,
  TYPE_DIAMOND_COLORLESS,
  TYPE_DIAMOND_FANCY,
  TYPE_LGD_COLORLESS,
  TYPE_LGD_FANCY, TYPE_LGD_ROUGH, TYPE_ROUGH,
} from '../../product/constants/productTypes';
import {SCREEN_MD, SCREEN_SM, SCREEN_XS} from '../constants/breakpoints';
import updateWaypoints from '../helpers/updateWaypoints';
import APIDriver from '../services/session/APIDriver';
import ConfigurationStore, {configurationStore} from '@/common/stores/ConfigurationStore';

export default class AppStore {
  /**
   * @type {?string}
   */
  @observable
  productTypeContext = null;

  /**
   * @type {boolean}
   */
  @observable
  isSidebarOpened;

  /**
   * @type {boolean}
   */
  @observable
  isHQModeEnabled;

  /**
   * @type {?number}
   */
  @observable
  contentAreaWidth = null;

  /**
   * @type {?number}
   */
  @observable
  screenWidth = null;

  /**
   * @type {ProductRepository}
   */
  productRepository;

  /**
   * @param {ConfigurationStore} configurationStore
   */
  constructor(configurationStore) {
    this.configurationStore = configurationStore;

    this.userSessionDataManager = new UserSessionDataManager(new LocalStorageDriver());

    const isSidebarOpened = Storage.getObject(KEY_IS_SIDEBAR_OPENED, Storage.STORAGE_LOCAL);
    this.isSidebarOpened = !isNil(isSidebarOpened) ? isSidebarOpened : !IS_PORTABLE;
    this.isHQModeEnabled = window.devicePixelRatio > 1;

    this.productRepository = new ProductRepository(new ProductAPI(), new IdentityMap(navigator && navigator.deviceMemory > 2 ? 500 : 100));
    asyncProductLoader.setRepository(this.productRepository);
  }

  // todo
  /**
   * @return {?Sort}
   */
  @computed
  get defaultSort() {
    const defaultSort = this.activeSortList[0];
    return new Sort().setSingleSort(defaultSort.field, defaultSort.direction);
  }

  // todo
  /**
   * @return {Sort[]}
   */
  @computed
  get activeSortList() {
    return sortRepository.getSortsByProductType(this.productTypeContext);
  }

  /**
   * @return {boolean}
   */
  @computed
  get isDiamondContext() {
    return Boolean([TYPE_DIAMOND_COLORLESS, TYPE_DIAMOND_FANCY, TYPE_LGD_COLORLESS, TYPE_LGD_FANCY].includes(this.productTypeContext));
  }

  /**
   * @return {boolean}
   */
  @computed
  get isRoughContext() {
    return Boolean([TYPE_ROUGH, TYPE_LGD_ROUGH].includes(this.productTypeContext));
  }

  /**
   * @return {boolean}
   */
  @computed
  get isNaturalContext() {
    return Boolean([TYPE_DIAMOND_COLORLESS, TYPE_DIAMOND_FANCY, TYPE_ROUGH].includes(this.productTypeContext));
  }

  /**
   * @return {boolean}
   */
  @computed
  get isLGDContext() {
    return Boolean([TYPE_LGD_COLORLESS, TYPE_LGD_FANCY, TYPE_LGD_ROUGH].includes(this.productTypeContext));
  }

  /**
   * @return {boolean}
   */
  @computed
  get isColorlessContext() {
    return Boolean([TYPE_DIAMOND_COLORLESS, TYPE_LGD_COLORLESS].includes(this.productTypeContext));
  }

  /**
   * @return {boolean}
   */
  get isLandscape() {
    return window.innerWidth > window.innerHeight;
  }

  /**
   * @return {boolean}
   */
  @computed
  get isPhone() {
    return this.isPhonePortrait || this.isPhoneLandscape;
  }

  /**
   * `isPhonePortrait === true` means that user rotated mobile device vertically
   *
   * @return {boolean}
   */
  @computed
  get isPhonePortrait() {
    return this.screenWidth <= SCREEN_XS && !this.isLandscape;
  }

  /**
   * `isPhoneLandscape === true` means that user rotated mobile device horizontally
   *
   * @return {boolean}
   */
  @computed
  get isPhoneLandscape() {
    return this.screenWidth <= SCREEN_MD && this.isLandscape;
  }

  /**
   * @return {boolean}
   */
  @computed
  get isTabletPortrait() {
    return this.screenWidth >= SCREEN_SM && this.screenWidth <= SCREEN_MD && !this.isLandscape;
  }

  get isTouch() {
    // taken from here: https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript
    return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
  }

  @action
  toggleSidebar = () => {
    this.isSidebarOpened = !this.isSidebarOpened;
    Storage.saveObject(KEY_IS_SIDEBAR_OPENED, this.isSidebarOpened, Storage.STORAGE_LOCAL);

    updateWaypoints(); // recalculate visible area of layout
  };

  /**
   * @param {string} productTypeContext
   */
  @action
  setProductTypeContext(productTypeContext) {
    this.productTypeContext = productTypeContext;
  }

  /**
   * @param {number} cw
   */
  @action
  setContainerWidth = (cw) => {
    this.contentAreaWidth = cw;
  };

  /**
   * @param {number} sw
   */
  @action
  setScreenWidth = (sw) => {
    this.screenWidth = sw;
  };

  // todo
  /**
   * @return {Object[]}
   */
  @computed
  get activeB2BList() {
    let b2bFiltersKey = TYPE_DIAMOND;

    if (!this.isDiamondContext) {
      b2bFiltersKey = this.productTypeContext;
    }
    if (this.isRoughContext) {
      b2bFiltersKey = TYPE_ROUGH;
    }

    return this.configurationStore.b2bFilters ? this.configurationStore.b2bFilters[b2bFiltersKey] : [];
  }

  enableCloudSettingsStorage() {
    this.userSessionDataManager.setDriver(new APIDriver());
  }
}

export const appStore = new AppStore(configurationStore);
