import { observable } from 'mobx';
import {METRIC_DATA_DECORATOR_DEFAULT} from '../constants/dataDecorators';
import Diamond from '../../product/entity/Diamond';

/**
 * @typedef {Object} MetricDataDecorator
 * @property {string[]} colors
 * @property {string[]} marks
 * @property {MetricDataDecoratorCutShapeThresholds[]} thresholds
 */

/**
 * @typedef {Object} MetricDataDecoratorCutShapeThresholds
 * @property {string[]} brightness
 * @property {string[]} fire
 * @property {string[]} integral
 * @property {string[]} symmetry
 * @property {number} shape
 */

export default class ScoringRepository {
  @observable.ref
  scoring;

  /**
   * @type {Object.<string, MetricDataDecorator>}
   */
  @observable.ref
  metricDataDecorators;

  /**
   * @type {MetricDataDecoratorCutShapeThresholds[]}
   */
  @observable.ref
  metricThresholds;

  initScoring(scoringData) {
    this.scoring = scoringData;
  }

  initMetricDataDecorators(metricDataDecorators) {
    this.metricDataDecorators = metricDataDecorators;
  }

  initMetricThresholds(thresholds) {
    this.metricThresholds = thresholds;
  }

  /**
   * @param metricName
   * @return {?number[]}
   */
  getGradesForMetric(metricName) {
    if (!this.scoring || !this.scoring[metricName] || !this.scoring[metricName].grades) {
      return null;
    }

    return this.scoring[metricName].grades;
  }

  /**
   * @param {string} dataDecoratorId
   * @return {?string[]}
   */
  getMarksFor(dataDecoratorId = METRIC_DATA_DECORATOR_DEFAULT) {
    const dataDecorator = this.metricDataDecorators[dataDecoratorId];

    if (!dataDecorator) {
      return null;
    }

    return dataDecorator.marks;
  }

  /**
   * @param {string} dataDecoratorId
   * @return {?string[]}
   */
  getColorsFor(dataDecoratorId = METRIC_DATA_DECORATOR_DEFAULT) {
    const dataDecorator = this.metricDataDecorators[dataDecoratorId];

    if (!dataDecorator) {
      return null;
    }

    return dataDecorator.colors;
  }

  /**
   * @param {Product} product
   * @param {string} metricName
   * @return {?number[]}
   */
  getThresholdsFor(product, metricName) {
    if (!(product instanceof Diamond)) {
      return null;
    }

    const defaultScoring = this.scoring[metricName].grades;

    if (!this.metricThresholds) {
      return defaultScoring;
    }

    const thresholdsForCutShape = this.metricThresholds.find(({ shape }) => shape === product.parentCutShapeId);

    if (!thresholdsForCutShape) {
      return defaultScoring;
    }

    const thresholds = thresholdsForCutShape[metricName];

    if (!thresholds) {
      return defaultScoring;
    }

    return thresholds;
  }
}

export const scoringRepository = new ScoringRepository();
