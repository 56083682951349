import Cache from '../Cache';
import locationReload from '../../helpers/locationReload';
import { APP_VERSION_KEY, HEADER_X_VERSION } from '../CutwiseAPIClient';
import Storage from '../../services/Storage';

/**
 * @param {Query} query
 * @return {Query}
 */
export default function (query) {
  if (!query.result || !query.result.networkResponse || !query.result.networkResponse.headers) {
    return query;
  }

  /*
  * Possible cases to check:
  *
  * 1. Version exists in storage
  * - no version in response - continue request, version in storage won't be updated/removed
  * - same version in response - continue request, version in storage will be updated, but same value
  * - new version in response - stop request, clear cache, reload page
  *
  * 2. Version DOES NOT exist in storage:
  * - no version in response - continue request, version in storage won't be put
  * - new version in response - continue request, version in storage will be updated
  * */

  const responseVersion = query.result.networkResponse.headers.get(HEADER_X_VERSION);
  const currentVersion = Storage.getObject(APP_VERSION_KEY, Storage.STORAGE_LOCAL);

  if (!responseVersion) {
    return query;
  }

  if (!currentVersion) {
    return query;
  }

  if (responseVersion !== currentVersion) {
    Cache.clearIndex();
    locationReload();
  }

  return query;
}
