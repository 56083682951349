import { action, observable } from 'mobx';
import BillingAPI from '../api/BillingAPI';

export default class BillingStore {
  /**
   * @type {BillingAPI}
   */
  billingAPI;

  /**
   * @type {?BillingState}
   */
  @observable
  billingState;

  constructor() {
    this.billingAPI = new BillingAPI();
  }

  @action
  fetchBillingData() {
    // return this.billingAPI
    //   .fetchState()
    //   .then(
    //     action((res) => {
    //       if (!res.ok && res.data && res.data.message) {
    //         Notifier.error(res.data.message);
    //       } else {
    //         this.billingState = new BillingState(res.data, false);
    //       }
    //     }),
    //   )
    //   .catch((error) => {
    //     this.billingState = new BillingState(
    //       {
    //         alerts: [
    //           {
    //             type: 'danger',
    //             message: error.description,
    //           },
    //         ],
    //       },
    //       true,
    //     );
    //   });
  }

  /**
   * {
   *     "balance": "1381.00",
   *     "forecast": {
   *         "new": [
   *             {
   *                 "services": [1, 3, 4],
   *                 "cost": 1.2,
   *                 "count": 111,
   *             }
   *         ],        "time": {
   *             "period": "2 weeks"
   *         },
   *     },
   *     "hosting": {
   *         "periods": [
   *             {
   *                 "months": 0,
   *                 "products": 504,
   * TBD                “? ptype”: 1, [Colorless Diams | Fancy Color Diams | LGD Colorless Diams | LGD Fancy Color Diams | Rough | Jewelry]
   *                 "spent": null,
   *                 "next": 100.8
   *             },
   *             {
   *                 "months": 1,
   *                 "products": 695,
   *                 “ptype”: 1,
   *                 "spent": 278,
   *                 "next": 139
   *             },
   *             {
   *                 "months": 1,
   *                 "products": 695,
   *                 “ptype”: 3,
   *                 "spent": 315,
   *                 "next": 211
   *             }
   *         ]
   *     }
   *     "services": [
   *         {
   *             "title": "New Product Submit",
   *             "cost": "1",
   *             "code": 2
   *         }, {
   *             "title": "Video processing",
   *             "cost": "0.18",
   *             "code": 7
   *         }
   *     ],
   *     "currency": "EUR"
   * }
   */
}
