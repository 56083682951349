import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {inject, observer, Provider} from 'mobx-react';
import {securityManager, MANAGER} from '../../../common/services/SecurityManager';
import Layout from '../../../common/components/Layout/Layout';
import Content from '../../../common/components/Layout/Content/Content';
import ErrorPage from '../../../common/pages/ErrorPage/ErrorPage';
import AppError from '../../../common/state/AppError';
import withPageViewHOC from '@/analytics/components/WithPageViewHOC';
import {DEFAULT_CATEGORY} from '@/analytics/services/Analytics';
import ShowOnlyForAuthorizedUserHOC from '../../../user/components/ShowOnlyForAuthorizedUser/ShowOnlyForAuthorizedUser';
import DashboardToolbar from '../../components/DashboardToolbar/DashboardToolbar';
import BillingStore from '../../stores/BillingStore';
import styles from './DashboardPage.module.css';
import Button, {PRIMARY} from '../../../common/components/Button/Button';
import HostedStockTable from '../../components/HostedStockTable/HostedStockTable';
import ServiceForecastTable from '../../components/ServiceForecastTable/ServiceForecastTable';
import Link from '../../../common/components/Link/Link';
import Avatar from '../../../common/components/Avatar/Avatar';

@inject('userStore') @withRouter @observer
class DashboardPage extends Component {
  constructor(props) {
    super(props);

    this.billingStore = new BillingStore();
  }

  componentDidMount() {
    if (securityManager.isGranted(MANAGER)) {
      this.billingStore.fetchBillingData();
    }
  }

  render() {
    return (<Provider profileStore={this.billingStore}>
      <Layout>
        <Content className={styles.dashboardPage}>
          <DashboardToolbar/>
          <div className={styles.dashboardPanels}>
            <section className={styles.sectionBillingState}>
              <div className={styles.sectionHeader}>Balance</div>
              <div className={styles.balanceInfo}>
                <h3>8 700.08 €</h3>
                <Button color={PRIMARY}>Replenish Balance</Button>
                <Button>&#9200;</Button>
              </div>
              <ServiceForecastTable/>
              <ServiceForecastTable alert/>
              <div className={styles.sectionHeader}>Hosted Stock</div>
              <HostedStockTable/>
            </section>
            <section className={styles.sectionExpenses}>
              <div className={styles.sectionHeader}>Expenses Analysis</div>
              <div className={styles.expensesAnalysisBlock}>
                <div>
                  <h3>Products</h3>
                  <table>
                    <tbody>
                    <tr>
                      <td>+</td>
                      <td>New</td>
                      <td>3131</td>
                    </tr>
                    <tr>
                      <td>-</td>
                      <td>Removed</td>
                      <td>412</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <h3>Expenses</h3>
                  <h2>17 988.48 €</h2>
                  <Button>View Details in Cost Explorer</Button>
                </div>
                <div>
                  CHART
                </div>
              </div>
              <div className={styles.usersAndProductsBlock}>
                <div>
                  <div className={styles.sectionHeader}>Services</div>
                  <div>
                    <table>
                      <thead>
                      <tr>
                        <th>Service</th>
                        <th>Products</th>
                        <th>Expenses</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>
                          <p>Upload DiBox 2.0</p>
                          <p className="serviceCost">1.00 € per product</p>
                        </td>
                        <td>2 863</td>
                        <td>2 863.00 €</td>
                        <td><Link to="#">Details</Link></td>
                      </tr>
                      <tr>
                        <td>
                          <p>Upload 360 Video</p>
                          <p className="serviceCost">0.10 € per product (0.20 € for Rough or Jewelry)</p>
                        </td>
                        <td>2 863</td>
                        <td>2 863.00 €</td>
                        <td><Link to="#">Details</Link></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div>
                  <div className={styles.sectionHeader}>Users</div>
                  <div>
                    <table>
                      <thead>
                      <tr>
                        <th>User</th>
                        <th>Products</th>
                        <th>Expenses</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td><Avatar user={this.props.userStore.user} withUsername/></td>
                        <td>2 863</td>
                        <td>2 863.00 €</td>
                        <td><Link to="#">Details</Link></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Content>
      </Layout>
    </Provider>);
  }
}

export default withPageViewHOC(ShowOnlyForAuthorizedUserHOC(DashboardPage, <ErrorPage error={AppError.createUnauthorizedError()}/>), DEFAULT_CATEGORY);
