export const TYPE_DIAMOND = 'diamond';
export const TYPE_DIAMOND_COLORLESS = 'diamond-colorless';
export const TYPE_DIAMOND_FANCY = 'diamond-fancy';
export const TYPE_LGD = 'lgd';
export const TYPE_LGD_COLORLESS = 'lgd-colorless';
export const TYPE_LGD_FANCY = 'lgd-fancy';
export const TYPE_ROUGH = 'rough';
export const TYPE_LGD_ROUGH = 'lgd-rough';
export const TYPE_JEWELRY = 'jewelry';

export const COMPARE_DIAMOND_GROUP = new Set([
  TYPE_DIAMOND_COLORLESS,
  TYPE_DIAMOND_FANCY,
  TYPE_LGD_COLORLESS,
  TYPE_LGD_FANCY,
]);

export const COMPARE_ROUGH_GROUP = new Set([
  TYPE_ROUGH,
  TYPE_LGD_ROUGH,
]);

export const ALL_PRODUCT_TYPES = [
  TYPE_DIAMOND,
  TYPE_DIAMOND_COLORLESS,
  TYPE_DIAMOND_FANCY,
  TYPE_LGD,
  TYPE_LGD_COLORLESS,
  TYPE_LGD_FANCY,
  TYPE_LGD_ROUGH,
  TYPE_JEWELRY,
  TYPE_ROUGH,
];

export const reverseProductType = (productType) => {
  switch (productType) {
    case TYPE_DIAMOND_COLORLESS:
      return TYPE_LGD_COLORLESS;
    case TYPE_DIAMOND_FANCY:
      return TYPE_LGD_FANCY;
    case TYPE_ROUGH:
      return TYPE_LGD_ROUGH;
    case TYPE_LGD_COLORLESS:
      return TYPE_DIAMOND_COLORLESS;
    case TYPE_LGD_FANCY:
      return TYPE_DIAMOND_FANCY;
    case TYPE_LGD_ROUGH:
      return TYPE_ROUGH;
    default:
      return productType;
  }
};
