import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styles from './Loader.less';

export const BLACK = 'black';
export const WHITE = 'white';
export const COLORED = 'colored';

const APPEARANCE_CLASS_MAP = {
  [BLACK]: styles.loaderBlack,
  [WHITE]: styles.loaderWhite,
  [COLORED]: styles.loaderColored,
};

export const LARGE = 'large';
export const MEDIUM = 'medium';
export const SMALL = 'small';

const SIZE_CLASS_MAP = {
  [LARGE]: styles.loaderLarge,
  [MEDIUM]: styles.loaderMedium,
  [SMALL]: styles.loaderSmall,
};

export default class Loader extends Component {
  static propTypes = {
    appearance: PropTypes.oneOf([BLACK, WHITE, COLORED]),
    size: PropTypes.oneOf([LARGE, MEDIUM, SMALL]),
    className: PropTypes.string,
  };

  static defaultProps = {
    appearance: COLORED,
    size: LARGE,
    className: null,
  };

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div className={`
        ${styles.loader}
        ${APPEARANCE_CLASS_MAP[this.props.appearance]}
        ${SIZE_CLASS_MAP[this.props.size]}
        ${this.props.className}
      `}
      >
        <span />
        <span />
        <span />
      </div>
    );
  }
}
