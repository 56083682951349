import {observable, toJS} from 'mobx';
import {
  TYPE_DIAMOND_FANCY, TYPE_JEWELRY, TYPE_LGD_ROUGH, TYPE_ROUGH,
} from '../../product/constants/productTypes';
import {
  VIEW_TEMPLATE_TYPE_COLORLESS,
  VIEW_TEMPLATE_TYPE_FANCY,
  VIEW_TEMPLATE_TYPE_JEWELRY,
  VIEW_TEMPLATE_TYPE_ROUGH,
} from '../constants/viewTemplateTypes';
import Sort from '../../common/state/Sort';
import diff from '../../common/helpers/diff';

/**
 * @typedef {Object} ViewTemplatePlainObject
 * @property {number} id
 * @property {string} title
 * @property {Object} sort
 * @property {Object|Number} setupPreset
 * @property {Object|Number} setupPresetGroup
 * @property {Array} cardFields
 * @property {boolean} isHiddenDesc
 * @property {boolean} isSingleScale
 * @property {boolean} isGrayscale
 * @property {boolean} isSaturation
 * @property {[number]} type
 * @property {[number]} context
 */

export default class ViewTemplate {
  /**
   * @type {number}
   */
  @observable
  id;

  /**
   * @type {string}
   */
  @observable
  title;

  /**
   * @type {?SetupPreset}
   */
  @observable
  setupPreset;

  /**
   * @type {?SetupPresetGroup}
   */
  @observable
  setupPresetGroup;

  /**
   * @type {Sort}
   */
  @observable
  sort;

  /**
   * @type {string[]}
   */
  @observable
  cardFields;

  /**
   * @type {boolean}
   */
  @observable
  isHiddenDesc;

  /**
   * @type {boolean}
   */
  @observable
  isSingleScale;

  /**
   * @type {boolean}
   */
  @observable
  isGrayscale;

  /**
   * @type {boolean}
   */
  @observable
  isSaturation;

  /**
   * @type {[number]}
   */
  @observable
  type;

  /**
   * @type {[number]}
   */
  context;

  /**
   * @param {string} productType
   * @return {number}
   */
  static getTypeByProductType(productType) {
    if (productType === TYPE_ROUGH || productType === TYPE_LGD_ROUGH) {
      return VIEW_TEMPLATE_TYPE_ROUGH;
    }
    if (productType === TYPE_JEWELRY) {
      return VIEW_TEMPLATE_TYPE_JEWELRY;
    }
    if (productType === TYPE_DIAMOND_FANCY) {
      return VIEW_TEMPLATE_TYPE_FANCY;
    }

    return VIEW_TEMPLATE_TYPE_COLORLESS;
  }

  /**
   * @param {?ViewTemplatePlainObject} modelData
   * @param {?SetupPreset} setupPreset
   * @param {?SetupPresetGroup} setupPresetGroup
   * @constructor
   */
  constructor(modelData, setupPreset, setupPresetGroup) {
    this.id = modelData.id;
    this.title = modelData.title;
    this.sort = Sort.fromJSON(modelData.sort);
    this.cardFields = modelData.cardFields;
    this.setupPreset = setupPreset;
    this.setupPresetGroup = setupPresetGroup;
    this.isHiddenDesc = modelData.isHiddenDesc;
    this.isSingleScale = modelData.isSingleScale;
    this.isGrayscale = modelData.isGrayscale;
    this.isSaturation = modelData.isSaturation;
    this.type = modelData.type;
    this.context = modelData.context;
  }

  /**
   * @return {ViewSettingsPlainObject}
   */
  serialize() {
    const json = toJS(this);
    if (json.setupPreset && json.setupPreset.id) {
      json.setupPreset = json.setupPreset.id;
    }

    return json;
  }

  /**
   * @return {Object}
   */
  diff(otherObject) {
    return diff(toJS(this), toJS(otherObject));
  }
}
