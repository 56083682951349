import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import UserStore from '../../stores/UserStore';

export default function ShowOnlyForAuthorizedUserHOC(WrappedComponent, renderOnError = null) {
  @inject('userStore')
  @observer
  class ShowOnlyForAuthorizedUser extends Component {
    static propTypes = {
      userStore: PropTypes.instanceOf(UserStore).isRequired,
    };

    /**
     * @return {ReactElement}
     */
    render() {
      if (!this.props.userStore.isAuthorized) {
        return renderOnError;
      }

      return <WrappedComponent {...this.props} />;
    }
  }

  return ShowOnlyForAuthorizedUser;
}
