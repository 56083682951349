import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Waypoint} from 'react-waypoint';
import { computed } from 'mobx';
import isEqual from 'lodash/isEqual';
import styles from './ProductMedia.less';
import Product from '../../../product/entity/Product';
import SetupPreset from '../../entity/SetupPreset';
import {NETBOX_STATUS_ACTIVE, NETBOX_STATUS_DISABLED, NETBOX_STATUS_IDLE} from '../../../netbox/constants/statuses';
import {MEDIA_SIZE_MEDIUM} from '../../constants/sizeCodes';
import {HIDE_ALL_CONTROLS, Player} from '../Player';
import URLProvider from '../../../common/services/URLProvider';
import browserHistory from '../../../common/services/history';
import MediaPlaceholder from '../MediaPlaceholder/MediaPlaceholder';
import {MEDIA_SIZE_MAP} from '../../constants/sizeCodeToPx';

// todo: use at edit page
export default class ProductMedia extends Component {
  static propTypes = {
    product: PropTypes.instanceOf(Product).isRequired,
    mediaSize: PropTypes.string,
    maxScale: PropTypes.number,
    isSingleScale: PropTypes.bool,
    isGrayscale: PropTypes.bool,
    isSaturation: PropTypes.bool,
    setupPreset: PropTypes.instanceOf(SetupPreset),
    fps: PropTypes.number,
    hideOutsideViewport: PropTypes.bool,
    hasLazyLoading: PropTypes.bool,
    group: PropTypes.string,
    hqMode: PropTypes.bool,
    netBoxStatus: PropTypes.oneOf([NETBOX_STATUS_ACTIVE, NETBOX_STATUS_DISABLED, NETBOX_STATUS_IDLE]),
    hideControls: PropTypes.number,
    preventNavigation: PropTypes.bool,
  };

  static defaultProps = {
    isSingleScale: false,
    isGrayscale: false,
    isSaturation: false,
    mediaSize: MEDIA_SIZE_MEDIUM,
    setupPreset: null,
    maxScale: null,
    fps: null,
    hideOutsideViewport: true,
    hasLazyLoading: false,
    hideControls: HIDE_ALL_CONTROLS,
    group: null,
    hqMode: false,
    netBoxStatus: NETBOX_STATUS_DISABLED,
    preventNavigation: false,
  };

  state = {
    visible: false,
  };

  /**
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (isEqual(nextProps, this.props) && isEqual(nextState, this.state)) {
      return false;
    }

    if (
      nextProps.hqMode !== this.props.hqMode
      || nextProps.setupPreset !== this.props.setupPreset
      || nextProps.product !== this.props.product
      || nextProps.mediaSize !== this.props.mediaSize
      || nextProps.isSingleScale !== this.props.isSingleScale
    ) {
      return true;
    }

    if (nextProps.isGrayscale !== this.props.isGrayscale || nextProps.isSaturation !== this.props.isSaturation) {
      return false;
    }

    return true;
  }

  /**
   * @return {?Media}
   */
  @computed
  get media() {
    const { product, setupPreset } = this.props;

    return product.mediaCollection && setupPreset ? product.mediaCollection.getMediaBySetupPreset(setupPreset) : null;
  }

  @computed
  get productDetailPage() {
    if (this.props.product.isReference) {
      return null;
    }

    return URLProvider.productDetailPage(this.props.product, this.props.setupPreset);
  }

  /**
   * @return {void}
   */
  handleClick = () => {
    if (this.props.preventNavigation) {
      return;
    }

    if (!this.props.product) {
      return;
    }

    if (this.props.product.isReference) {
      return;
    }

    browserHistory.push(this.productDetailPage);
  };

  /**
   * @return {void}
   */
  handleWaypointEnter = () => {
    if (this.state.visible === false) {
      this.setState({ visible: true });
    }
  };

  /**
   * @return {void}
   */
  handleWaypointLeave = () => {
    if (this.state.visible === true) {
      this.setState({ visible: false });
    }
  };

  /**
   * @param {Media} media
   * @return {ReactElement}
   */
  renderPlaceholder(media) {
    return <MediaPlaceholder product={media.product} outline={false} href={this.productDetailPage} />;
  }

  /**
   * @param {Media} media
   * @param {number} mediaSizeInPX
   * @return {ReactElement}
   */
  renderPlayer(media, mediaSizeInPX) {
    return (
      <Player
        media={media}
        group={this.props.group}
        mediaSource={media.mainMediaSource}
        stereoSource={media.stereoMediaSource}
        product={media.product}
        mediaSize={mediaSizeInPX}
        maxScale={this.props.maxScale}
        isSingleScale={this.props.isSingleScale}
        isGrayscale={this.props.isGrayscale}
        isSaturation={this.props.isSaturation}
        hideControls={this.props.hideControls}
        bgColor={media.mainMediaSource.bgColorOverrided}
        fps={this.props.fps}
        hasLazyLoading={this.props.hasLazyLoading}
        onClick={this.props.hideControls === HIDE_ALL_CONTROLS ? this.handleClick : null}
        hqMode={this.props.hqMode}
        netBoxStatus={this.props.netBoxStatus}
      />
    );
  }

  /**
   * @param {Media} media
   * @param {string} mediaSize
   * @return {ReactElement}
   */
  renderMediaOrPlaceholder(media, mediaSize) {
    const mediaSizeInPX = MEDIA_SIZE_MAP[mediaSize];
    let mediaComponent = null;

    if (this.state.visible || !this.props.hideOutsideViewport) {
      mediaComponent = this.renderPlayer(media, mediaSizeInPX);
    }

    if (this.props.hideOutsideViewport) {
      mediaComponent = (
        <Waypoint onEnter={this.handleWaypointEnter} onLeave={this.handleWaypointLeave} fireOnRapidScroll={false}>
          <div>{mediaComponent}</div>
        </Waypoint>
      );
    }

    return (
      <div className={`${styles.productMedia} ${mediaComponent ? styles.productMediaWithoutPlaceholder : ''}`} style={{'--product-media-size': `${mediaSizeInPX}px`}}>
        {mediaComponent}
      </div>
    );
  }

  render() {
    const product = this.props.product;
    const mediaSize = this.props.mediaSize;
    const media = this.media;

    if (media && !media.isEmpty) {
      return this.renderMediaOrPlaceholder(media, mediaSize);
    }

    return <MediaPlaceholder size={MEDIA_SIZE_MAP[mediaSize]} product={product} href={this.productDetailPage} />;
  }
}
