import {computed, observable} from 'mobx';
import {setupPresetRepository} from '../repository/SetupPresetRepository';
import SetupPreset from './SetupPreset';
import MediaSource from './MediaSource';
import {TYPE_LGD_ROUGH, TYPE_ROUGH} from '../../product/constants/productTypes';
import {
  MEDIA_TYPE_3D_MODEL, MEDIA_TYPE_I3D_COMPARATIVE_REPORT_POLISH,
  MEDIA_TYPE_I3D_REPORT_POLISH,
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_POLISH,
  MEDIA_TYPE_VIDEO,
} from '../constants/mediaTypes';

const HTML_REPORT_SUB_FILE_ID = 52;
const MONO_TO_STEREO_SP = [1, 2, 3, 4, 81, 82, 88, 89, 78];

export const I3D_REPORT_REL = 'report.show';
export const DMC_REL = 'dmc.show';
export const HTML_REPORT_REL = 'html-report.show';

export default class Media {
  /** @type {number} */
  id;

  /** @type Product */
  product;

  /** @type {number} */
  mediaType;

  /** @type {SetupPreset} */
  setupPreset;

  /** @type {MediaSource[]} */
  @observable mediaSources;

  /**
   * @param {Object} modelData
   * @param {Product} product
   */
  constructor(modelData, product) {
    this.product = product;

    Object.assign(this, modelData);

    if (this.mediaSources) {
      this.mediaSources = this.mediaSources.map(mediaSourceData => new MediaSource(mediaSourceData, this))
        .filter(ms => (!ms.isEmpty || ms.isOSVCompatibleStereo()));
    }

    let sp = setupPresetRepository.findSetupPresetById(modelData.sp);

    if (!sp) {
      sp = new SetupPreset({id: modelData.sp}, false);
    }

    this.setupPreset = sp;
    if (this.setupPreset) {
      this.bgColor = setupPresetRepository.findSetupPresetBackground(this.setupPreset);
    }
  }

  /**
   * @return {?MediaSource}
   */
  @computed
  get mainMediaSource() {
    if (this.isEmpty) {
      return null;
    }

    let ms = null;

    if (this.mediaSources.length > 0) {
      ms = this.mediaSources[0];
    }

    if (this.mediaSources.length > 1) {
      for (let i = this.mediaSources.length - 1; i >= 0; i--) {
        const hdrMode = this.mediaSources[i] && this.mediaSources[i].hdrMode ? this.mediaSources[i].hdrMode : null;
        if (hdrMode && hdrMode !== 'NoHDR' && this.mediaSources[i].stereoAngle === null) {
          ms = this.mediaSources[i];
        }
      }
    }

    return ms;
  }

  /**
   * @return {?string}
   */
  @computed
  get stereoMediaSource() {
    if (this.mediaSources.length === 0) {
      return null;
    }

    if (MONO_TO_STEREO_SP.includes(this.setupPreset.id)) {
      return this.mediaSources[0].sourceExternalUrl ? this.mediaSources[0].sourceExternalUrl : null;
    }

    for (let i = this.mediaSources.length - 1; i >= 0; i--) {
      if (this.mediaSources[i].isOSVCompatibleStereo()) {
        return this.mediaSources[i].sourceExternalUrl;
      }
    }

    return null;
  }

  @computed
  get fps() {
    return this.product.entityType === TYPE_ROUGH || this.product.entityType === TYPE_LGD_ROUGH ? 30 : null;
  }

  /**
   * @return {boolean}
   */
  @computed
  get isImage() {
    return this.mediaType === MEDIA_TYPE_IMAGE;
  }

  /**
   * @return {boolean}
   */
  @computed
  get isVideo() {
    return this.mediaType === MEDIA_TYPE_VIDEO;
  }

  /**
   * @return {boolean}
   */
  @computed
  get isPolish() {
    return this.mediaType === MEDIA_TYPE_POLISH;
  }

  /**
   * @return {boolean}
   */
  @computed
  get is3DModel() {
    return this.mediaType === MEDIA_TYPE_3D_MODEL;
  }

  /**
   * @return {boolean}
   */
  @computed
  get isI3DReport() {
    return this.mediaType === MEDIA_TYPE_I3D_REPORT_POLISH;
  }

  /**
   * @return {boolean}
   */
  @computed
  get isI3DComparativeReport() {
    return this.mediaType === MEDIA_TYPE_I3D_COMPARATIVE_REPORT_POLISH;
  }

  /**
   * @return {boolean}
   */
  @computed
  get isEmpty() {
    return Media.isEmptyMediaSources(this.mediaSources);
  }

  @computed
  get hasPlotting() {
    return Boolean(this.mainMediaSource && this.mainMediaSource.plotting);
  }

  /**
   * @param {string} rel
   * @return {string}
   */
  getLink(rel) {
    if (rel === HTML_REPORT_REL) {
      const msf = this.getSubFileById(HTML_REPORT_SUB_FILE_ID);
      return msf ? msf.externalLink : '';
    }

    return this._links && this._links[rel] ? this._links[rel].href : '';
  }

  /**
   * @param {number} id
   * @return {?Object}
   */
  getSubFileById(id) {
    if (!Media.isEmptyMediaSources[this.mediaSources] && this.mainMediaSource && this.mainMediaSource.mediaSubFiles.length > 0) {
      return this.mainMediaSource.mediaSubFiles.find(item => item.mediaSubFormat.id === id);
    }

    return null;
  }

  static isEmptyMediaSources(mediaSourceCollection) {
    if (!mediaSourceCollection || mediaSourceCollection.length === 0) {
      return true;
    }

    return mediaSourceCollection.every(ms => ms.isEmpty);
  }
}
