import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './NetBoxIndicator.module.css';
import logo from './logo.svg';
import NetBoxStore from '../../stores/NetBoxStore';
import LoadingTaskList from '../LoadingTaskList/LoadingTaskList';
import Modal from '../../../common/components/Modal/Modal';
import {NETBOX_STATUS_DISABLED} from '../../constants/statuses';
import Alert from '../../../common/components/Alert/Alert';

@inject('netBoxStore')
@observer
export default class NetBoxIndicator extends Component {
  static propTypes = {
    netBoxStore: PropTypes.instanceOf(NetBoxStore).isRequired,
  };

  state = {
    isModalOpened: false,
  };

  toggleModal = () => {
    if (!this.props.netBoxStore.status) {
      return;
    }

    this.setState(
      prevState => ({isModalOpened: !prevState.isModalOpened}),
    );
  };

  render() {
    if (!this.props.netBoxStore.isAccessible) {
      return null;
    }

    return (
      <>
        <Modal title="NetBox Control Panel" onClose={this.toggleModal} isShowed={this.props.netBoxStore.status !== NETBOX_STATUS_DISABLED && this.state.isModalOpened}>
          <div className={styles.alert}>
            {!this.props.netBoxStore.isServiceWorkerActivated && (
              <Alert>
                To serve content from the cache and intercept network requests Service Worker must be enabled. Check your browser settings
              </Alert>
            )}
          </div>
          <LoadingTaskList />
        </Modal>
        <img src={logo} height={32} width={32} alt="NetBox" className={styles.indicator} onClick={this.toggleModal} />
      </>
    );
  }
}
