import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Separator.module.css';

export default class Separator extends Component {
  static propTypes = {
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    return <hr className={`${styles.separator} ${this.props.className}`} />;
  }
}
