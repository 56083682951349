import { computed } from 'mobx';
import {mediaSubFormatRepository} from '../repository/MediaSubFormatRepository';
import {setupPresetRepository} from '../repository/SetupPresetRepository';
import {
  IMAGE_SUB_FORMAT_160,
  IMAGE_SUB_FORMAT_BY_SIZE,
  VIDEO_SUB_FORMAT_FSET_160,
  VIDEO_SUB_FORMAT_FSET_BY_SIZE,
} from './MediaSubFormat';

/**
 * @typedef {Object} MediaSubFile
 * @property {number} id
 * @property {MediaSubFormat} mediaSubFormat
 * @property {string} externalLink
 * @property {number} resolutionX
 * @property {number} resolutionY
 * @property {number} firstFrame
 */

export default class MediaSource {
  /** @type {string} */
  id;

  /** @type {Media} */
  media;

  /** @type {MediaSubFile[]} */
  mediaSubFiles;

  /** @type {number} */
  scaleRatio;

  /** @type {string} */
  bgColor;

  /** @type {?string} */
  sourceExternalUrl;

  /** @type {?string} */
  hdrMode;

  /** @type {?number} */
  stereoAngle;

  constructor(modelData, media) {
    Object.assign(this, modelData);

    if (this.mediaSubFiles) {
      this.mediaSubFiles.forEach((msf) => {
        msf.mediaSubFormat = mediaSubFormatRepository.findMediaSubFormatById(msf.mediaSubFormat);
      });
    }

    this.media = media;
  }

  /**
   * @return {boolean}
   */
  @computed
  get isEmpty() {
    return !this.mediaSubFiles || this.mediaSubFiles.length === 0;
  }

  /**
   * @return {string}
   */
  @computed
  get bgColorOverrided() {
    if (this.media && this.media.setupPreset && this.media.setupPreset.isStandard === false) {
      return this.bgColor;
    }

    const bg = setupPresetRepository.findSetupPresetBackground(this.media.setupPreset);

    if (!bg) {
      return this.bgColor;
    }

    return bg;
  }

  /**
   * @returns {number}
   */
  @computed
  get relScale() {
    const msf = this.media.isVideo ? this.findSubFile(VIDEO_SUB_FORMAT_FSET_160) : this.findSubFile(IMAGE_SUB_FORMAT_160);

    return this.getRelScale(msf);
  }

  /**
   * @param {MediaSubFile} msf
   * @returns {number}
   */
  getRelScale(msf) {
    return this.scaleRatio / msf.resolutionY;
  }

  /**
   * @param {number} mediaSize
   * @return {?MediaSubFile}
   */
  getSubfileByMediaSize(mediaSize) {
    /** @type {?number} */
    let subformat = null;

    if (this.media.isImage) {
      subformat = IMAGE_SUB_FORMAT_BY_SIZE[mediaSize];
    }

    if (this.media.isVideo) {
      subformat = VIDEO_SUB_FORMAT_FSET_BY_SIZE[mediaSize];
    }

    if (!subformat) {
      return null;
    }

    return this.findSubFile(subformat);
  }

  /**
   * @param {number} mediaSize
   * @return {?MediaSubFile}
   */
  getMaxSubFileByMediaSize(mediaSize) {
    const sizesMap = this.media.isImage ? IMAGE_SUB_FORMAT_BY_SIZE : VIDEO_SUB_FORMAT_FSET_BY_SIZE;
    const possibleSizes = Object.keys(sizesMap);

    let minSize = possibleSizes[0];

    for (let i = 1; i < possibleSizes.length; i++) {
      const isSubFileExist = Boolean(this.findSubFile(sizesMap[possibleSizes[i]]));

      if (isSubFileExist && minSize < possibleSizes[i] && possibleSizes[i] <= mediaSize) {
        minSize = possibleSizes[i];
      }
    }

    const subFormat = sizesMap[minSize];

    if (!subFormat) {
      return null;
    }

    return this.findSubFile(subFormat);
  }

  /**
   * @param {number} mediaSubFormat
   * @returns {?MediaSubFile}
   */
  findSubFile(mediaSubFormat) {
    return this.mediaSubFiles.find(sf => sf.mediaSubFormat && sf.mediaSubFormat.id === mediaSubFormat);
  }

  /**
   * @returns {boolean}
   */
  isOSVCompatibleStereo() {
    // Reasonable Stereo Angles for AutoStereoscopic displays are: 2.5 (legacy); 3 (mono + right 6-degree channel); 4 (optimal)
    return this.hdrMode === 'EF' && this.stereoAngle !== null && this.stereoAngle <= 4 && Boolean(this.sourceExternalUrl);
  }
}
