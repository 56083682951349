import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import ReactSelect, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import cls from 'classnames';
import Icon from '@/common/components/Icon/Icon';
import './FancySelect.less';
const BASE_CSS_CLASS = 'fancy-select';
const SingleValueInitial = components.SingleValue;
const OptionInitial = components.Option;
const MultiValueRemoveInitial = components.MultiValueRemove;
function FancySelect(props) {
    const { isCreatable = false, isSearchable = false, isClearable = false, loadOptions, loading, className, renderPlaceholder, renderOption, renderMultiValueOption, hideArrow, theme = 'dark', value, options, onChange, menuPosition = 'fixed', onKeyDown, noOptionsMessage, ...rest } = props;
    const components = {
        SingleValue: (props) => {
            const { data } = props;
            return (_jsx(SingleValueInitial, { ...props, children: _jsxs("div", { className: "fancy-select__option-title", children: [_jsx("div", { className: "fancy-select__option-icon", children: typeof data.icon === 'string' ? _jsx(Icon, { name: data.icon }) : data.icon }), _jsx("div", { className: "fancy-select__option-label", children: data.label })] }) }));
        },
        MultiValueRemove: (props) => (_jsx(MultiValueRemoveInitial, { ...props, children: _jsx(Icon, { name: "close" }) })),
        Option: (props) => {
            const { data, className, ...rest } = props;
            return (_jsx(OptionInitial, { data: data, ...rest, className: cls(className, data.className), children: renderOption?.(props) ?? (_jsxs(Fragment, { children: [_jsxs("div", { className: "fancy-select__option-title", children: [_jsx("div", { className: "fancy-select__option-icon", children: typeof data.icon === 'string' ? _jsx(Icon, { name: data.icon }) : data.icon }), _jsx("div", { className: "fancy-select__option-label", children: data.label })] }), data.description && _jsx("div", { className: "fancy-select__option-description", children: data.description })] })) }));
        },
    };
    if (renderPlaceholder) {
        components.Placeholder = renderPlaceholder;
    }
    if (renderMultiValueOption) {
        components.MultiValueLabel = renderMultiValueOption;
    }
    function getSelectedOption() {
        /*
         * We can pass value as primitive, like "string" or "number", or as object of type "FancySelectOption"
         * while "react-select" expects only structure, so below we get passed value and search for this value within "options"
         * */
        if (value === null || typeof value === 'undefined') {
            return null;
        }
        if (Array.isArray(value)) {
            return value;
        }
        if (typeof value === 'string' || typeof value === 'number') {
            return (options?.find((option) => `${option.value}` === `${value}`) ?? null);
        }
        return (options?.find((option) => `${option.value}` === `${value.value}`) ??
            null);
    }
    function getSearchableState() {
        /*
         * By default, "react-select" allows to type text in field, it will filter data in dropdown
         *
         * Below we detect ability to type dynamically - if select is creatable or
         * "inputValue" and "onInputChange" are passed - user will be able to type anything in field
         * */
        const { inputValue, onInputChange } = rest;
        if (typeof inputValue !== 'undefined' && typeof onInputChange !== 'undefined') {
            return true;
        }
        if (isCreatable) {
            return true;
        }
        return isSearchable;
    }
    const componentProps = {
        value: getSelectedOption(),
        onChange: (value, meta) => {
            if (!value) {
                return onChange(value, meta);
            }
            if (Array.isArray(value)) {
                return onChange(value, meta);
            }
            return onChange([value], meta);
        },
        onKeyDown: (e) => {
            e.stopPropagation();
            onKeyDown?.(e);
        },
        options,
        isClearable,
        isSearchable: getSearchableState(),
        components,
        loading,
        classNamePrefix: BASE_CSS_CLASS,
        className: cls(BASE_CSS_CLASS, {
            [`${BASE_CSS_CLASS}--loading`]: loading,
            [`${BASE_CSS_CLASS}--no-arrow`]: hideArrow,
        }, `${BASE_CSS_CLASS}--${theme}`, className),
        menuPosition,
        noOptionsMessage: () => noOptionsMessage ?? 'No options',
        ...rest,
    };
    if (loadOptions && isCreatable) {
        return _jsx(AsyncCreatableSelect, { loadOptions: loadOptions, ...componentProps });
    }
    if (loadOptions) {
        return _jsx(AsyncSelect, { loadOptions: loadOptions, ...componentProps });
    }
    if (isCreatable) {
        return _jsx(CreatableSelect, { ...componentProps });
    }
    return _jsx(ReactSelect, { ...componentProps });
}
export default FancySelect;
