import React, {Component} from 'react';
import {observer} from 'mobx-react';
import styles from './DashboardToolbar.module.css';

@observer
export default class DashboardToolbar extends Component {
  // static propTypes = {
  //   billingState: PropTypes.instanceOf(BillingState).isRequired,
  // };

  render() {
    return (
      <div className={styles.dashboadToolbarPane}>
        DiBox 2.0 Account
        Billing Dashboard
      </div>
    );
  }
}
