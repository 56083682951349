import debounce from 'lodash/debounce';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import styles from './App.less';
import DragDropHTML5Context from '../../components/DragDropHTML5Context/DragDropHTML5Context';
import AppStore from '../../stores/AppStore';
import ConfigurationStore from '../../stores/ConfigurationStore';
import UserStore from '../../../user/stores/UserStore';
import Auth from '@/user/services/auth/Auth';
import Header from '../../components/Header/Header';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import ErrorChecker from '../../components/ErrorChecker/ErrorChecker';
import {E2E_TESTS_ENABLED} from '@/test/constants/e2e';
import ContactsStore from '@/contacts/stores/ContactsStore';

@inject('userStore', 'appStore', 'configurationStore', 'contactsStore')
@DragDropHTML5Context
@withRouter
@observer
export default class AppPage extends Component {
  static propTypes = {
    appStore: PropTypes.instanceOf(AppStore).isRequired,
    configurationStore: PropTypes.instanceOf(ConfigurationStore).isRequired,
    userStore: PropTypes.instanceOf(UserStore).isRequired,
    contactsStore: PropTypes.instanceOf(ContactsStore).isRequired,
    children: PropTypes.node.isRequired,
  };

  componentDidMount() {
    this.setScreenWidth(window.innerWidth);
    window.addEventListener('resize', this.setScreenWidth);
    Auth.showNoticeAboutSessionExpirationIfNeeded();
    Promise.all([this.props.userStore.fetch()]).then(() => {
      if (this.props.userStore.isAuthorized) {
        this.props.appStore.enableCloudSettingsStorage();
        this.props.contactsStore.initContactsStore();

        this.props.userStore.fetchCompanyUsers()
      }
    });

    if (E2E_TESTS_ENABLED) {
      document.body.classList.add(styles.e2eMode);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setScreenWidth);
  }

  setScreenWidth = debounce(() => {
    if (document.fullscreenElement && document.fullscreenEnabled) {
      return;
    }

    this.props.appStore.setScreenWidth(window.innerWidth);
  }, 100, { leading: true });

  render() {
    if (!this.props.configurationStore.isConfigurationLoaded || !this.props.userStore.user) {
      return null;
    }

    return (
      <div className={styles.app}>
        <Header />
        <ErrorBoundary>
          <ErrorChecker>
            {this.props.children}
          </ErrorChecker>
        </ErrorBoundary>
      </div>
    );
  }
}
