var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable } from 'mobx';
import qs from 'qs';
import BillingAPI from '../api/BillingAPI';
import { BillingAccount } from '../entities/billingAccount';
import { DashboardStore } from './DashboardStore';
import { TransactionsStore } from './TransactionsStore';
class BillingStore {
    dashboardStore;
    transactionsStore;
    billingAccounts = [];
    activeBillingAccount;
    billingAPI;
    constructor() {
        this.billingAPI = new BillingAPI();
        /*
         * Idea to split Dashboard and Transactions stores is:
         * when user enters billing page, we have global store (BillingStore), this store keeps values of accounts,
         * then, dependenly on page, we have specific store, this page's store keeps values of filter and specific methods,
         * this is good solution for us, because when we switch between pages we need to remember filter values and data on each page,
         * so, spliting stores helps us to keep data when we switch between pages and accounts
         * */
        this.dashboardStore = new DashboardStore();
        this.transactionsStore = new TransactionsStore();
    }
    fetchBillingAccount = () => {
        this.billingAPI.getBillingAccount().then((res) => {
            if (!res.content) {
                return;
            }
            this.billingAccounts = res.content.map((account) => new BillingAccount(account));
            const query = qs.parse(window.location.search, {
                ignoreQueryPrefix: true,
            });
            let activeBillingAccount = res.content[0];
            /*
             * If we pass `filter.account` in search query and value exists in billing account response
             * we will use this account
             *
             * We need it to save context of Billing Account when reload page
             *
             * `query.account` - for dashboard
             * `query.filter.account` - for transactions
             * */
            const account = query.account ?? query?.filter?.account;
            if (account && !Number.isNaN(+account)) {
                const billingAccount = res.content.find(({ id }) => id === +account);
                if (billingAccount) {
                    activeBillingAccount = billingAccount;
                }
            }
            this.activeBillingAccount = new BillingAccount(activeBillingAccount);
        });
    };
    setBillingAccount = (account) => {
        this.activeBillingAccount = account;
    };
}
__decorate([
    observable
], BillingStore.prototype, "billingAccounts", void 0);
__decorate([
    observable
], BillingStore.prototype, "activeBillingAccount", void 0);
export const billingStore = new BillingStore();
export default BillingStore;
