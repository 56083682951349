import { observable } from 'mobx';
import {setupPresetRepository} from './SetupPresetRepository';
import SetupPresetGroup from '../entity/SetupPresetGroup';
import {ALL_PRODUCT_TYPES} from '../../product/constants/productTypes';

class SetupPresetGroupRepository {
  /**
   * @type {SetupPresetGroup[]}
   */
  @observable
  setupPresetGroups;

  /**
   * @type {Object.<string, number[]>}
   */
  spgIdsMapSortedByProductType = {};

  /**
   * @type {Object.<string, SetupPresetGroup[]>}
   */
  spgsMapSortedByProductType = {};

  /**
   * @param {Object[]} setupPresetGroupsData
   * @return {*}
   */
  initSetupPresetGroups(setupPresetGroupsData) {
    const sortedArray = setupPresetGroupsData.sort((a, b) => (a.position > b.position ? 1 : -1));

    this.setupPresetGroups = sortedArray.map((spg) => {
      const sps = spg.sp.map(spId => setupPresetRepository.findSetupPresetById(spId));
      return new SetupPresetGroup({ ...spg, sp: sps });
    });
  }

  /**
   * @param {Object.<string, {spgList: number[]}>} sorts
   */
  initSorts(sorts) {
    ALL_PRODUCT_TYPES.forEach((pt) => {
      if (sorts[pt]) {
        this.spgIdsMapSortedByProductType[pt] = sorts[pt].spgList;
      }
    });
  }

  /**
   * @param {string} productType
   * @return {SetupPresetGroup[]}
   */
  getSortedSpgsByProductType(productType) {
    if (!Object.keys(this.spgsMapSortedByProductType).includes(productType)) {
      this.spgsMapSortedByProductType[productType] = this.spgIdsMapSortedByProductType[productType]
        .map(spgId => this.findSetupPresetGroupById(spgId))
        .filter(spg => Boolean(spg));
    }

    return this.spgsMapSortedByProductType[productType];
  }

  /**
   * @param {number} id
   * @return {?SetupPresetGroup}
   */
  findSetupPresetGroupById(id) {
    if (!this.setupPresetGroups) {
      return null;
    }

    return this.setupPresetGroups.find(spg => spg.id === id);
  }
}

export const setupPresetGroupRepository = new SetupPresetGroupRepository();
