import {computed, observable} from 'mobx';
import SetupPreset, {I_MUNSELL, I_MUNSELL_CLOSEUP} from '../entity/SetupPreset';

const BACKGROUND_COLOR_DEFAULT = '#000000';
const BACKGROUND_COLOR_WHITE = '#ffffff';
const BACKGROUND_COLOR_AUTO = 'auto';

export default class SetupPresetRepository {
  /**
   * @type {SetupPreset[]}
   */
  @observable
  setupPresets;

  /**
   * @type {number[]}
   */
  @observable
  setupPresetOrder;

  /**
   * @return {?SetupPreset}
   */
  @computed
  get defaultSetupPreset() {
    if (!this.setupPresets) {
      return null;
    }

    const spOrder = this.getSetupPresetOrder();

    for (const spId of spOrder) {
      const sp = this.findSetupPresetById(spId);
      if (sp) {
        return sp;
      }
    }

    return null;
  }

  initSetupPresets(setupPresetsData) {
    this.setupPresets = setupPresetsData.map(setupPresetsDataItem => new SetupPreset(setupPresetsDataItem, true));
  }

  initSetupPresetOrder(setupPresetOrderData) {
    this.setupPresetOrder = setupPresetOrderData;
  }

  /**
   * @param {number} id
   * @returns {?SetupPreset}
   */
  findSetupPresetById(id) {
    if (!this.setupPresets) {
      return null;
    }

    return this.setupPresets.find(sp => sp.id === id);
  }

  /**
   * @param {SetupPreset} setupPreset
   * @returns {?string}
   */
  findSetupPresetBackground(setupPreset) {
    if (!setupPreset) {
      return BACKGROUND_COLOR_DEFAULT;
    }

    if (setupPreset.id === I_MUNSELL || setupPreset.id === I_MUNSELL_CLOSEUP) {
      return BACKGROUND_COLOR_WHITE;
    }

    const bgColor = setupPreset.bg;

    if (!bgColor) {
      return BACKGROUND_COLOR_DEFAULT;
    }

    if (bgColor === BACKGROUND_COLOR_AUTO) {
      return null;
    }

    return bgColor;
  }

  /**
   * @returns {number[]}
   */
  getSetupPresetOrder() {
    if (!this.setupPresetOrder) {
      return [];
    }

    return this.setupPresetOrder;
  }
}

export const setupPresetRepository = new SetupPresetRepository();
