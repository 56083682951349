import parseISO from 'date-fns/parseISO';

/**
 * @see https://octonus-teams.com/jira/browse/CUT-5674
 * @param {Query} query
 * @return {Query}
 */
export default (query) => {
  const filters = query.queryStringParams.filters;

  if (filters.createdAt && filters.createdAt.to) {
    const date = parseISO(filters.createdAt.to, 'yyyy-MM-dd');
    date.setHours(23, 59);
    filters.createdAt.to = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
  }

  return query;
};
