import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import isNil from 'lodash/isNil';
import React, {Fragment} from 'react';
import {CONTEXT_B2B, CONTEXT_LISTING} from '../../common/constants/context';
import {
  TYPE_CUSTOM, TYPE_MEDIA, TYPE_METRIC, TYPE_SCALAR,
} from './fieldTypes';
import ProductLink from '../../product/components/ProductLink/ProductLink';
import ShapeWithPatents from '../../product/components/ShapeWithPatents/ShapeWithPatents';
import SpreadFormatter from '../../common/helpers/SpreadFormatter';
import {
  SETUP_PRESET_GROUP_ASET,
  SETUP_PRESET_GROUP_DARKFIELD,
  SETUP_PRESET_GROUP_FIRE_DIBOX,
  SETUP_PRESET_GROUP_FLUOR_TABLE,
  SETUP_PRESET_GROUP_FULL_ROTATION,
  SETUP_PRESET_GROUP_OFFICE,
  SETUP_PRESET_GROUP_PAV_COLOR_LAB, SPG_ORDER_SOLUTION_LIST_DZ, SPG_ORDER_SOLUTION_LIST_FANCY_COLOR,
} from '../../media/entity/SetupPresetGroup';
import comboGradient from '../components/CatalogContent/ProductListContainer/ProductListCombo/ProductComboItem/comboGradient';
import {dictionaryRepository} from '../../common/repositories/DictionaryRepository';
import ProductInfo from '../components/CatalogContent/ProductListContainer/ProductListCombo/ProductInfo/ProductInfo';
import priceFormatter from '../../common/helpers/priceFormatter';
import {scoringRepository} from '../../metric/repositories/ScoringRepository';
import {
  CODE_BRIGHTNESS,
  CODE_FIRE,
  CODE_INTEGRAL,
  CODE_SCINTILLATION, CODE_SPREAD,
  CODE_SYMMETRY,
} from '../../metric/constants/codes';
import OptMetricBar from '../../metric/components/OptMetricBar/OptMetricBar.tsx';
import MetricScaleContainer, { SIZE_MEDIUM, SIZE_XSMALL } from '../../metric/components/MetricScaleContainer/MetricScaleContainer';
import MetricMap from '../../metric/components/MetricMap/MetricMap';
import {FIRE} from '../../media/entity/SetupPreset';
import Diamond from '../../product/entity/Diamond';
import FluMetricBar from '../../metric/components/FluMetricBar/FluMetricBar.tsx';
import SpreadMetricBar from '../../metric/components/SpreadMetricBar/SpreadMetricBar.tsx';
import HighlightedHPOColor from '../../metric/components/HighlightedHPOColor/HighlightedHPOColor';
import Certification from '../../common/components/Certification/Certification';
import {
  PRODUCT_EDIT,
  securityManager,
  SHARE_ACCESS,
  SHARE_PRODUCT,
  VIEW_PRODUCT_STATUS
} from '../../common/services/SecurityManager';
import ProductLabels from '../../product/components/ProductLabels/ProductLabels';
import {
  CUSTOM_GRADE_BRILLIANCE,
  CUSTOM_GRADE_COLOR, CUSTOM_GRADE_CUT_PERFORMANCE,
  CUSTOM_GRADE_GIRDLE_BEAUTY,
} from '../../customGrading/constants/customGradeCodes';
import ProductMedia from '../../media/components/ProductMedia/ProductMedia';
import Rough from '../../product/entity/Rough';
import ProjectLink from '../../hpo/components/ProjectLink/ProjectLink';
import {TYPE_LGD_ROUGH, TYPE_ROUGH} from '../../product/constants/productTypes';
import {setupPresetGroupRepository} from '../../media/repository/SetupPresetGroupRepository';
import SolutionLink from '../../hpo/components/SolutionLink/SolutionLink';
import { NextPayment } from '../../common/components/NextPayment/NextPayment.tsx';
import { ExpensesProgress } from '../../common/components/ExpensesProgress/ExpensesProgress.tsx';
import { METRIC_DATA_DECORATOR_FANCY, METRIC_DATA_DECORATOR_ROUND } from '../../metric/constants/dataDecorators';
import AccessStatus from '@/security/components/AccessStatus/AccessStatus';
import ACLStatus from '@/security/components/ACLStatus/ACLStatus';
import {SIZE_LARGE} from '@/common/components/Label/Label';
import {getScanWeight} from '@/catalog/helpers/getScanWeight';

const emptyDecorator = v => v;

/**
 * @typedef {Object} ScalarField
 * @property {string} label - Short human-readable label
 * @property {string} fullLabel - Full human-readable label
 * @property {string} key - Unique key (for fields identification and iteration)
 * @property {string} type - Indicates how this field should be processed (as media or simple scalar value and so on)
 * @property {?string} icon - Class of related icon
 * @property {?number} spGroupId - Field could be connected with any Setup Preset Group
 * @property {function(Product, ProductViewSettings=, number=, boolean=): *} getter - Gets output for this field (human-readable representation)
 * @property {function(Product): *} getterPlain - Get simple plain value for this field (for example, sorting / filtering)
 * @property {function(*, Product, ?Object): *} decorator - Decorates value
 * @property {?[number]} contexts - Indicates whether the field should be hidden in some context
 * @property {?Boolean} isCustomGrade - Indicates field is custom grade
 */

const ALL_CONTEXTS = [CONTEXT_B2B, CONTEXT_LISTING];
const ONLY_LISTING_CONTEXTS = [CONTEXT_LISTING];

export const FIELD_CREATED_AT = {
  label: 'Added',
  fullLabel: 'Added',
  key: 'createdAt',
  type: TYPE_SCALAR,
  getter: function (p) {
    return p.createdAt ? format(parseISO(p.createdAt), 'dd.MM.yyyy') : null;
  },
  getterPlain: function (p) {
    return p.createdAt || null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_SELLER = {
  label: 'Seller',
  fullLabel: 'Seller',
  key: 'b2b',
  type: TYPE_SCALAR,
  getter: function (p) {
    const titles = [];

    if (p.b2b && p.b2b.title) {
      titles.push(p.b2b.title);
    }

    if (p.seller && p.seller.title && !titles.includes(p.seller.title)) {
      titles.push(p.seller.title);
    }

    if (titles.length === 0) {
      return '';
    }

    return titles.join(', ');
  },
  getterPlain: function (p) {
    return p.seller && p.seller.title ? p.seller : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ONLY_LISTING_CONTEXTS, // hide this field on StockManagement Mode
};

export const FIELD_SKU = {
  label: 'SKU',
  fullLabel: 'Product SKU',
  key: 'sku',
  type: TYPE_SCALAR,
  getter: function (p) {
    return this.getterPlain(p);
  },
  getterPlain: function (p) {
    return p.b2bSid || null;
  },
  decorator: (v, p) => <ProductLink product={p} text={p.b2bSid} />,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_PROJECT_LINK = {
  label: 'Project',
  fullLabel: 'Project',
  key: 'projectLink',
  type: TYPE_SCALAR,
  getter: function (p) {
    return this.decorator(null, p);
  },
  getterPlain: function (p) {
    return (p.project && p.project.title) || null;
  },
  decorator: (v, p) => p.project && <ProjectLink project={p.project} title={p.project.title} />,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_SOLUTION = {
  label: 'Solution',
  fullLabel: 'Solution',
  key: 'solutionLink',
  type: TYPE_SCALAR,
  getter: function (p) {
    return this.decorator(null, p);
  },
  getterPlain: function (p) {
    return (p.solution && p.project && p.project.name) || null;
  },
  decorator: (v, p) => p.project && p.solution && <SolutionLink project={p.project} solution={p.solution} title={p.b2bSid} />,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_SHAPE = {
  label: 'Shape',
  fullLabel: 'Cut Shape',
  key: 'cutShape',
  type: TYPE_SCALAR,
  icon: 'metrics-shape',
  getter: function (p) {
    return p.cutShapeTitle ? p.cutShapeTitle : null;
  },
  getterPlain: function (p) {
    return p.cutShape || null;
  },
  decorator: (v, p) => (v ? <ShapeWithPatents cutShape={v} product={p} /> : null),
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_CARAT_WEIGHT = {
  label: 'Weight',
  fullLabel: 'Carat Weight',
  key: 'carat',
  type: TYPE_SCALAR,
  icon: 'metrics-carat',
  getter: function (p) {
    return p.carat ? `${getScanWeight(p.carat)}ct` : null;
  },
  getterPlain: function (p) {
    return p.carat ? getScanWeight(p.carat) : null;
  },
  decorator: emptyDecorator,
  iteratee: p => p.carat ? p.carat : null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_SPREAD_CT = {
  label: 'Spread',
  fullLabel: 'Spread, ct',
  key: 'spreadCt',
  type: TYPE_SCALAR,
  icon: 'metrics-spread',
  /**
   * @param {Diamond} p
   * @return {?number}
   */
  getter: function (p) {
    return p.spreadMetric ? SpreadFormatter.format(p.spreadMetric.ct, 2, 'ct') : null;
  },
  /**
   * @param {Diamond} p
   * @return {?number}
   */
  getterPlain: function (p) {
    return p.spreadMetric ? p.spreadMetric.ct : null;
  },
  decorator: emptyDecorator,
  iteratee: p => (p.spreadMetric ? p.spreadMetric.ct : null),
  contexts: ALL_CONTEXTS,
};

export const FIELD_SPREAD_PC = {
  label: 'Spread',
  fullLabel: 'Spread, %',
  key: 'spreadPc',
  type: TYPE_SCALAR,
  icon: 'metrics-spread',
  /**
   * @param {Diamond} p
   * @return {?number}
   */
  getter: function (p) {
    return p.spreadMetric ? SpreadFormatter.format(p.spreadMetric.pc, 1, '%') : null;
  },
  /**
   * @param {Diamond} p
   * @return {?number}
   */
  getterPlain: function (p) {
    return p.spreadMetric ? p.spreadMetric.pc : null;
  },
  decorator: emptyDecorator,
  iteratee: function (p) {
    return p.spreadMetric.pc;
  },
  contexts: ALL_CONTEXTS,
};

export const FIELD_GIRDLE = {
  label: 'Girdle',
  fullLabel: 'Girdle Grade',
  key: 'girdle',
  type: TYPE_SCALAR,
  icon: 'metrics-girdle',
  getter: function (p, short = false) {
    if (!p.girdleThicknessGrade) {
      return null;
    }

    let resultName = short ? p.girdleThicknessGrade.short : p.girdleThicknessGrade.title;

    if (p.girdleThicknessMax) {
      resultName += ` – ${short ? p.girdleThicknessMax.short : p.girdleThicknessMax.title}`;
    }

    return resultName;
  },
  getterPlain: function (p) {
    return !isNil(p.girdleThicknessGrade) ? p.girdleThicknessGrade.id : null;
  },
  decorator: emptyDecorator,
  iteratee: function (p) {
    return p.girdleThicknessGrade ? p.girdleThicknessGrade.position * -1 : null;
  },
  contexts: ALL_CONTEXTS,
};

export const FIELD_COLOR = {
  label: 'Color',
  fullLabel: 'Color',
  key: 'color',
  type: TYPE_SCALAR,
  spGroupId: SETUP_PRESET_GROUP_PAV_COLOR_LAB,
  icon: 'metrics-color',
  getter: function (p, short = false) {
    return p.color ? (short ? p.color.short : p.color.title) : null;
  },
  getterPlain: function (p) {
    return p.color ? p.color.position : null;
  },
  decorator: emptyDecorator,
  iteratee: function (p) {
    return p.color ? p.color.position * -1 : null;
  },
  contexts: ALL_CONTEXTS,
};

export const FIELD_CLARITY = {
  label: 'Clarity',
  fullLabel: 'Clarity',
  key: 'clarity',
  type: TYPE_SCALAR,
  spGroupId: SETUP_PRESET_GROUP_DARKFIELD,
  icon: 'metrics-clarity',
  getter: function (p, short = false) {
    return p.clarity ? (short ? p.clarity.short : p.clarity.title) : null;
  },
  getterPlain: function (p) {
    return p.clarity ? p.clarity.position : null;
  },
  decorator: emptyDecorator,
  iteratee: function (p) {
    return p.clarity ? p.clarity.position * -1 : null;
  },
  contexts: ALL_CONTEXTS,
};

export const FIELD_CUT = {
  label: 'Cut',
  fullLabel: 'Cut Quality',
  key: 'cutQuality',
  type: TYPE_SCALAR,
  icon: 'metrics-cut',
  getter: function (p, short = false) {
    return p.cutQuality ? (short ? p.cutQuality.short : p.cutQuality.title) : null;
  },
  getterPlain: function (p) {
    return p.cutQuality ? p.cutQuality.position : null;
  },
  decorator: emptyDecorator,
  iteratee: function (p) {
    return p.cutQuality ? p.cutQuality.position * -1 : null;
  },
  contexts: ALL_CONTEXTS,
};

export const FIELD_COLOR_GRADE = {
  label: 'Color',
  fullLabel: 'Color',
  key: 'fancyColor',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: 'metrics-color',
  getter: function (p) {
    return p.colorGrade;
  },
  getterPlain: function (p) {
    if (p.color) {
      return p.color.position * -1;
    }

    if (p.colorGrade) {
      return p.colorGrade;
    }

    return null;
  },
  decorator: emptyDecorator,
  iteratee: function (p) {
    return p.color ? p.color.position * -1 : null;
  },
  contexts: ALL_CONTEXTS,
};

export const FIELD_COLOR_GRADE_DESC_HUE = {
  label: 'Color',
  fullLabel: 'Color Hue',
  key: 'fancyColorDescHue',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: 'metrics-color',
  getter: function (p) {
    return p.fancyColorDescHue ? p.fancyColorDescHue.title : null;
  },
  getterPlain: function (p) {
    return p.fancyColorDescHue || null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_SYMMETRY = {
  label: 'Symmetry',
  fullLabel: 'Symmetry',
  key: 'symmetry',
  type: TYPE_SCALAR,
  icon: 'metrics-symmetry',
  getter: function (p, short = false) {
    return p.symmetry ? (short ? p.symmetry.short : p.symmetry.title) : null;
  },
  getterPlain: function (p) {
    return p.symmetry ? p.symmetry.position : null;
  },
  decorator: emptyDecorator,
  iteratee: function (p) {
    return p.symmetry ? p.symmetry.position * -1 : null;
  },
  contexts: ALL_CONTEXTS,
};

export const FIELD_POLISH = {
  label: 'Polish',
  fullLabel: 'Polish',
  key: 'polish',
  type: TYPE_SCALAR,
  icon: 'metrics-polish',
  getter: function (p, short = false) {
    if (!p.polish) {
      return null;
    }

    return short ? p.polish.short : p.polish.title;
  },
  getterPlain: function (p) {
    return p.polish ? p.polish.position : null;
  },
  decorator: emptyDecorator,
  iteratee: function (p) {
    return p.polish ? p.polish.position * -1 : null;
  },
  contexts: ALL_CONTEXTS,
};

export const FIELD_FLUOR_STRENGTH = {
  label: 'Fluorescence',
  fullLabel: 'Fluorescence',
  key: 'fluorescenceStrength',
  type: TYPE_SCALAR,
  icon: 'metrics-fluor',
  getter: function (p, short = false) {
    if (!p.fluorescenceStrength) {
      return null;
    }

    const result = [
      short ? p.fluorescenceStrength.short : p.fluorescenceStrength.title,
    ];

    if (p.fluorescenceColor) {
      result.push(short ? p.fluorescenceColor.short : p.fluorescenceColor.title);
    }

    return result.join(' ');
  },
  getterPlain: function (p) {
    return p.fluorescenceStrength ? p.fluorescenceStrength.position : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_INTENSITY = {
  label: 'Intensity',
  fullLabel: 'Color Grade',
  key: 'fancyGrade',
  type: TYPE_SCALAR,
  getter: function (p, short = false) {
    if (!p.fancyGrade) {
      return null;
    }

    return short ? p.fancyGrade.short : p.fancyGrade.title;
  },
  getterPlain: function (p) {
    if (p.fancyGrade) {
      return p.fancyGrade.position;
    }

    return null;
  },
  decorator: (v, p) => (v ? (
    <span
      style={{
        color: comboGradient.rgbAt(dictionaryRepository.getFancyGradePercentGrade(p.fancyGrade.id) / 100),
      }}
    >
      {v}
    </span>
  ) : null),
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_SHORT_VIEW = {
  label: 'Short View',
  fullLabel: 'Short View',
  key: 'shortView',
  type: TYPE_SCALAR,
  getter: function (p) {
    return this.getterPlain(p);
  },
  getterPlain: function (p) {
    return p.shortView;
  },
  decorator: (v, p) => <ProductInfo product={p} showB2BSid showTitle showPrice />,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_PRICE = {
  label: 'Price',
  fullLabel: 'Price',
  key: 'price',
  type: TYPE_SCALAR,
  spGroupId: null,
  getter: function (p) {
    return p.price ? priceFormatter.format(p.price) : null;
  },
  getterPlain: function (p) {
    return p.price ? p.price : null;
  },
  decorator: emptyDecorator,
  iteratee: function (p) {
    return p.price ? p.price * -1 : null;
  },
  contexts: ALL_CONTEXTS,
};

export const FIELD_PRICE_CT = {
  label: 'Price ct',
  fullLabel: 'Price Per Carat',
  key: 'priceCt',
  type: TYPE_SCALAR,
  getter: function (p) {
    return p.priceCt ? `${priceFormatter.format(p.priceCt)}/ct` : null;
  },
  getterPlain: function (p) {
    return p.priceCt ? p.priceCt : null;
  },
  decorator: emptyDecorator,
  iteratee: function (p) {
    return p.priceCt ? p.priceCt * -1 : null;
  },
  contexts: ALL_CONTEXTS,
};

export const FIELD_BRILLIANCE = {
  key: 'brightness.val',
  label: 'Brilliance',
  fullLabel: 'Brilliance',
  type: TYPE_METRIC,
  spGroupId: SETUP_PRESET_GROUP_OFFICE,
  icon: 'metrics-brilliance',
  getter: function (p) {
    return p.brightnessMetric ? p.brightnessMetric.hr : null;
  },
  getterPlain: function (p) {
    return p.brightnessMetric ? p.brightnessMetric.rg : null;
  },
  decorator: (v, p, {
    ratio, size, label, icon, metricDataDecorator,
  }) => (p instanceof Diamond
    && (
    <OptMetricBar
      type="brilliance"
      cutShapeId={p.cutShapeId}
      ratio={ratio}
      metric={p.brightnessMetric}
      thresholds={scoringRepository.getThresholdsFor(p, CODE_BRIGHTNESS)}
      metricDataDecorator={metricDataDecorator}
    >
      {(scale, abs) => (
        <MetricScaleContainer abs={abs} scale={scale} size={size} label={label} icon={icon} isMetricCalculated={Boolean(p.brightnessMetric)} />
      )}
    </OptMetricBar>
    )
  ),
  /**
   * @param {Diamond} p
   * @return {?number}
   */
  iteratee: function (p) {
    return p.brightnessMetric ? p.brightnessMetric.val : null;
  },
  contexts: ALL_CONTEXTS,
};

export const FIELD_FACE_BRIGHTNESS = {
  label: 'Face Brightness',
  fullLabel: 'Face-up Brightness',
  key: 'brightness.faceup',
  type: TYPE_SCALAR,
  spGroupId: SETUP_PRESET_GROUP_OFFICE,
  icon: 'metrics-brightness-faceup',
  getter: function (p) {
    return this.getterPlain(p);
  },
  getterPlain: function (p) {
    return p.brightnessMetric ? p.brightnessMetric.faceup : null;
  },
  decorator: emptyDecorator,
  /**
   * @param {Diamond} p
   * @return {?number}
   */
  iteratee: function (p) {
    return p.brightnessMetric ? p.brightnessMetric.faceup : null;
  },
  contexts: ALL_CONTEXTS,
};

export const FIELD_AVG_BRIGHTNESS = {
  key: 'brightness.avg',
  label: 'Brightness',
  fullLabel: 'Brightness',
  type: TYPE_SCALAR,
  spGroupId: SETUP_PRESET_GROUP_OFFICE,
  icon: 'metrics-brightness',
  getter: function (p) {
    return this.getterPlain(p);
  },
  getterPlain: function (p) {
    return p.brightnessMetric ? p.brightnessMetric.avg : null;
  },
  decorator: emptyDecorator,
  /**
   * @param {Diamond} p
   * @return {?number}
   */
  iteratee: function (p) {
    return p.brightnessMetric ? p.brightnessMetric.avg : null;
  },
  contexts: ALL_CONTEXTS,
};

export const FIELD_OPTICAL_SYMMETRY = {
  key: 'symmetry.val',
  label: 'O. Symmetry',
  fullLabel: 'Optical Symmetry',
  type: TYPE_METRIC,
  spGroupId: SETUP_PRESET_GROUP_ASET,
  icon: 'metrics-optical-symmetry',
  getter: function (p) {
    return p.symmetryMetric ? p.symmetryMetric.hr : null;
  },
  getterPlain: function (p) {
    return p.symmetryMetric ? Number(p.symmetryMetric.val) : null;
  },
  decorator: (v, p, {
    ratio, size, label, icon, metricDataDecorator,
  }) => (p instanceof Diamond
    && (
    <OptMetricBar
      type="symmetry"
      cutShapeId={p.cutShapeId}
      ratio={ratio}
      metric={p.symmetryMetric}
      thresholds={scoringRepository.getThresholdsFor(p, CODE_SYMMETRY)}
      metricDataDecorator={metricDataDecorator}
    >
      {(scale, abs) => (
        <MetricScaleContainer abs={abs} scale={scale} size={size} label={label} icon={icon} isMetricCalculated={Boolean(p.symmetryMetric)} />
      )}
    </OptMetricBar>
    )
  ),
  /**
   * @param {Diamond} p
   * @return {?number}
   */
  iteratee: function (p) {
    return p.symmetryMetric ? p.symmetryMetric.val : null;
  },
  contexts: ALL_CONTEXTS,
};

export const FIELD_FIRE = {
  key: 'fire.val',
  label: 'Fire',
  fullLabel: 'Fire',
  type: TYPE_METRIC,
  spGroupId: SETUP_PRESET_GROUP_FIRE_DIBOX,
  icon: 'metrics-fire',
  getter: function (p) {
    return p.fireMetric ? p.fireMetric.hr : null;
  },
  getterPlain: function (p) {
    return p.fireMetric ? p.fireMetric.val : null;
  },
  decorator: (v, p, {
    ratio, size, label, icon, metricDataDecorator,
  }) => (p instanceof Diamond
    && (
    <OptMetricBar
      type="fire"
      cutShapeId={p.cutShapeId}
      ratio={ratio}
      metric={p.fireMetric}
      thresholds={scoringRepository.getThresholdsFor(p, CODE_FIRE)}
      metricDataDecorator={metricDataDecorator}
    >
      {(scale, abs) => (
        <MetricScaleContainer abs={abs} scale={scale} size={size} label={label} icon={icon} isMetricCalculated={Boolean(p.fireMetric)} />
      )}
    </OptMetricBar>
    )
  ),
  /**
   * @param {Diamond} p
   * @return {?number}
   */
  iteratee: function (p) {
    return p.fireMetric ? p.fireMetric.val : null;
  },
  contexts: ALL_CONTEXTS,
};

export const FIELD_SCINTILLATION = {
  key: 'scintillation.val',
  label: 'Scintillation',
  fullLabel: 'Scintillation',
  type: TYPE_METRIC,
  spGroupId: SETUP_PRESET_GROUP_FIRE_DIBOX, // todo 6569
  icon: 'metrics-scintillation',
  getter: function (p) {
    return p.scintillationMetric ? p.scintillationMetric.hr : null;
  },
  getterPlain: function (p) {
    return p.scintillationMetric ? p.scintillationMetric.val : null;
  },
  decorator: (v, p, {
    ratio, size, label, icon, metricDataDecorator,
  }) => (p instanceof Diamond
    && (
    <OptMetricBar
      type="scintillation"
      cutShapeId={p.cutShapeId}
      ratio={ratio}
      metric={p.scintillationMetric}
      thresholds={scoringRepository.getThresholdsFor(p, CODE_SCINTILLATION)}
      metricDataDecorator={metricDataDecorator}
    >
      {(scale, abs) => (
        <MetricScaleContainer abs={abs} scale={scale} size={size} label={label} icon={icon} isMetricCalculated={Boolean(p.scintillationMetric)}/>
      )}
    </OptMetricBar>
    )
  ),
  iteratee: function (p) {
    return p.scintillationMetric ? p.scintillationMetric.val : null;
  },
  context: ALL_CONTEXTS,
};

/**
 * @type {MediaField}
 */
export const FIELD_SCINTILLATION_MAP = {
  key: 'scintillationMap',
  label: 'Scintillation Map',
  fullLabel: 'Scintillation Map',
  type: TYPE_MEDIA,
  spGroupId: null,
  icon: '',
  /**
   * @param {Diamond} p
   * @param {ProductViewSettings} viewSettings
   * @param {number} maxScale
   * @returns {ReactElement}
   */
  getter: function (p, viewSettings, maxScale) {
    if (!p.scintillationMetric) {
      return null;
    }

    return (
      <MetricMap
        product={p}
        mediaSize={viewSettings.mediaSize}
        maxScale={maxScale}
        isSingleScaleEnabled={viewSettings.resultedIsSingleScale}
        setupPresetId={FIRE}
        metric={p.scintillationMetric}
      />
    );
  },
  getterPlain: function (p) {
    return p instanceof Diamond && p.scintillationMetric && p.scintillationMetric.map ? p.scintillationMetric.map : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_SHAPE_NORMALIZED_PERFORMANCE = {
  key: 'shapeNormalizedPerformance',
  label: 'Shape Norm. Perf.',
  fullLabel: 'Shape Normalized Performance',
  type: TYPE_METRIC,
  spGroupId: SETUP_PRESET_GROUP_FIRE_DIBOX,
  icon: 'metrics-normalized–cut-perf',
  getter: function (p) {
    return p.integralNormMetric ? p.integralNormMetric.hr : null;
  },
  getterPlain: function (p) {
    return p.integralNormMetric ? p.integralNormMetric.val : null;
  },
  decorator: (v, p, {
    ratio, size, label, icon,
  }) => (p instanceof Diamond
    && (
      <OptMetricBar
        type="integral"
        cutShapeId={p.cutShapeId}
        ratio={ratio}
        metric={p.integralNormMetric}
        thresholds={scoringRepository.getThresholdsFor(p, CODE_INTEGRAL)}
        metricDataDecorator={METRIC_DATA_DECORATOR_FANCY}
      >
        {(scale, abs) => (
          <MetricScaleContainer abs={abs} scale={scale} size={size} label={label} icon={icon} isMetricCalculated={Boolean(p.integralNormMetric)} />
        )}
      </OptMetricBar>
    )
  ),
  /**
   * @param {Diamond} p
   * @return {?number}
   */
  iteratee: function (p) {
    return p.integralNormMetric ? p.integralNormMetric.val : null;
  },
  contexts: ALL_CONTEXTS,
};

export const FIELD_CUT_PERFORMANCE = {
  key: 'integral.val',
  label: 'Global Perf.',
  fullLabel: 'Global Performance',
  type: TYPE_METRIC,
  spGroupId: SETUP_PRESET_GROUP_FIRE_DIBOX,
  icon: 'metrics-optical-performance',
  getter: function (p) {
    return p.integralMetric ? p.integralMetric.hr : null;
  },
  getterPlain: function (p) {
    return p.integralMetric ? p.integralMetric.val : null;
  },
  decorator: (v, p, {
    ratio, size, label, icon,
  }) => (p instanceof Diamond
    && (
    <OptMetricBar
      type="integral"
      cutShapeId={p.cutShapeId}
      ratio={ratio}
      metric={p.integralMetric}
      thresholds={scoringRepository.getThresholdsFor(p, CODE_INTEGRAL)}
      metricDataDecorator={METRIC_DATA_DECORATOR_ROUND}
    >
      {(scale, abs) => (
        <MetricScaleContainer abs={abs} scale={scale} size={size} label={label} icon={icon} isMetricCalculated={Boolean(p.integralMetric)} />
      )}
    </OptMetricBar>
    )
  ),
  /**
   * @param {Diamond} p
   * @return {?number}
   */
  iteratee: function (p) {
    return p.integralMetric ? p.integralMetric.val : null;
  },
  contexts: ALL_CONTEXTS,
};

export const FIELD_FLUOR = {
  key: 'fluor.val',
  label: 'Face-up Fluorescence',
  fullLabel: 'Face-up Fluorescence',
  type: TYPE_METRIC,
  spGroupId: SETUP_PRESET_GROUP_FLUOR_TABLE,
  hasAbsoluteValue: false,
  icon: 'metrics-fluor-table',
  getter: function (p) {
    return this.getterPlain(p);
  },
  getterPlain: function (p) {
    return p.fluorMetric ? p.fluorMetric.compositeVal : null;
  },
  decorator: (v, p, {
    ratio, size, label, icon,
  }) => {
    return (
      <FluMetricBar ratio={ratio} metric={p.fluorMetric}>
        {(scale, abs) => {
          let modifiedABS = abs;
          if (size !== SIZE_XSMALL && p.fluorMetric) {
            modifiedABS = p.fluorMetric.colorTitle;
          }

          if (size === SIZE_MEDIUM) {
            modifiedABS = '';
          }

          return <MetricScaleContainer abs={modifiedABS} scale={scale} size={size} label={label} icon={icon} isMetricCalculated={Boolean(p.fluorMetric)} />;
        }}
      </FluMetricBar>
    );
  },
  iteratee: function (p) {
    return p.fluorMetric ? p.fluorMetric.compositeVal : null;
  },
  contexts: ALL_CONTEXTS,
};

// used only on scatter plot
export const FIELD_FLUOR_GRADE = {
  key: 'fluor.grade',
  label: 'Face-up Fluorescence Grade',
  fullLabel: 'Face-up Fluorescence Grade',
  type: TYPE_METRIC,
  spGroupId: SETUP_PRESET_GROUP_FLUOR_TABLE,
  hasAbsoluteValue: false,
  icon: 'metrics-fluor-table',
  getter: function (p) {
    return this.getterPlain(p);
  },
  getterPlain: function (p) {
    return p.fluorMetric ? p.fluorMetric.grade : null;
  },
  decorator: (v, p, {
    ratio, size, label, icon,
  }) => {
    return (
      <FluMetricBar ratio={ratio} metric={p.fluorMetric}>
        {(scale, abs) => {
          let modifiedABS = abs;
          if (size !== SIZE_XSMALL) {
            modifiedABS = '';
          }

          return <MetricScaleContainer abs={modifiedABS} scale={scale} size={size} label={label} icon={icon} isMetricCalculated={Boolean(p.fluorMetric)} />;
        }}
      </FluMetricBar>
    );
  },
  iteratee: function (p) {
    return p.fluorMetric ? p.fluorMetric.compositeVal : null;
  },
  contexts: ALL_CONTEXTS,
};

// used only on scatter plot and radar
export const FIELD_FIRE_RG = {
  key: 'fire.rg',
  label: 'Fire',
  fullLabel: 'Fire',
  type: TYPE_METRIC,
  spGroupId: SETUP_PRESET_GROUP_FIRE_DIBOX,
  hasAbsoluteValue: true,
  icon: 'metrics-fire',
  getter: function (p) {
    return p.fireMetric ? p.fireMetric.hr : null;
  },
  getterPlain: function (p) {
    return p.fireMetric ? p.fireMetric.rg : null;
  },
  decorator: (v, p, {
    ratio, size, label, icon, metricDataDecorator,
  }) => (p instanceof Diamond
    && (
    <OptMetricBar
      type="fire"
      cutShapeId={p.cutShapeId}
      ratio={ratio}
      metric={p.fireMetric}
      thresholds={scoringRepository.getThresholdsFor(p, CODE_FIRE)}
      metricDataDecorator={metricDataDecorator}
    >
      {(scale, abs) => (
        <MetricScaleContainer abs={abs} scale={scale} size={size} label={label} icon={icon} isMetricCalculated={Boolean(p.fireMetric)} />
      )}
    </OptMetricBar>
    )
  ),
  iteratee: function (p) {
    return p.fireMetric ? p.fireMetric.rg : null;
  },
  contexts: ALL_CONTEXTS,
};

// used only on scatter plot and radar
export const FIELD_BRILLIANCE_RG = {
  key: 'brightness.rg',
  label: 'Brilliance',
  fullLabel: 'Brilliance',
  type: TYPE_METRIC,
  spGroupId: SETUP_PRESET_GROUP_OFFICE,
  hasAbsoluteValue: true,
  icon: 'metrics-brilliance',
  getter: function (p) {
    return p.brightnessMetric ? p.brightnessMetric.hr : null;
  },
  getterPlain: function (p) {
    return p.brightnessMetric ? p.brightnessMetric.rg : null;
  },
  decorator: (v, p, {
    ratio, size, label, icon, metricDataDecorator,
  }) => (p instanceof Diamond
    && (
    <OptMetricBar
      type="brilliance"
      cutShapeId={p.cutShapeId}
      ratio={ratio}
      metric={p.fireMetric}
      thresholds={scoringRepository.getThresholdsFor(p, CODE_BRIGHTNESS)}
      metricDataDecorator={metricDataDecorator}
    >
      {(scale, abs) => (
        <MetricScaleContainer abs={abs} scale={scale} size={size} label={label} icon={icon} isMetricCalculated={Boolean(p.fireMetric)} />
      )}
    </OptMetricBar>
    )
  ),
  iteratee: function (p) {
    return p.brightnessMetric ? p.brightnessMetric.rg : null;
  },
  contexts: ALL_CONTEXTS,
};

// used only on scatter plot and radar
export const FIELD_CUT_PERFORMANCE_RG = {
  key: 'integral.rg',
  label: 'Global Performance',
  fullLabel: 'Global Performance',
  type: TYPE_METRIC,
  spGroupId: SETUP_PRESET_GROUP_FIRE_DIBOX,
  hasAbsoluteValue: true,
  icon: 'metrics-optical-performance',
  getter: function (p) {
    return p.integralMetric ? p.integralMetric.hr : null;
  },
  getterPlain: function (p) {
    return p.integralMetric ? p.integralMetric.rg : null;
  },
  decorator: (v, p, {
    ratio, size, label, icon, metricDataDecorator,
  }) => (p instanceof Diamond
    && (
    <OptMetricBar
      type="integral"
      cutShapeId={p.cutShapeId}
      ratio={ratio}
      metric={p.integralMetric}
      thresholds={scoringRepository.getThresholdsFor(p, CODE_INTEGRAL)}
      metricDataDecorator={metricDataDecorator}
    >
      {(scale, abs) => (
        <MetricScaleContainer abs={abs} scale={scale} size={size} label={label} icon={icon} isMetricCalculated={Boolean(p.integralMetric)} />
      )}
    </OptMetricBar>
    )
  ),
  iteratee: function (p) {
    return p.integralMetric ? p.integralMetric.rg : null;
  },
  contexts: ALL_CONTEXTS,
};

// used only on scatter plot and radar
export const FIELD_OPTICAL_SYMMETRY_GRADE = {
  key: 'symmetry.rg',
  label: 'O. Symmetry',
  fullLabel: 'Optical Symmetry',
  type: TYPE_METRIC,
  spGroupId: SETUP_PRESET_GROUP_ASET,
  icon: 'metrics-optical-symmetry',
  getter: function (p) {
    return p.symmetryMetric ? p.symmetryMetric.hr : null;
  },
  getterPlain: function (p) {
    return p.symmetryMetric ? Number(p.symmetryMetric.val) : null;
  },
  decorator: (v, p, {
    ratio, size, label, icon, metricDataDecorator,
  }) => (p instanceof Diamond
    && (
    <OptMetricBar
      type="symmetry"
      cutShapeId={p.cutShapeId}
      ratio={ratio}
      metric={p.symmetryMetric}
      thresholds={scoringRepository.getThresholdsFor(p, CODE_SYMMETRY)}
      metricDataDecorator={metricDataDecorator}
    >
      {(scale, abs) => (
        <MetricScaleContainer abs={abs} scale={scale} size={size} label={label} icon={icon} isMetricCalculated={Boolean(p.symmetryMetric)} />
      )}
    </OptMetricBar>
    )
  ),
  /**
   * @param {Diamond} p
   * @return {?number}
   */
  iteratee: function (p) {
    return p.symmetryMetric ? p.symmetryMetric.val : null;
  },
  contexts: ALL_CONTEXTS,
};

export const FIELD_SPREAD = {
  key: 'spread',
  label: 'Spread',
  fullLabel: 'Spread',
  type: TYPE_METRIC,
  spGroupId: SETUP_PRESET_GROUP_OFFICE,
  hasAbsoluteValue: true,
  icon: 'metrics-spread',
  /**
   * @param {Diamond} p
   * @return {?number}
   */
  getter: function (p) {
    return p.spreadMetric ? SpreadFormatter.format(p.spreadMetric.ct, 2, 'ct') : null;
  },
  /**
   * @param {Diamond} p
   * @return {?number}
   */
  getterPlain: function (p) {
    return p.spreadMetric ? p.spreadMetric.ct.toFixed(2) : null;
  },
  decorator: (v, p, {
    ratio, size, label, icon, metricDataDecorator,
  }) => (p instanceof Diamond
    && (
    <SpreadMetricBar
      ratio={ratio}
      cutShapeId={p.cutShapeId}
      metric={p.spreadMetric}
      thresholds={scoringRepository.getThresholdsFor(p, CODE_SPREAD)}
      metricDataDecorator={metricDataDecorator}
    >
      {(scale, abs) => (
        <MetricScaleContainer abs={abs} scale={scale} size={size} label={label} icon={icon} isMetricCalculated={Boolean(p.spreadMetric)} />
      )}
    </SpreadMetricBar>
    )
  ),
  iteratee: p => (p.spreadMetric ? p.spreadMetric.ct : null),
  contexts: ALL_CONTEXTS,
};

export const FIELD_DIMENSIONS = {
  key: 'dimensions',
  label: 'Dimensions',
  fullLabel: 'Dimensions',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: null,
  getter: function (p) {
    return this.getterPlain(p);
  },
  getterPlain: function (p) {
    return p instanceof Rough ? p.dimensions : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_MANUFACTURER = {
  key: 'manufacturer',
  label: 'Manufacturer',
  fullLabel: 'Manufacturer',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: null,
  getter: function (p) {
    return this.getterPlain(p);
  },
  getterPlain: function (p) {
    return p.manufacturer ? p.manufacturer : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_CULET = {
  key: 'culetSizeGrade',
  label: 'Culet',
  fullLabel: 'Culet Grade',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: 'metrics-culet',
  getter: function (p, short = false) {
    if (p.culetSizeGrade) {
      return short ? p.culetSizeGrade.short : p.culetSizeGrade.title;
    }
    return null;
  },
  getterPlain: function (p) {
    return p.culetSizeGrade ? p.culetSizeGrade : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_LW = {
  key: 'lengthMm',
  label: 'L × W',
  fullLabel: 'L × W',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: null,
  getter: function (p) {
    return p.reportData && p.reportData.length !== null && p.reportData.width !== null ? `${p.reportData.length.toFixed(2)}x${p.reportData.width.toFixed(2)} mm` : null;
  },
  getterPlain: function (p) {
    return p.reportData && p.reportData.length !== null && p.reportData.width !== null ? `${p.reportData.length.toFixed(2)}x${p.reportData.width.toFixed(2)}` : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_LENGTH = {
  key: 'lengthMm',
  label: 'Length',
  fullLabel: 'Length, mm',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: null,
  getter: function (p) {
    return p.reportData && p.reportData.length !== null ? `${p.reportData.length} mm` : null;
  },
  getterPlain: function (p) {
    return p.reportData && p.reportData.length !== null ? `${p.reportData.length}` : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_WIDTH = {
  key: 'widthMm',
  label: 'Width',
  fullLabel: 'Width, mm',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: null,
  getter: function (p) {
    return p.reportData && p.reportData.width !== null ? `${p.reportData.width} mm` : null;
  },
  getterPlain: function (p) {
    return p.reportData && p.reportData.width !== null ? `${p.reportData.width}` : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_DIAMETER_RATIO = {
  key: 'diameterRatio',
  label: 'Diameter Ratio',
  fullLabel: 'Diameter Ratio',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: null,
  getter: function (p) {
    return this.getterPlain(p);
  },
  getterPlain: function (p) {
    return p.reportData && p.reportData.rt !== null ? `${p.reportData.rt}` : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_DEPTH_MM = {
  key: 'totalHeightMm',
  label: 'Depth',
  fullLabel: 'Depth, mm',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: null,
  getter: function (p) {
    return p.reportData && p.reportData.height !== null ? `${p.reportData.height} mm` : null;
  },
  getterPlain: function (p) {
    return p.reportData && p.reportData.height !== null ? p.reportData.height : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_DEPTH_PC = {
  key: 'totalHeightPc',
  label: 'Depth',
  fullLabel: 'Depth, %',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: null,
  getter: function (p) {
    return p.reportData && p.reportData.heightPc !== null ? `${p.reportData.heightPc}%` : null;
  },
  getterPlain: function (p) {
    return p.reportData && p.reportData.heightPc !== null ? p.reportData.heightPc : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_DEPTH_COMPOSED = {
  key: 'totalHeight',
  label: 'Depth',
  fullLabel: 'Depth',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: null,
  getter: function (p) {
    return p.reportData && p.reportData.heightPc !== null && p.reportData.height !== null ? `${p.reportData.heightPc}% (${p.reportData.height} mm)` : null;
  },
  getterPlain: function (p) {
    return p.reportData && p.reportData.heightPc !== null ? p.reportData.heightPc !== null : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_TABLE_MM = {
  key: 'tableMma',
  label: 'Table',
  fullLabel: 'Table, mm',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: null,
  getter: function (p) {
    return p.reportData && p.reportData.table !== null ? `${p.reportData.table} mm` : null;
  },
  getterPlain: function (p) {
    return p.reportData && p.reportData.table !== null ? p.reportData.table : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_TABLE_PC = {
  key: 'tablePc',
  label: 'Table',
  fullLabel: 'Table Size',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: null,
  getter: function (p) {
    return p.reportData && p.reportData.tablePc !== null ? `${p.reportData.tablePc}%` : null;
  },
  getterPlain: function (p) {
    return p.reportData && p.reportData.tablePc !== null ? p.reportData.tablePc : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_TABLE_COMPOSED = {
  key: 'table',
  label: 'Table',
  fullLabel: 'Table',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: null,
  getter: function (p) {
    return p.reportData && p.reportData.tablePc !== null && p.reportData.table !== null ? `${p.reportData.tablePc}% (${p.reportData.table} mm)` : null;
  },
  getterPlain: function (p) {
    return p.reportData && p.reportData.tablePc !== null ? p.reportData.tablePc : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_CROWN_MM = {
  key: 'crownHeightMma',
  label: 'Crown',
  fullLabel: 'Crown, mm',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: null,
  getter: function (p) {
    return p.reportData && p.reportData.crnHeight !== null ? `${p.reportData.crnHeight} mm` : null;
  },
  getterPlain: function (p) {
    return p.reportData && p.reportData.crnHeight !== null ? p.reportData.crnHeight : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_CROWN_PC = {
  key: 'crownHeightPcAvg',
  label: 'Crown',
  fullLabel: 'Crown, %',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: null,
  getter: function (p) {
    return p.reportData && p.reportData.crnHeightPc !== null ? `${p.reportData.crnHeightPc} %` : null;
  },
  getterPlain: function (p) {
    return p.reportData && p.reportData.crnHeightPc !== null ? p.reportData.crnHeightPc : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_CROWN_ANGLE = {
  key: 'crownAngleDeg',
  label: 'Crown Angle',
  fullLabel: 'Crown Angle',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: null,
  getter: function (p) {
    return p.reportData && p.reportData.crnAn !== null ? `${p.reportData.crnAn}°` : null;
  },
  getterPlain: function (p) {
    return p.reportData && p.reportData.crnAn !== null ? p.reportData.crnAn : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_CROWN_COMPOSED = {
  key: 'crownComposed',
  label: 'Crown Height',
  fullLabel: 'Crown Height',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: null,
  getter: function (p) {
    return p.reportData && p.reportData.crnHeightPc !== null && p.reportData.crnHeight !== null ? `${p.reportData.crnHeightPc}% (${p.reportData.crnHeight} mm)` : null;
  },
  getterPlain: function (p) {
    return p.reportData && p.reportData.crnHeightPc !== null && p.reportData.crnHeight !== null ? p.reportData.crnHeightPc : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_PAVILION_MM = {
  key: 'pavilionHeightMma',
  label: 'Pavilion',
  fullLabel: 'Pavilion, mm',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: null,
  getter: function (p) {
    return p.reportData && p.reportData.pavDepth !== null ? `${p.reportData.pavDepth} mm` : null;
  },
  getterPlain: function (p) {
    return p.reportData && p.reportData.pavDepth !== null ? p.reportData.pavDepth : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_PAVILION_PC = {
  key: 'pavilionHeightPcAvg',
  label: 'Pavilion',
  fullLabel: 'Pavilion, %',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: null,
  getter: function (p) {
    return p.reportData && p.reportData.pavDepthPc !== null ? `${p.reportData.pavDepthPc} %` : null;
  },
  getterPlain: function (p) {
    return p.reportData && p.reportData.pavDepthPc !== null ? p.reportData.pavDepthPc : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_PAVILION_ANGLE = {
  key: 'pavilionAngleDeg',
  label: 'Pav. Angle',
  fullLabel: 'Pavilion Angle',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: null,
  getter: function (p) {
    return p.reportData && p.reportData.pavAn !== null ? `${p.reportData.pavAn}°` : null;
  },
  getterPlain: function (p) {
    return p.reportData && p.reportData.pavAn !== null ? p.reportData.pavAn : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_PAVILION_FIRST_ANGLE = {
  key: 'pav1An',
  label: 'Pav. F. Angle',
  fullLabel: 'Pavilion First Angle',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: null,
  getter: function (p) {
    return p.reportData && p.reportData.pav1An !== null ? `${p.reportData.pav1An}°` : null;
  },
  getterPlain: function (p) {
    return p.reportData && p.reportData.pav1An !== null ? p.reportData.pav1An : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_PAVILION_COMPOSED = {
  key: 'pavilionComposed',
  label: 'Pav. Depth',
  fullLabel: 'Pavilion Depth',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: null,
  getter: function (p) {
    return p.reportData && p.reportData.pavDepthPc !== null && p.reportData.pavDepth !== null ? `${p.reportData.pavDepthPc}% (${p.reportData.pavDepth} mm)` : null;
  },
  getterPlain: function (p) {
    return p.reportData && p.reportData.pavDepthPc !== null ? p.reportData.pavDepthPc : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_GIRDLE_COMPOSED = {
  key: 'girdleComposed',
  label: 'Girdle',
  fullLabel: 'Girdle',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: 'metrics-girdle',
  getter: function (p) {
    return p.reportData && p.reportData.girdleBezelPc !== null && p.reportData.girdleBezel !== null ? `${p.reportData.girdleBezelPc}% (${p.reportData.girdleBezel} mm)` : null;
  },
  getterPlain: function (p) {
    return p.reportData && p.reportData.girdleBezelPc !== null ? p.reportData.girdleBezelPc : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_CULET_COMPOSED = {
  key: 'culetComposed',
  label: 'Culet',
  fullLabel: 'Culet',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: 'metrics-culet',
  getter: function (p) {
    return p.reportData && p.reportData.culetPc !== null && p.reportData.culet !== null ? `${p.reportData.culetPc}% (${p.reportData.culet} mm)` : null;
  },
  getterPlain: function (p) {
    return p.reportData && p.reportData.culetPc !== null ? p.reportData.culetPc : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_GIRDLE_PC = {
  key: 'girdlePc',
  label: 'Girdle',
  fullLabel: 'Girdle, %',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: 'metrics-girdle',
  getter: function (p) {
    return p.reportData && p.reportData.girdleBezelPc !== null ? `${p.reportData.girdleBezelPc}%` : null;
  },
  getterPlain: function (p) {
    return p.reportData && p.reportData.girdleBezelPc !== null ? p.reportData.girdleBezelPc : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_PAVILION_COLOR = {
  key: 'dzPavColor',
  label: 'Pavilion Color',
  fullLabel: 'Pavilion Color',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: 'metrics-color',
  getter: function (p) {
    if (!p.reportData || !p.reportData.dzPavColor) {
      return null;
    }

    return <HighlightedHPOColor hpoColor={p.reportData.dzPavColor} />;
  },
  getterPlain: function (p) {
    return p.reportData ? p.reportData.dzPavColor : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_TABLE_COLOR = {
  key: 'dzTableColor',
  label: 'Table Color',
  fullLabel: 'Table Color',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: 'metrics-color-table',
  getter: function (p) {
    if (!p.reportData || !p.reportData.dzTableColor) {
      return null;
    }

    return <HighlightedHPOColor hpoColor={p.reportData.dzTableColor} hpoColorToCompare={p.reportData.dzPavColor} />;
  },
  getterPlain: function (p) {
    return p.reportData ? p.reportData.dzTableColor : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_HISTOGRAM = {
  key: 'fHist',
  label: 'Histogram',
  fullLabel: 'Histogram',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: null,
  getter: function (p) {
    return this.getterPlain(p);
  },
  getterPlain: function (p) {
    return p.reportData ? p.reportData.fHist : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_CHROMA = {
  key: 'fChroma',
  label: 'Chroma',
  fullLabel: 'Chroma',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: null,
  getter: function (p) {
    return this.getterPlain(p);
  },
  getterPlain: function (p) {
    return p.reportData ? p.reportData.fChroma : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_GIRDLE_MM = {
  key: 'girdleWideBezelMma',
  label: 'Girdle',
  fullLabel: 'Girdle, mm',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: 'metrics-girdle',
  getter: function (p) {
    return p.reportData && p.reportData.girdleBezel !== null ? `${p.reportData.girdleBezel} mm` : null;
  },
  getterPlain: function (p) {
    return p.reportData && p.reportData.girdleBezel !== null ? p.reportData.girdleBezel : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_CULET_PC = {
  key: 'culetPc',
  label: 'Culet',
  fullLabel: 'Culet Size',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: 'metrics-culet',
  getter: function (p) {
    return p.reportData && p.reportData.culetPc !== null ? `${p.reportData.culetPc}%` : null;
  },
  getterPlain: function (p) {
    return p.reportData && p.reportData.culetPc !== null ? p.reportData.culetPc : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_CULET_MM = {
  key: 'culetMma',
  label: 'Culet',
  fullLabel: 'Culet, mm',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: 'metrics-culet',
  getter: function (p) {
    return p.reportData && p.reportData.culet !== null ? `${p.reportData.culet} mm` : null;
  },
  getterPlain: function (p) {
    return p.reportData && p.reportData.culet !== null ? p.reportData.culet : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_CERTIFICATE = {
  key: 'cert',
  label: 'Cert.',
  fullLabel: 'Certificate',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: null,
  getter: function (p) {
    return this.getterPlain(p);
  },
  getterPlain: function (p) {
    return p.certificate ? p.certificate : null;
  },
  decorator: (v) => {
    return v ? <Certification certification={v} /> : null;
  },
  iteratee: null,
  contexts: ONLY_LISTING_CONTEXTS,
};

export const FIELD_INCLUSION_DETAILS = {
  key: 'inclusions',
  label: 'Inclusions',
  fullLabel: 'Inclusion Details',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: 'inclusion',
  getter: function (p) {
    return this.getterPlain(p);
  },
  getterPlain: function (p) {
    return p.clarityVisibility ? p.clarityVisibility : null;
  },
  decorator: emptyDecorator,
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_LABELS = {
  label: '',
  fullLabel: '',
  key: 'labels',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: '',
  getter: function (p) {
    const isProductValid = p instanceof Diamond || p instanceof Rough;
    if (!isProductValid) {
      return null;
    }

    if (p instanceof Rough) {
      return p.isLabGrown || securityManager.isGranted(VIEW_PRODUCT_STATUS, {product: p}) || null;
    }

    const hasAtLeastOneLabel = p.isLabGrown || p.isPhotoreal || p.isReference || securityManager.isGranted(VIEW_PRODUCT_STATUS, {product: p});

    return hasAtLeastOneLabel || null;
  },
  getterPlain: function (p) {
    const isProductValid = p instanceof Diamond || p instanceof Rough;
    if (!isProductValid) {
      return null;
    }

    if (p instanceof Rough) {
      return p.isLabGrown || securityManager.isGranted(VIEW_PRODUCT_STATUS, {product: p}) || null;
    }

    const hasAtLeastOneLabel = p.isLabGrown || p.isPhotoreal || p.isReference || securityManager.isGranted(VIEW_PRODUCT_STATUS, {product: p});

    return hasAtLeastOneLabel || null;
  },
  decorator: function (v, p, props = {}) {
    return <ProductLabels product={p} centered={props.centered} isCreatable={securityManager.isGranted(PRODUCT_EDIT, { product: p })} />;
  },
  iteratee: null,
  contexts: ALL_CONTEXTS
};

export const FIELD_LABELS_EXTENDED = {
  label: '',
  fullLabel: '',
  key: 'labels',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: '',
  getter: function (p) {
    const isProductValid = p instanceof Diamond || p instanceof Rough;

    if (!isProductValid) {
      return null;
    }

    if (p instanceof Rough) {
      return p.isLabGrown || securityManager.isGranted(VIEW_PRODUCT_STATUS, {product: p}) || null;
    }

    const hasAtLeastOneLabel = p.isLabGrown || p.isPhotoreal || p.isReference || securityManager.isGranted(VIEW_PRODUCT_STATUS, {product: p});

    return hasAtLeastOneLabel || null;
  },
  getterPlain: function (p) {
    const isProductValid = p instanceof Diamond || p instanceof Rough;

    if (!isProductValid) {
      return null;
    }

    if (p instanceof Rough) {
      return p.isLabGrown || securityManager.isGranted(VIEW_PRODUCT_STATUS, {product: p}) || null;
    }

    const hasAtLeastOneLabel = p.isLabGrown || p.isPhotoreal || p.isReference || securityManager.isGranted(VIEW_PRODUCT_STATUS, {product: p});

    return hasAtLeastOneLabel || null;
  },
  decorator: function (v, p, props = {}) {
    return (
      <ProductLabels product={p} centered={props.centered} showAccess isCreatable={securityManager.isGranted(PRODUCT_EDIT, { product: p })}  />
    );
  },
  iteratee: null,
  contexts: ALL_CONTEXTS
};

export const FIELD_GLOBAL_ACCESS = {
  label: 'Global Access',
  fullLabel: 'Global Access',
  key: 'globalAccess',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: '',
  getter: function (p) {
    const isProductValid = p instanceof Diamond || p instanceof Rough;

    if (!isProductValid) {
      return null;
    }

    return securityManager.isGranted(VIEW_PRODUCT_STATUS, {product: p}) || null;
  },
  getterPlain: function (p) {
    const isProductValid = p instanceof Diamond || p instanceof Rough;

    if (!isProductValid) {
      return null;
    }

    return securityManager.isGranted(VIEW_PRODUCT_STATUS, {product: p}) || null;
  },
  decorator: function (v, p) {
    return <AccessStatus product={p} isCreatable />;
  },
  iteratee: null,
  contexts: [CONTEXT_B2B],
};

export const FIELD_PRIVATE_ACCESS = {
  label: 'Private Access',
  fullLabel: 'Private Access',
  key: 'privateAccess',
  type: TYPE_SCALAR,
  spGroupId: null,
  icon: '',
  getter: function (p) {
    const isProductValid = p instanceof Diamond || p instanceof Rough;

    if (!isProductValid) {
      return null;
    }

    return securityManager.isGranted(VIEW_PRODUCT_STATUS, {product: p}) || null;
  },
  getterPlain: function (p) {
    const isProductValid = p instanceof Diamond || p instanceof Rough;

    if (!isProductValid) {
      return null;
    }

    return securityManager.isGranted(VIEW_PRODUCT_STATUS, {product: p}) || null;
  },
  decorator: function (v, p) {
    return <ACLStatus product={p} isCreatable />;
  },
  iteratee: null,
  contexts: [CONTEXT_B2B],
};

/**
 * @type {MediaField}
 */
export const FIELD_FIRE_MAP = {
  label: 'Fire Map',
  fullLabel: 'Fire Map',
  key: 'fireMap',
  type: TYPE_MEDIA,
  spGroupId: null,
  icon: '',
  /**
   * @param {Diamond} p
   * @param {ProductViewSettings} viewSettings
   * @param {number} maxScale
   * @returns {ReactElement}
   */
  getter: function (p, viewSettings, maxScale) {
    if (!p.fireMetric) {
      return null;
    }

    return (
      <MetricMap
        product={p}
        mediaSize={viewSettings.mediaSize}
        maxScale={maxScale}
        isSingleScaleEnabled={viewSettings.resultedIsSingleScale}
        setupPresetId={FIRE}
        metric={p.fireMetric}
      />
    );
  },
  getterPlain: function (p) {
    return p instanceof Diamond && p.fireMetric && p.fireMetric.map ? p.fireMetric.map : null;
  },
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_CUSTOM_COLOR = {
  label: 'Custom Color',
  fullLabel: 'Custom Color',
  key: 'customColor',
  type: TYPE_SCALAR,
  spGroupId: SETUP_PRESET_GROUP_PAV_COLOR_LAB,
  icon: 'metrics-color',
  /**
   * @param {Product} p
   */
  getter: function (p) {
    return p.customGrades && p.customGrades[CUSTOM_GRADE_COLOR] && p.customGrades[CUSTOM_GRADE_COLOR].value ? p.customGrades[CUSTOM_GRADE_COLOR].value.title : null;
  },
  /**
   * @param {Product} p
   */
  getterPlain: function (p) {
    return p.customGrades && p.customGrades[CUSTOM_GRADE_COLOR] ? p.customGrades[CUSTOM_GRADE_COLOR].rawValue : null;
  },
  decorator: emptyDecorator,
  /**
   * @param {Product} p
   */
  iteratee: function (p) {
    return p.customGrades && p.customGrades[CUSTOM_GRADE_COLOR] && p.customGrades[CUSTOM_GRADE_COLOR].value ? p.customGrades[CUSTOM_GRADE_COLOR].value.id * -1 : null;
  },
  contexts: ALL_CONTEXTS,
  isCustomGrade: true,
};

export const FIELD_CUSTOM_GIRDLE_BEAUTY = {
  label: 'Shape Appeal',
  fullLabel: 'Shape Appeal',
  key: 'girdleBeauty',
  type: TYPE_SCALAR,
  spGroupId: SETUP_PRESET_GROUP_OFFICE,
  icon: 'metrics-girdle',
  /**
   * @param {Product} p
   */
  getter: function (p) {
    return p.customGrades && p.customGrades[CUSTOM_GRADE_GIRDLE_BEAUTY] && p.customGrades[CUSTOM_GRADE_GIRDLE_BEAUTY].value ? p.customGrades[CUSTOM_GRADE_GIRDLE_BEAUTY].value.title : null;
  },
  /**
   * @param {Product} p
   */
  getterPlain: function (p) {
    return p.customGrades && p.customGrades[CUSTOM_GRADE_GIRDLE_BEAUTY] ? p.customGrades[CUSTOM_GRADE_GIRDLE_BEAUTY].rawValue : null;
  },
  decorator: emptyDecorator,
  /**
   * @param {Product} p
   */
  iteratee: function (p) {
    return p.customGrades && p.customGrades[CUSTOM_GRADE_GIRDLE_BEAUTY] && p.customGrades[CUSTOM_GRADE_GIRDLE_BEAUTY].value ? p.customGrades[CUSTOM_GRADE_GIRDLE_BEAUTY].value.id * -1 : null;
  },
  contexts: ALL_CONTEXTS,
  isCustomGrade: true,
};

export const FIELD_CUSTOM_BRILLIANCE = {
  label: 'Custom Brilliance',
  fullLabel: 'Custom Brilliance',
  key: 'customBrilliance',
  type: TYPE_SCALAR,
  spGroupId: SETUP_PRESET_GROUP_OFFICE,
  icon: 'metrics-brilliance',
  /**
   * @param {Product} p
   */
  getter: function (p) {
    return p.customGrades && p.customGrades[CUSTOM_GRADE_BRILLIANCE] && p.customGrades[CUSTOM_GRADE_BRILLIANCE].value ? p.customGrades[CUSTOM_GRADE_BRILLIANCE].value.title : null;
  },
  /**
   * @param {Product} p
   */
  getterPlain: function (p) {
    return p.customGrades && p.customGrades[CUSTOM_GRADE_BRILLIANCE] ? p.customGrades[CUSTOM_GRADE_BRILLIANCE].rawValue : null;
  },
  decorator: emptyDecorator,
  /**
   * @param {Product} p
   */
  iteratee: function (p) {
    return p.customGrades && p.customGrades[CUSTOM_GRADE_BRILLIANCE] && p.customGrades[CUSTOM_GRADE_BRILLIANCE].value ? p.customGrades[CUSTOM_GRADE_BRILLIANCE].value.id * -1 : null;
  },
  contexts: ALL_CONTEXTS,
  isCustomGrade: true,
};

export const FIELD_CUSTOM_CUT_PERFORMANCE = {
  label: 'Custom Cut Performance',
  fullLabel: 'Custom Cut Performance',
  key: 'customCutPerformance',
  type: TYPE_SCALAR,
  spGroupId: SETUP_PRESET_GROUP_FIRE_DIBOX,
  icon: 'metrics-optical-performance',
  /**
   * @param {Product} p
   */
  getter: function (p) {
    return p.customGrades && p.customGrades[CUSTOM_GRADE_CUT_PERFORMANCE] && p.customGrades[CUSTOM_GRADE_CUT_PERFORMANCE].value ? p.customGrades[CUSTOM_GRADE_CUT_PERFORMANCE].value.title : null;
  },
  /**
   * @param {Product} p
   */
  getterPlain: function (p) {
    return p.customGrades && p.customGrades[CUSTOM_GRADE_CUT_PERFORMANCE] ? p.customGrades[CUSTOM_GRADE_CUT_PERFORMANCE].rawValue : null;
  },
  decorator: emptyDecorator,
  /**
   * @param {Product} p
   */
  iteratee: function (p) {
    return p.customGrades && p.customGrades[CUSTOM_GRADE_CUT_PERFORMANCE] && p.customGrades[CUSTOM_GRADE_CUT_PERFORMANCE].value ? p.customGrades[CUSTOM_GRADE_CUT_PERFORMANCE].value.id * -1 : null;
  },
  contexts: ALL_CONTEXTS,
  isCustomGrade: true,
};

export const FIELD_NEXT_PAYMENT = {
  label: 'Next Payment',
  fullLabel: 'Next Payment',
  key: 'nextPayment',
  type: TYPE_CUSTOM,
  icon: '',
  getter: function (product) {
    if (!product.expenses) {
      return null;
    }

    return (
      <NextPayment amount={product.expenses.nextAmount} days={product.expenses.nextDays} />
    );
  },
  getterPlain: function () {
    return null;
  },
  decorator: emptyDecorator(),
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

export const FIELD_EXPENSES = {
  label: 'Expenses',
  fullLabel: 'Expenses',
  key: 'expenses',
  type: TYPE_CUSTOM,
  icon: '',
  getter: function (product, _, extra) {
    const { largestExpenseOnPage, onToggle } = extra;

    const productExpenses = product.expenses && (product.expenses.expenses || product.expenses.expenses === 0) ? +product.expenses.expenses : null;
    let progressValue = 0;

    if (productExpenses) {
      // here we calculate progress length of product expenses in comparison with the largest product expenses on page
      progressValue = (productExpenses / largestExpenseOnPage) * 100;
    }

    return (
      <ExpensesProgress value={progressValue} amount={productExpenses} onExpand={onToggle} />
    );
  },
  getterPlain: function () {
    return null;
  },
  decorator: emptyDecorator(),
  iteratee: null,
  contexts: ALL_CONTEXTS,
};

/**
 * Entity for composition of different setup presets. For example, we merge Fire and Fire Live Photo in one SetupPresetGroup. If product doesn't have Fire video then Fire Live Photo will be displayed
 * @typedef {Object} MediaField
 * @property {string} label - Short human-readable label
 * @property {string} fullLabel - Full human-readable label
 * @property {number} key - Unique key (for fields identification and iteration)
 * @property {string} type - Indicates how this field should be processed (as media or simple scalar value and so on)
 * @property {SetupPresetGroup} spg - Instance of SetupPresetGroup
 * @property {function(Product, ProductViewSettings, number, boolean): *} getter - Gets output for this field (human-readable representation, JSX markup usually)
 * @property {function(Product): Media} getterPlain - Get simple plain value for this field (for example, sorting / filtering)
 */

/**
 * @param {SetupPresetGroup} setupPresetGroup
 * @return {MediaField}
 */
export const generateMediaField = (setupPresetGroup) => {
  return {
    label: setupPresetGroup.title,
    fullLabel: setupPresetGroup.title,
    key: setupPresetGroup.id,
    type: TYPE_MEDIA,
    spg: setupPresetGroup,
    getter: (p, viewSettings, maxScale, isHQModeEnabled) => {
      /** @type {?Media} media */
      let media = p.mediaCollection ? p.mediaCollection.getMediaBySetupPresetGroup(setupPresetGroup) : null;
      let fixedMaxScale = maxScale;

      if (p.type === TYPE_ROUGH || p.type === TYPE_LGD_ROUGH) {
        const isSPGExistInSolutionList = SPG_ORDER_SOLUTION_LIST_DZ.includes(setupPresetGroup.id) || SPG_ORDER_SOLUTION_LIST_FANCY_COLOR.includes(setupPresetGroup.id);

        if (setupPresetGroup.id === SETUP_PRESET_GROUP_OFFICE) {
          const spgFullRotation = setupPresetGroupRepository.findSetupPresetGroupById(SETUP_PRESET_GROUP_FULL_ROTATION);
          const spgFullOffice = setupPresetGroupRepository.findSetupPresetGroupById(SETUP_PRESET_GROUP_OFFICE);

          if (p.mediaCollection) {
            media = p.mediaCollection.getMediaByMultipleSetupPresets(
              [
                ...spgFullRotation.sp,
                ...spgFullOffice.sp,
              ],
            );
          }

          fixedMaxScale = 1;
        } else if (isSPGExistInSolutionList) {
          return '—';
        }
      }

      return (
        <ProductMedia
          key={`${p.id}-${setupPresetGroup.id}`}
          product={p}
          setupPreset={media ? media.setupPreset : null}
          mediaSize={viewSettings.mediaSize}
          isSingleScale={viewSettings.resultedIsSingleScale}
          isGrayscale={viewSettings.resultedIsGrayscale}
          isSaturation={viewSettings.resultedIsSaturation}
          showMediaTypes
          hasLazyLoading
          hideOutsideViewport={false}
          maxScale={fixedMaxScale}
          hqMode={isHQModeEnabled}
          preventNavigation={p.isReference}
        />
      );
    },
    getterPlain: (p) => {
      if (!p.mediaCollection) {
        return null;
      }

      return p.mediaCollection.getMediaBySetupPresetGroup(setupPresetGroup);
    },
  };
};

/**
 * @param {(ScalarField|MediaField)[]} fields
 * @param {number} context
 * @return {(ScalarField|MediaField)[]}
 */
export const filterFieldsByContext = (fields, context) => {
  return fields.filter((f) => {
    if (!f.contexts || !context) {
      return true;
    }

    return f.contexts.includes(context);
  });
};
