import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import styles from './ServiceForecastTable.module.css';

@observer
export default class ServiceForecastTable extends Component {
  static propTypes = {
    alert: PropTypes.bool,
  };

  static defaultProps = {
    alert: false,
  };

  render() {
    if (this.props.alert) {
      return (<div>Alert</div>);
    }

    return (
      <>
        <div>Sufficient balance estimate: 2.5 month</div>
        <div className={styles.forecastTableTableWrap}>
          <table className={styles.forecastTableTable}>
            <thead>
              <tr>
                <td>Services</td>
                <td>Fee</td>
                <td>New Products</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Upload DiBox 2.0</td>
                <td>1.00 €</td>
                <td>8 500</td>
              </tr>
              <tr>
                <td>Upload DiBox 2.0 + Calculate Optical Performance</td>
                <td>5.00 €</td>
                <td>1 700</td>
              </tr>
              <tr>
                <td>Upload DiBox 2.0 + Upload 360 Video</td>
                <td>1.10 €</td>
                <td>7 727</td>
              </tr>
              <tr>
                <td>Upload 360 Video</td>
                <td>0.10 €</td>
                <td>85000</td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
}
