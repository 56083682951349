var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { endOfDay, startOfDay } from 'date-fns';
import { action, computed, observable } from 'mobx';
import qs from 'qs';
import { BASE_SERIALIZE_OPTIONS } from '@/common/constants/qsSerialize';
import { dateFormat } from '@/common/helpers/dateFormat';
import { downloadTextContentAsFile } from '@/common/helpers/download';
import { pureStructureFrom } from '@/common/helpers/pureStructureFrom';
import { HEADER_X_PRODUCTS_COUNT, HEADER_X_TOTAL_AMOUNT, HEADER_X_TOTAL_COUNT, } from '@/common/network/CutwiseAPIClient';
import browserHistory from '@/common/services/history';
import Notifier from '@/common/services/notifier';
import BillingAPI from '../api/BillingAPI';
import { BILLING_TRANSACTIONS_PAGE } from '../constants/pages';
import { BillingTransaction } from '../entities/billingTransaction';
import { billingStore } from './BillingStore';
const DEFAULT_TRANSACTIONS_FILTER = {
    page: 1,
    createdAt: {
        from: null,
        to: null,
    },
    amount: {
        from: null,
        to: null,
    },
    type: null,
    sku: null,
    product: null,
    user: null,
    order: {
        createdAt: 'desc',
    },
};
export const TRANSACTIONS_PAGE_LIMIT = 20;
export class TransactionsStore {
    transactionsFilter = DEFAULT_TRANSACTIONS_FILTER;
    billingTransactions;
    totalTransactions;
    totalProducts;
    totalAmount;
    isExportingTransactions = false;
    get transactionsPagesCount() {
        if (!this.totalTransactions) {
            return 0;
        }
        return Math.ceil(Number(this.totalTransactions) / TRANSACTIONS_PAGE_LIMIT);
    }
    billingAPI;
    constructor() {
        this.billingAPI = new BillingAPI();
    }
    initTransactionsStore = () => {
        this.transactionsFilter = this.parseTransactionQueryString();
    };
    exportBillingTransactions = () => {
        this.isExportingTransactions = true;
        this.billingAPI
            .exportBillingTransactionsToCSV(this.getFilterServerFormat())
            .then((res) => {
            if (!res.content) {
                return;
            }
            downloadTextContentAsFile(res.content, 'application/csv;charset=utf-8;', 'transactions.csv');
        })
            .catch((e) => Notifier.error(e.message))
            .finally(() => {
            this.isExportingTransactions = false;
        });
    };
    setTransactionFilter = (filter) => {
        this.transactionsFilter = pureStructureFrom({
            ...this.transactionsFilter,
            page: 1,
            ...filter,
        });
        this.fetchTransactions();
    };
    resetTransactionFilter = () => {
        this.setTransactionFilter(this.parseTransactionQueryString());
    };
    fetchTransactions = () => {
        if (!billingStore.activeBillingAccount?.id) {
            return;
        }
        this.billingTransactions = undefined;
        const { page, order } = this.transactionsFilter;
        const params = {
            offset: (page - 1) * TRANSACTIONS_PAGE_LIMIT,
            limit: TRANSACTIONS_PAGE_LIMIT,
            filter: {
                ...this.transactionsFilter,
                account: billingStore.activeBillingAccount.id,
            },
            order,
        };
        browserHistory.push({
            pathname: BILLING_TRANSACTIONS_PAGE,
            search: qs.stringify(params, {
                ...BASE_SERIALIZE_OPTIONS,
            }),
        });
        this.billingAPI
            .getBillingTransactions(params.offset, params.limit, this.getFilterServerFormat(), params.order)
            .then((res) => {
            if (!res.content) {
                return;
            }
            this.totalTransactions = res.networkResponse?.headers.get(HEADER_X_TOTAL_COUNT);
            this.totalProducts = res.networkResponse?.headers.get(HEADER_X_PRODUCTS_COUNT);
            this.totalAmount = res.networkResponse?.headers.get(HEADER_X_TOTAL_AMOUNT);
            this.billingTransactions = res.content.map((transaction) => new BillingTransaction(transaction));
        });
    };
    parseTransactionQueryString() {
        const query = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });
        let result = {
            ...DEFAULT_TRANSACTIONS_FILTER,
        };
        if (!query) {
            return result;
        }
        const { limit, offset, filter, ...rest } = query;
        if (filter instanceof Object && !Array.isArray(filter)) {
            result = Object.assign(result, filter);
        }
        if (rest instanceof Object && !Array.isArray(rest)) {
            result = Object.assign(result, rest);
        }
        const numericLimit = Number(limit);
        const numericOffset = Number(offset);
        if (!Number.isNaN(numericOffset)) {
            if (numericOffset <= 0) {
                result.page = 1;
            }
            else if (!Number.isNaN(numericLimit)) {
                result.page = Math.ceil(numericOffset / numericLimit + 1);
            }
        }
        return pureStructureFrom(result);
    }
    getFilterServerFormat() {
        const filter = {
            ...this.transactionsFilter,
            createdAt: {
                from: this.transactionsFilter?.createdAt?.from
                    ? dateFormat.toServer(startOfDay(new Date(this.transactionsFilter.createdAt.from)))
                    : null,
                to: this.transactionsFilter?.createdAt?.to
                    ? dateFormat.toServer(endOfDay(new Date(this.transactionsFilter.createdAt.to)))
                    : null,
            },
        };
        if (billingStore.activeBillingAccount?.id) {
            filter.account = billingStore.activeBillingAccount.id;
        }
        return pureStructureFrom({
            ...filter,
            createdAt: {
                from: filter?.createdAt?.from ? dateFormat.toServer(startOfDay(new Date(filter.createdAt.from))) : null,
                to: filter?.createdAt?.to ? dateFormat.toServer(endOfDay(new Date(filter.createdAt.to))) : null,
            },
        });
    }
}
__decorate([
    observable
], TransactionsStore.prototype, "transactionsFilter", void 0);
__decorate([
    observable
], TransactionsStore.prototype, "billingTransactions", void 0);
__decorate([
    observable
], TransactionsStore.prototype, "totalTransactions", void 0);
__decorate([
    observable
], TransactionsStore.prototype, "totalProducts", void 0);
__decorate([
    observable
], TransactionsStore.prototype, "totalAmount", void 0);
__decorate([
    observable
], TransactionsStore.prototype, "isExportingTransactions", void 0);
__decorate([
    computed
], TransactionsStore.prototype, "transactionsPagesCount", null);
__decorate([
    action
], TransactionsStore.prototype, "initTransactionsStore", void 0);
__decorate([
    action
], TransactionsStore.prototype, "exportBillingTransactions", void 0);
__decorate([
    action
], TransactionsStore.prototype, "setTransactionFilter", void 0);
__decorate([
    action
], TransactionsStore.prototype, "resetTransactionFilter", void 0);
