import { userStore } from '@/user/stores/UserStore';
import { formatMoney } from '../../helpers/formatMoney';
function useMoney() {
    const { user } = userStore;
    function format(amount, options = {}) {
        const { locale, currency, fractionDigits, ...rest } = options;
        return formatMoney(amount, {
            // below we temporarily disable TS check, because of inconsistency between JS and TS
            // TS just don't see properties of JS class
            // @ts-ignore
            locale: locale || user?.locale,
            // @ts-ignore
            currency: currency || user?.b2b?.currency,
            fractionDigits,
            ...rest,
        });
    }
    function getCurrencySymbol(currency) {
        // below we temporarily disable TS check, because of inconsistency between JS and TS
        // TS just don't see properties of JS class
        const parts = new Intl.NumberFormat(
        // @ts-ignore
        user?.locale, {
            currency,
            style: 'currency',
        }).formatToParts(0);
        return parts.find((part) => part.type === 'currency')?.value ?? '';
    }
    return {
        formatMoney: format,
        getCurrencySymbol,
    };
}
export default useMoney;
