import { jsx as _jsx } from "react/jsx-runtime";
import { inject } from 'mobx-react';
import { METRIC_DATA_DECORATOR_DEFAULT, METRIC_DATA_DECORATOR_FANCY } from '../../constants/dataDecorators';
import { RATIO_THICK } from '../../constants/ratio';
import MetricBar from '../MetricBar/MetricBar';
function OptMetricBar(props) {
    const { metric, children, ratio = RATIO_THICK, scaleBaseUri, cutShapeId, metricDataDecorator = METRIC_DATA_DECORATOR_DEFAULT, type, } = props;
    function renderMetricBar() {
        const params = new URLSearchParams({
            ratio: `${ratio}`,
        });
        if (metric) {
            params.append('rg', metric.rg);
            params.append('d', `${metric.d}`);
            if (cutShapeId && metricDataDecorator === METRIC_DATA_DECORATOR_FANCY) {
                params.append('sh', `${cutShapeId}`);
            }
        }
        return _jsx(MetricBar, { ratio: ratio, svg: `${scaleBaseUri}/line/${type}?${params.toString()}` });
    }
    const metricValNumber = metric ? Number(metric.val).toFixed(2) : null;
    return children ? children(renderMetricBar(), metricValNumber) : renderMetricBar();
}
export default inject(({ appStore }) => ({
    scaleBaseUri: appStore.configurationStore.scaleBaseUri,
}))(OptMetricBar);
