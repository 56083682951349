import * as Sentry from '@sentry/react';
import { getEnvironment } from '@/common/helpers/getEnvironment';
import config from '@/config';
export function initSentry() {
    Sentry.init({
        dsn: config.SENTRY_DSN,
        environment: getEnvironment(),
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        release: config.APP_VERSION,
    });
}
