import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import styles from './MenuItemSeparator.module.css';
function MenuItemSeparator({ children }) {
    if (children) {
        return _jsx("div", { className: styles.menuItemSeparator, children: children });
    }
    return _jsx("div", { className: styles.menuItemSeparatorLine });
}
export default memo(MenuItemSeparator);
