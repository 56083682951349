import {
  observable, action, reaction, computed,
} from 'mobx';
import UserAPI from '../api/UserAPI';
import Token from '../services/token';
import User from '../entity/User';
import { MANAGER, securityManager } from '../../common/services/SecurityManager';
import {appStore} from '@/common/stores/AppStore';
import Storage, {KEY_USER} from '@/common/services/Storage';

export default class UserStore {
  /**
   * @type {UserAPI}
   */
  userAPI;

  /**
   * @type {User}
   */
  @observable.ref
  user;

  /**
   * @type {User[]}
   */
  @observable
  companyUsers = [];

  /**
   * @constructor
   * @param {AppStore} appStore
   */
  constructor(appStore) {
    this.appStore = appStore;
    this.userAPI = new UserAPI();

    reaction(
      () => this.user,
      () => {
        securityManager.setUser(this.user);
      },
    );
  }

  /**
   * @return {boolean}
   */
  @computed
  get isAuthorized() {
    return this.user && !this.user.isAnonymous;
  }

  /**
   * @return {Promise<QueryResult>}
   */
  @action
  fetch() {
    if (!Token.accessToken) {
      this.user = new User();

      return Promise.resolve(null);
    }

    return this.userAPI
      .fetchItem()
      .then((res) => {
        Storage.saveObject(KEY_USER, res.content, Storage.STORAGE_LOCAL);
        this.setUser(new User(res.content));
      });
  }

  /**
   * @return {Promise<QueryResult>}
   */
  @action
  fetchCompanyUsers() {
    if (securityManager.isGranted(MANAGER)) {
      this.userAPI.fetchCompanyUsers().then((res) => {
        if (!res.content || !Array.isArray(res.content)) {
          return
        }

        this.companyUsers = res.content.map(companyUser => new User(companyUser))
      })
    }
  }

  /**
   * @public
   * @param {User} user
   */
  @action
  setUser(user) {
    this.user = user;
  }
}

export const userStore = new UserStore(appStore)
