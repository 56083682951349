import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import { NEXTBOX_DOMAIN } from '../../netbox/constants/statuses';

export default class URLBuilder {
  /**
   * @param {string} path
   * @param {Object|string} params
   * @returns {string}
   */
  static buildUrl(path, params) {
    let url = `${window.location.origin}/${path}`;

    if (!url.includes(NEXTBOX_DOMAIN)) {
      /*
       * Below we need to check if site opened on subdomain (e.g. develop.cutwise.com, staging.cutwise.com),
       * if Yes api should be like "api-*cutwise.com", if No - "api.cutwise.com"
       *
       * This is because we can't have domain with 4 levels because of certificates
       *
       * Examples
       *   - cutwise.com -> api.cutwise.com
       *   - develop.cutwise.com -> api-develop.cutwise.com
       *   - staging.cutwise.com -> api-staging.cutwise.com
       * */
      const subdomainSearch = /\w+\.cutwise\.(com|local|link)/gi.exec(window.location.host);
      const subdomain = subdomainSearch && subdomainSearch[0] ? subdomainSearch[0] : null;

      url = `${window.location.protocol}//${subdomain ? `api-${subdomain}` : `api.${window.location.host}`}/${path}`;
    }

    if (isString(params)) {
      return `${url}?${params}`;
    }

    if (isEmpty(params)) {
      return url;
    }

    const queryStringParts = [];

    Object.keys(params).forEach((paramKey) => {
      if (params[paramKey] === null || params[paramKey] === undefined) {
        return;
      }
      if (Array.isArray(params[paramKey])) {
        params[paramKey].forEach((arrayElement) => {
          queryStringParts.push(`${paramKey}[]=${arrayElement}`);
        });
      } else if (params[paramKey] instanceof Object) {
        URLBuilder.filtersToString(params[paramKey], queryStringParts, paramKey);
      } else {
        queryStringParts.push(`${paramKey}=${params[paramKey]}`);
      }
    });

    if (queryStringParts.length) {
      url += `?${queryStringParts.join('&')}`;
    }

    return url;
  }

  static buildRelativeUrl(path) {
    return `${window.location.origin}${path}`;
  }

  static filtersToString(processedParams, queryStringParts, prefix = '') {
    Object.keys(processedParams).forEach((paramKey) => {
      if (processedParams[paramKey] === null || processedParams[paramKey] === undefined) {
        return;
      }
      if (Array.isArray(processedParams[paramKey])) {
        processedParams[paramKey].forEach((arrayElement) => {
          queryStringParts.push(`${prefix}[${paramKey}][]=${arrayElement}`);
        });
      } else if (processedParams[paramKey] instanceof Object) {
        const newPrefix = prefix ? `${prefix}[${paramKey}]` : paramKey;
        URLBuilder.filtersToString(processedParams[paramKey], queryStringParts, newPrefix);
      } else {
        queryStringParts.push(`${prefix}[${paramKey}]=${processedParams[paramKey]}`);
      }
    });
  }
}
