export default class User {
  /**
   * @type {?number}
   */
  id;

  /**
   * @type {?[string]}
   */
  roles;

  /**
   * @type {?[string]}
   */
  deny;

  /**
   * @type {?Object}
   */
  b2b;

  /**
   * @type {?string}
   */
  lastName;

  /**
   * @type {?string}
   */
  firstName;

  /**
   * @type {?string}
   */
  username;

  /**
   * @type {?string}
   */
  email;

  /**
   * @type {?string}
   */
  phone;

  /**
   * @type {?string}
   */
  utcTimezone;

  /**
   * @type {?string}
   */
  locale;

  /**
   * @type {?{
   *  disableStockNextPayment: boolean,
   *  disableBilling: boolean,
   *  disableBilling: boolean,
   *  allowSurveys: boolean,
   * }}
   */
  flags = {};

  /**
   * @type {?{
   *  flagSciRender: boolean,
   *  enablePrintDiamond: boolean,
   *  showPresentationMode: boolean,
   *  showCutEvoClarity: boolean,
   *  allowTopUpBalance: boolean,
   * }}
   */
  settings;

  constructor(modelData = {}) {
    Object.assign(this, modelData);

    this.flags = modelData.flags || {};
    this.settings = modelData.settings || {};
  }

  /**
   * @return {boolean}
   */
  get isAnonymous() {
    return !this.id;
  }

  /**
   * @return {?number}
   */
  get b2bId() {
    return this.b2b && this.b2b.id ? this.b2b.id : null;
  }

  /**
   * @return {?string}
   */
  get b2bTitle() {
    return this.b2b && this.b2b.title ? this.b2b.title : null;
  }

  /**
   * @return {string}
   */
  get userNameDesignationB2b() {
    if (this.b2bId && this.b2b.title) {
      return this.b2b.title;
    }

    return this.getFullName;
  }

  /**
   * @return {string}
   */
  get getFullName() {
    if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`;
    } if (this.firstName) {
      return this.firstName;
    } if (this.lastName) {
      return this.lastName;
    }

    return '';
  }

  /**
   * @return {string}
   */
  get getUserRenderName() {
    const userFullName = this.getFullName;

    if (userFullName) {
      return userFullName;
    } if (this.username) {
      return this.username;
    }

    return 'Unknown user';
  }
}
