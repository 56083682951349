var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { endOfDay, startOfDay, sub } from 'date-fns';
import { action, observable } from 'mobx';
import qs from 'qs';
import { BASE_SERIALIZE_OPTIONS } from '@/common/constants/qsSerialize';
import { dateFormat } from '@/common/helpers/dateFormat';
import { pureStructureFrom } from '@/common/helpers/pureStructureFrom';
import Notifier from '@/common/services/notifier';
import BillingAPI from '../api/BillingAPI';
import { BILLING_DASHBOARD_PAGE } from '../constants/pages';
import { billingStore } from './BillingStore';
const DEFAULT_DASHBOARD_FILTER = {
    period: {
        from: dateFormat.toServerDate(startOfDay(sub(new Date(), { months: 6 }))),
        to: dateFormat.toServerDate(endOfDay(new Date())),
    },
};
export class DashboardStore {
    /*
     * When component mount first time and no data loaded, we will render loader,
     * on update we don't need to show loader
     * */
    isInitialLoading = true;
    dashboardFilter = DEFAULT_DASHBOARD_FILTER;
    expenses;
    balance;
    billingAPI;
    constructor() {
        this.billingAPI = new BillingAPI();
    }
    initDashboardStore = () => {
        const { success, error, ...filter } = this.parseQueryString();
        if (error) {
            Notifier.error(error);
        }
        if (success) {
            Notifier.success(success);
        }
        this.dashboardFilter = filter;
        this.fetchDashboardInfo();
    };
    fetchDashboardInfo = () => {
        if (!billingStore.activeBillingAccount?.id) {
            return;
        }
        Promise.all([this.fetchBalance(), this.fetchExpenses()]).finally(() => {
            this.isInitialLoading = false;
        });
    };
    setExpensesFilter = (filter) => {
        this.dashboardFilter = pureStructureFrom({
            ...this.dashboardFilter,
            ...filter,
        });
        this.fetchExpenses();
    };
    replenishBalance = (values) => this.billingAPI.getPaymentEnroll({
        ...values,
        account: billingStore.activeBillingAccount.id,
    });
    fetchBalance = () => {
        // reset containers with data to clear UI during request
        this.balance = undefined;
        return this.billingAPI
            .getAccountDashboardById(billingStore.activeBillingAccount.id)
            .then((balance) => {
            if (balance.content) {
                this.balance = balance.content;
            }
        });
    };
    fetchExpenses = () => {
        const params = pureStructureFrom({
            ...this.dashboardFilter,
            account: billingStore.activeBillingAccount.id,
        });
        window.history.replaceState({}, '', `${BILLING_DASHBOARD_PAGE}?${qs.stringify(params, {
            ...BASE_SERIALIZE_OPTIONS,
        })}`);
        // reset containers with data to clear UI during request
        this.expenses = undefined;
        return this.billingAPI
            .getAccountExpensesById({
            account: params.account,
            period: {
                from: params.period?.from ? dateFormat.toServer(startOfDay(new Date(params.period.from))) : null,
                to: params.period?.to ? dateFormat.toServer(endOfDay(new Date(params.period.to))) : null,
            },
        })
            .then((expenses) => {
            if (expenses.content) {
                this.expenses = expenses.content;
            }
        });
    };
    parseQueryString() {
        const query = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });
        const result = {
            ...DEFAULT_DASHBOARD_FILTER,
        };
        if (!query) {
            return result;
        }
        return Object.assign(result, query);
    }
}
__decorate([
    observable
], DashboardStore.prototype, "isInitialLoading", void 0);
__decorate([
    observable
], DashboardStore.prototype, "dashboardFilter", void 0);
__decorate([
    observable
], DashboardStore.prototype, "expenses", void 0);
__decorate([
    observable
], DashboardStore.prototype, "balance", void 0);
__decorate([
    action
], DashboardStore.prototype, "initDashboardStore", void 0);
__decorate([
    action
], DashboardStore.prototype, "fetchDashboardInfo", void 0);
__decorate([
    action
], DashboardStore.prototype, "setExpensesFilter", void 0);
__decorate([
    action
], DashboardStore.prototype, "replenishBalance", void 0);
__decorate([
    action
], DashboardStore.prototype, "fetchBalance", void 0);
__decorate([
    action
], DashboardStore.prototype, "fetchExpenses", void 0);
