import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import cls from 'classnames';
import Checkbox from '../Checkbox/Checkbox';
import styles from './CheckboxGroup.less';
function CheckboxGroup(props) {
    const { type = 'checkbox', grid = false, options, values, onChange, optionClassName, activeClassName, disabledClassName, } = props;
    const parsedOptions = useMemo(() => options.map((option) => ({
        ...option,
        isSelected: values.includes(option.id),
    })), [options, JSON.stringify(values)]);
    function onChangeWrapper(nextOption) {
        const ids = parsedOptions
            .map((option) => {
            const nextParsedOption = { ...option };
            const isNextOption = nextParsedOption.id === nextOption.id;
            if (type === 'radio') {
                nextParsedOption.isSelected = isNextOption;
            }
            if (type === 'checkbox') {
                if (isNextOption) {
                    nextParsedOption.isSelected = !nextOption.isSelected;
                }
            }
            return nextParsedOption;
        })
            .filter(({ isSelected }) => isSelected)
            .map(({ id }) => id);
        onChange?.(ids);
    }
    return (_jsx("div", { className: cls({ [styles.checkboxGroupGrid]: grid }), children: parsedOptions.map((option) => {
            const isAmbient = type === 'radio' && new Set(values).size > 1 && option.isSelected;
            return (_jsx(Checkbox, { ambient: isAmbient, checked: option.isSelected, className: cls(styles.listingCheckboxGroupItem, optionClassName, activeClassName && { [activeClassName]: !isAmbient && option.isSelected }, disabledClassName && { [disabledClassName]: !isAmbient && option.disabled }), disabled: option.disabled, label: option.title, onChange: () => onChangeWrapper?.(option), type: type }, option.id));
        }) }));
}
export default memo(CheckboxGroup);
